import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import { useFlags } from "launchdarkly-react-client-sdk";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { SuretyContextProvider } from "../surety-context/SuretyContext";
import * as routes from "../../constants/routes";
import ContractSurety from "./ContractSurety";
import CommercialSurety from "./CommercialSurety";
import SuretyAgentManuals from "../agent-manuals/SuretyAgentManuals";
import DocsAndForms from "../document-library/DocsAndForms";
import SuretyHome from "../home/SuretyHome";
import AccountOverview from "../account-overview/AccountOverview";
import BondOverviewModal from "../bond-overview/BondOverviewModal";
import { getProducer } from "../../selectors/agentSelectors";
import "../styles/surety-home.scss";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";

const SuretyRouter = ({ agency, agencyCode, producer, ssoSession }) => {
  const {
    suretyAgentManualsUpdateEnabled,
    suretyDocumentsAndFormsEnabled,
    suretyNewLandingPageEnabled,
    suretyAccountOverviewEnabled,
    suretyContractAndCommercialPagesEnabled,
    suretyBondOverviewEnabled
  } = useFlags();

  const agencyInfo = { ...agency, agencyCode };

  return (
    <Switch>
      <SuretyContextProvider
        ssoSession={ssoSession}
        producer={producer}
        agency={agencyInfo}
      >
        {!suretyNewLandingPageEnabled && (
          <Redirect exact from={routes.SURETY} to={routes.CONTRACT_SURETY} />
        )}
        {!suretyAccountOverviewEnabled && (
          <Redirect
            exact
            from={routes.SURETY_ACCOUNT_OVERVIEW}
            to={routes.CONTRACT_SURETY}
          />
        )}
        {suretyAgentManualsUpdateEnabled && (
          <Route
            path={routes.SURETY_AGENT_MANUALS}
            component={SuretyAgentManuals}
          />
        )}
        {suretyDocumentsAndFormsEnabled && (
          <Route
            path={routes.SURETY_DOCS_FORMS}
            render={() => <DocsAndForms ssoSession={ssoSession} />}
          />
        )}
        {suretyAccountOverviewEnabled && (
          <Route
            exact
            path={routes.SURETY_ACCOUNT_OVERVIEW}
            component={AccountOverview}
          />
        )}
        {suretyBondOverviewEnabled && (
          <>
            <Route
              exact
              path={routes.SURETY_BOND_OVERVIEW}
              component={SuretyHome}
            />
            <BondOverviewModal />
          </>
        )}
        <Route exact path={routes.SURETY} component={SuretyHome} />
        {suretyContractAndCommercialPagesEnabled && (
          <>
            <Route path={routes.CONTRACT_SURETY} component={ContractSurety} />
            <Route
              path={routes.COMMERCIAL_SURETY}
              component={CommercialSurety}
            />
          </>
        )}
      </SuretyContextProvider>
    </Switch>
  );
};

SuretyRouter.propTypes = {
  agency: PropTypes.object.isRequired,
  agencyCode: PropTypes.string.isRequired,
  producer: PropTypes.object.isRequired,
  ssoSession: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    agency: sessionSelector.getActiveAgency(state),
    agencyCode: getSelectedAgencyCode(state),
    producer: getProducer(state),
    ssoSession: state?.ssoSession
  };
};

export default connect(mapStateToProps)(withRouter(SuretyRouter));
