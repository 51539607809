import API from "../../api";

/**
 * Document Upload
 */
export const postSuretyUploadDocuments = ({ payload, files }) => {
  const formData = new FormData();
  formData.append("json_body", JSON.stringify(payload));

  files.forEach((file, i) => {
    // OOS-5760
    // Mulesoft gets very angry when we try to send an Excel file. This is to trick
    // Mulesoft into happily accepting and passing on Excel files.
    const isExcel = ["xls", "xlsx"].includes(
      file.name.split(".").slice(-1)[0].toLowerCase()
    );
    const excelFile = isExcel
      ? file.slice(0, file.size, "application/octet-stream")
      : null;
    const fileNumber = i === 0 ? "" : i + 1;
    formData.append(`file${fileNumber}`, isExcel ? excelFile : file, file.name);
  });

  return API.surety().post(`/documents/upload`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
};

/**
 * Account Overview
 */
export const putSuretyAccountAddressChange = (accountNumber, payload) => {
  return API.surety().put(`/accounts/${accountNumber}/address`, payload);
};

export const putSuretyAccountNameChange = (accountNumber, payload) => {
  return API.surety().put(`/accounts/${accountNumber}/name`, payload);
};

export const getAccountBondList = (agencyCode, accountNumber) => {
  return API.surety().get(
    `/accounts/${accountNumber}/bonds?agency_code=${agencyCode}`
  );
};

export const getSuretyAccount = (agencyCode, accountNumber) => {
  return API.surety().get(
    `/accounts/${accountNumber}?agency_code=${agencyCode}`
  );
};

export const getSuretyBond = bondNumber => {
  return API.surety().get(`/bond/${bondNumber}`);
};

export const getPendingActivityList = agencyCode => {
  return API.surety().get(`/bond-request?agency_code=${agencyCode}`);
};

/**
 * Bond Overview
 */
export const putCancelBond = data => {
  return API.surety().put(`/bond/${data.bond_number}/cancellation`, data);
};

export const putBondAmount = data => {
  return API.surety().put(`/bond/${data.bond_number}/bond-amount`, data);
};

export const putBondProjectDescription = data => {
  return API.surety().put(
    `/bond/${data.bond_number}/project-description`,
    data
  );
};

/**
 * Fetch Renewal or Executed Surety bond forms.
 * @param {Number} bondNumber The bond number.
 * @param {String} version "latest" or "original".
 * @returns Bond documents: Bond Renewal Form ("latest") or Executed Bond Form ("original")
 */
export const getRenewalOrExecutedSuretyBondDocuments = (
  bondNumber,
  version
) => {
  return API.surety().get(`/bond/${bondNumber}/documents?version=${version}`, {
    responseType: "arraybuffer"
  });
};

/**
 * Fetch selected executed bond form.
 * @param {Number} bondNumber The bond number.
 * @param {String} bondType The bond type.
 * @param {Number} agencyCode The agency code.
 * @param {Number} trxnNumber The transaction number. Optional
 */
export const getExecutedBondDocument = (
  bondNumber,
  bondType,
  agencyCode,
  trxnNumber = null
) => {
  return API.surety().get(
    `/bond/${bondNumber}/executed-bond?agency_code=${agencyCode}&bond_type=${bondType}${
      trxnNumber ? "&trxn_number=" + trxnNumber : ""
    }`,
    { responseType: "arraybuffer" }
  );
};

/**
 * Checks whether a bond has a Renewal Form in ImageRight.
 * @param {Number} bondNumber The bond number.
 * @returns The "status" ("success" or "failure") for whether a bond has a Renewal Form in ImageRight.
 */
export const getRenewalFormExistsStatus = bondNumber => {
  return API.surety().get(
    `/bond/${bondNumber}/renewal-form-exists`,
    bondNumber
  );
};

/**
 * Bond Consent of Surety
 */
export const getActiveAgencyContacts = agencyCode => {
  return API.agency().get(`/agency/${agencyCode}/active-agents`);
};

export const postConsentOfSurety = data => {
  return API.surety().post(
    `/agency/${data?.agency_code}/bond/${data?.bond_number}/consent-of-surety`,
    data
  );
};
