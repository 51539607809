import _get from "lodash/get";
import { quotePath } from "../../shared/constants";

const buildingCoverages = (quoteData, buildingfixedId) => {
  // Get an array of all of the coverages to sent to ProductCoverages
  // for searching/adding to building
  const getAllCoveragesFromQuote = buildingfixedId => {
    const classIds = getClassificationIdsFromQuote(buildingfixedId) || [];
    let classCoverages = [];
    classIds.forEach(classId => {
      classCoverages = [
        ...classCoverages,
        ...getClassificationCoveragesFromQuote(classId)
      ];
    });

    return (
      [...getBuildingCoveragesFromQuote(buildingfixedId), ...classCoverages]
        // About this .map: adding this rowKey is necessary because two coverages with the same `publicID`
        // ... can be in the list because class coveraages are added to buildings
        // The 'rowKey' prop is used for buildings, while 'publicID' is used for the other coverables.
        .map(c => ({ ...c, rowKey: `${c.publicID}.${c.classificationName}` }))
    );
  };

  // START SUPPORT FUNCTIONS for getAllCoveragesFromQuote
  // ----------------------------------------------------
  // Find a list classification fixed ID given buildingfixedID.
  const getClassificationIdsFromQuote = buildingfixedId => {
    const locations = _get(quoteData, `${quotePath.bp7locations}`, []);
    const location = locations.find(
      l => l.buildings && l.buildings.some(b => b.fixedId === buildingfixedId)
    );
    const buildings = location ? location.buildings : [];
    const building = (buildings || []).find(b => b.fixedId === buildingfixedId);
    return (building?.classifications || []).map(c => c.fixedId);
  };

  const getClassificationCoveragesFromQuote = classificationfixedId => {
    const path = quotePath.bp7ClassificationCoverages;
    const classificationCoverageGroup = _get(quoteData, path, []).filter(
      c => c.coverableFixedId === classificationfixedId
    )[0];

    if (
      !classificationCoverageGroup ||
      !classificationCoverageGroup.clausesGroups
    )
      return [];

    const clausesGroups = classificationCoverageGroup.clausesGroups;

    return [
      ...clausesGroups.motelBus,
      ...clausesGroups.landscapeBus,
      ...clausesGroups.selfStorageBus,
      ...clausesGroups.coverages,
      ...clausesGroups.addlCoverages
    ].map(f => ({
      ...f,
      coverableFixedId: classificationfixedId,
      coverableType: "classification",
      coveragesPath: path,

      // we use replace - null if it comes up
      classificationName: (
        classificationCoverageGroup?.coverableDescription || ""
      ).replace("- null", "")
    }));
  };

  const getBuildingCoveragesFromQuote = buildingfixedId => {
    const path = quotePath.bp7BuildingCoverages;
    const buildingCoverageGroup = _get(quoteData, path, []).filter(
      c => c.coverableFixedId === buildingfixedId
    )[0];

    if (!buildingCoverageGroup || !buildingCoverageGroup.clausesGroups)
      return [];

    const clausesGroups = buildingCoverageGroup.clausesGroups;

    return [
      ...clausesGroups.coverages,
      ...clausesGroups.defaults,
      ...clausesGroups.addlCoverages
    ].map(f => ({
      ...f,
      coverableFixedId: buildingfixedId,
      coverableType: "building",
      coveragesPath: path
    }));
  };

  // --------------------------------------------------
  // END SUPPORT FUNCTIONS for getAllCoveragesFromQuote

  return getAllCoveragesFromQuote(buildingfixedId);
};

export const getBuildingCoveragesExclusionsFromQuote = (
  quoteData,
  buildingfixedId
) => {
  const path = quotePath.bp7BuildingCoverages;
  const buildingCoverageGroup = _get(quoteData, path, []).filter(
    c => c.coverableFixedId === buildingfixedId
  )[0];

  if (!buildingCoverageGroup || !buildingCoverageGroup.clausesGroups) return [];

  const clausesGroups = buildingCoverageGroup.clausesGroups;

  return [...(clausesGroups?.exclusions || [])].map(f => ({
    ...f,
    coverableFixedId: buildingfixedId,
    coverableType: "building",
    coveragesPath: path
  }));
};

export default buildingCoverages;
