import React from "react";
import PropTypes from "prop-types";
import IndividualContactCard from "./IndividualContactCard";
import v4 from "uuid/v4";

const IndividualContacts = ({
  contacts,
  department
}) => {
  const subheadings = Array.from(
    new Set(contacts.map(item => item.sub_heading))
  ).filter(item => !!item);
  if (department && department.id !== "UfgOnline") {
    subheadings.sort();
  }
  if (!subheadings.length > 0) subheadings.push("");
  const getContactsForGrouping = subheading => {
    const groupedContacts = [];
    let found = false;
    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i];
      if (!subheading) {
        groupedContacts.push(contact);
        continue;
      }
      if (!!contact.sub_heading) {
        found = subheading === contact.sub_heading;
      }
      if (found) {
        groupedContacts.push(contact);
      }
    }
    return groupedContacts;
  };

  return (
    <>
      {subheadings.map(subheading => (
        <div key={subheading}>
          {subheading && (
            <div className="contact-info-sub-heading mt-30">{subheading}</div>
          )}
          <div className="row underwriter-container">
            {getContactsForGrouping(subheading).map(item => (
              <IndividualContactCard contact={item} key={v4()} />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

IndividualContacts.propTypes = {
  contacts: PropTypes.array.isRequired,
  department: PropTypes.object
};

export default IndividualContacts;
