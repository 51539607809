import React, { useState, useCallback, useEffect, useContext } from "react";
import {
  ContentHeader,
  useForm,
  Form,
  FormGroup,
  Panel,
  Checkbox,
  Button
} from "@ufginsurance/ui-kit";
import cn from "classnames";
import "./CoverageOverrides.scss";

import PropTypes from "prop-types";
import { inputTypes, textTypes } from "./util";
import { Field } from "./Field";

import OnlineQuotingContext from "../OnlineQuotingContext";

const getIdFromFilter = i => {
  return i && i.filters && i.filters[0] && i.filters[0].id;
};

const shallowDiff = (objA, objB) => {
  if (!objA || !objB) {
    return objA === objB;
  }
  return Object.keys({ ...objA, ...objB }).filter(
    key => objA[key] !== objB[key]
  );
};

/*
  ----------
  FORM GROUP
  ----------
*/

const FormSetup = ({
  sectionHeader,
  panels,
  handleFormSubmit,
  showRequireTerms,
  submitBtnLabel,
  submitBtnSpinner,
  cancelButton,
  additionalContent,
  saveCurrentValues,
  hideSubmitBtn,
  useCoveragePanel,
  collapsiblePanel,
  isPanelOpen,
  updateFormStatus,
  formId,
  eligibilityChecked,
  setFormIsInvalid,
  onValuesUpdate,
  coverageUpdating,
  className
}) => {
  const { quoteIsUpdating } = useContext(OnlineQuotingContext);

  const initialValues = {};

  panels.forEach(p => {
    p.rows.forEach(r => {
      if (r.fields)
        r.fields.forEach(f => {
          initialValues[f.id] = f.defaultValue;
        });
    });
  });

  initialValues.requireTermsCheckbox = eligibilityChecked;

  const disableKeystrokesWhileUpdating = e => {
    if ((quoteIsUpdating || coverageUpdating) && e?.event?.preventDefault)
      e.event.preventDefault();
  };

  const form = useForm({ values: initialValues, onSubmit: handleFormSubmit });
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    invalidFields
  } = form;

  const [hiddenFields, setHiddenFields] = useState([]);

  useEffect(() => {
    if (setFormIsInvalid) setFormIsInvalid(invalidFields.length > 0);
  }, [invalidFields, setFormIsInvalid]);

  useEffect(() => {
    if (onValuesUpdate) onValuesUpdate({ values });
  }, [values, onValuesUpdate]);

  // method used by 'togglelink' that let's the user toggle the display/render of fields
  const handleToggle = useCallback(
    fieldId => {
      const arr =
        hiddenFields.indexOf(fieldId) >= 0
          ? hiddenFields.filter(i => i !== fieldId) // remove item from array
          : [...hiddenFields, fieldId]; // add item to array
      setHiddenFields(arr);
      return false;
    },
    [hiddenFields]
  );

  // for coverage panels
  // when panels are updated and have new values...
  // update the form values to match the new incoming values
  // note: panels should be updated when quoteData is refreshed from PC
  useEffect(() => {
    if (useCoveragePanel) {
      const updatedValues = {};
      panels.forEach(p => {
        p.rows.forEach(r => {
          if (!!r.fields)
            r.fields.forEach(f => {
              updatedValues[f.id] = f.defaultValue;
            });
        });
      });

      const diff = shallowDiff(values, updatedValues);

      if (diff && diff.length > 0) {
        const newValues = {};
        const resetErrors = {};
        shallowDiff(values, updatedValues).forEach(f => {
          newValues[f] = updatedValues[f];
          resetErrors[f] = [];
        });

        updateForm({ values: newValues, errors: resetErrors });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panels]);

  const removeFieldValue = fieldId => {
    // if field has a value in the {values}, set it to null
    if (!!values?.[fieldId]) {
      const v = {
        values: { ...values, [fieldId]: "" }
      };
      updateForm(v);
    }
  };

  // on text input fields, we want to wait until
  // they blur before sending the value to PC
  const customOnBlur = useCallback(
    ({ field, type, fieldOnBlur }) => {
      handleOnBlur(field);

      if (fieldOnBlur) {
        fieldOnBlur({
          field, // includes field.field and field.value
          type,
          values: { ...values, [field.field]: field.value },
          invalidFields, // includes any errors that exist in the form after the event
          // if there's an API error and the form data needs to be reverted, let the on-blur event undo the change
          undoFormUpdate: ({ previousValue }) => {
            updateForm({ values: { [field.field]: previousValue } });
          }
        });
      }

      const fieldIsTextInput = [...textTypes, "date"].includes(type); // prevents saves after each input change

      if (
        !invalidFields.some(f => {
          return f.name === field.field;
        }) &&
        fieldIsTextInput &&
        saveCurrentValues
      )
        saveCurrentValues(field.field, field.value);
    },
    [handleOnBlur, invalidFields, saveCurrentValues, updateForm, values]
  );

  // all other input fields, send the value to pc immediately
  const customOnChange = useCallback(
    ({ field, type, fieldOnChange }) => {
      handleOnChange(field);

      if (fieldOnChange)
        fieldOnChange({
          field, // includes field.field and field.value
          type,
          values: { ...values, [field.field]: field.value },
          invalidFields, // includes any errors that exist in the form after the event
          // if there's an API error and the form data needs to be reverted, let the on-blur event undo the change
          undoFormUpdate: ({ previousValue }) => {
            updateForm({ values: { [field.field]: previousValue } });
          },
          updateForm
        });

      const fieldIsTextInput = [...textTypes, "date"].includes(type); // prevents saves after each input change

      if (!fieldIsTextInput && saveCurrentValues) {
        saveCurrentValues(field.field, field.value);
      }
    },
    [handleOnChange, invalidFields, saveCurrentValues, updateForm, values]
  );

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus(formId, invalidFields);
    }
  }, [formId, invalidFields, updateFormStatus]);

  // Panels are different depending on where they're used in the quoting system
  // collapsible panels hold the coverables in the Risk Info
  // coverage panels are nested panels located in the Policy Info and inside coverable modals
  // the standard panel is the basic grey container panel
  const PanelContainer = useCallback(
    ({
      children,
      type,
      hasNoFields,
      coverageWithScheduleItems,
      className,
      ...rest
    }) => {
      if (type === "collapsible") {
        return (
          <Panel {...rest} bgcolor="white" collapsible rounded>
            {children}
          </Panel>
        );
      }

      if (type === "coverage") {
        return (
          <Panel
            {...rest}
            isOpen={!hasNoFields || coverageWithScheduleItems}
            className={cn(className, "oq__panel__coverage", {
              "oq__panel__coverage-compressed":
                hasNoFields && !coverageWithScheduleItems,
              "oq__panel__coverage__scheduled-item": coverageWithScheduleItems
            })}
          >
            {children}
          </Panel>
        );
      }

      return (
        <Panel {...rest} titlebar rounded bgcolor="grey">
          {children}
        </Panel>
      );
    },
    []
  );

  return (
    <Form
      context={form}
      className={cn("oq__form", className)}
      autoComplete="off"
    >
      {sectionHeader && <ContentHeader>{sectionHeader}</ContentHeader>}

      {panels.map(p => {
        // if the form has no fields, then make the panel collapsed so only the title appears
        const hasNoFields = !(p.rows[0].fields || []).length;

        const ky = p.key ? `panel-id-${p.key}` : null;

        return (
          <PanelContainer
            type={
              collapsiblePanel
                ? "collapsible"
                : useCoveragePanel
                ? "coverage"
                : ""
            }
            hasNoFields={hasNoFields}
            coverageWithScheduleItems={p.coverageWithScheduleItems}
            key={p.key}
            title={p.title}
            id={ky || p.title} //cn(p.title}.replace(/[\W_]+/g, "_"))}
            className={cn({
              [`columns-${p.columns}`]: p?.columns,
              [p?.className]: p?.className
            })}
            isOpen={isPanelOpen}
          >
            {!!p.jsxAtTop && p.jsxAtTop}

            {p.rows.map(r => {
              /*
                  {r.jsx} allows the StepFile to create custom JSX to be inserted into the form

                  A "row" should contain either an array of fields or a {jsx} property.
                */
              if (r.jsx) {
                return <React.Fragment key={r.key}>{r.jsx}</React.Fragment>;
              }
              if (r.fields)
                return (
                  <FormGroup
                    key={r.key}
                    className={cn(r.className, {
                      [`columns-${r.columns}`]: r?.columns
                    })}
                  >
                    {r.fields
                      .filter(f => {
                        // some fields may be be hidden by default...
                        // until a user selects to display them...
                        // or another field value triggers them to be displayed
                        const displayByDefault =
                          f.display === undefined || f.display === true;

                        // field filters can control whether a field should display or be hidden
                        const displayBeacuseOfFieldValue =
                          f.filters &&
                          f.filters.some(filter => {
                            return (
                              filter.display === true &&
                              RegExp(filter.pattern, "i").test(
                                values?.[filter.id]?.toString()
                              )
                            );
                          });

                        const hideBeacuseOfFieldValue =
                          f.filters &&
                          f.filters.some(
                            filter =>
                              filter.display === false &&
                              !!values?.[filter.id] &&
                              RegExp(filter.pattern, "i").test(
                                values?.[filter.id]?.toString()
                              )
                          );

                        // field selected by the user interaction to be displayed
                        const userSelectedToDisplay =
                          hiddenFields.indexOf(getIdFromFilter(f)) >= 0
                            ? true
                            : undefined;

                        /* the weird logic to determine if we show the field
                        1. SHOW if field is hidden by default but a field value makes it visible
                        2. HIDE if field value makes it hidden
                        3. SHOW if field is hidden by default but user makes it visible
                        4. SHOW if the default value says it should be visible
                        */
                        const renderIt =
                          !displayByDefault && displayBeacuseOfFieldValue
                            ? true
                            : hideBeacuseOfFieldValue
                            ? false
                            : !displayByDefault && !!userSelectedToDisplay
                            ? true
                            : displayByDefault;

                        // TODO - Chris:
                        // I think this should be handled during unmount of a Field.
                        // May need to evaluate if ui-kit code needs updated to do this correctly.
                        /*
                        if a field is not rendered for any reason, check to see if it had a value
                        and make sure to remove the field value from the {values} if it exists
                        */
                        if (!renderIt) {
                          removeFieldValue(f.id);
                        }
                        return renderIt;
                      })
                      .map(f => {
                        // allow JSX to be added so regular markup can be added into the array of fields to render
                        if (f.jsx) {
                          return (
                            <React.Fragment key={f.key}>{f.jsx}</React.Fragment>
                          );
                        }

                        /*
                        -------------
                        check filters
                        -------------
                        */

                        // filter makes field disabled:
                        const disabledByFilter =
                          f.filters &&
                          f.filters.some(filter => {
                            // return if the filter is a 'disabled' filter
                            // and the field value matches pattern to activate the filter
                            return (
                              filter.disabled === true &&
                              values[filter.id] &&
                              RegExp(filter.pattern, "i").test(
                                values[filter.id].toString()
                              )
                            );
                          });

                        // filter makes field not disabled
                        const enabledByFilter =
                          f.disabled &&
                          f.filters &&
                          f.filters.some(
                            filter =>
                              filter.disabled === false &&
                              values[filter.id] &&
                              RegExp(filter.pattern, "i").test(
                                values[filter.id].toString()
                              )
                          );

                        // filter makes field required
                        const requiredByFilter =
                          f.filters &&
                          f.filters.some(
                            filter =>
                              // return if the filter is a 'required' filter
                              filter.required === true &&
                              // and either the field value matches pattern to activate the filter
                              ((values[filter.id] &&
                                RegExp(filter.pattern, "i").test(
                                  values[filter.id].toString()
                                )) ||
                                // or the field is displayed by a togglebutton
                                hiddenFields.indexOf(filter.id) >= 0)
                          );

                        return !f ? null : (
                          <React.Fragment key={f.id}>
                            {!!f.jsxBefore && f.jsxBefore}
                            <Field
                              key={f.id}
                              id={f.id}
                              f={f}
                              handleOnChange={customOnChange}
                              handleOnBlur={customOnBlur}
                              onValidate={handleOnValidate}
                              onToggle={handleToggle}
                              hiddenFields={hiddenFields}
                              value={values[f.id]}
                              onKeyDown={disableKeystrokesWhileUpdating}
                              disabledByFilter={
                                enabledByFilter === true
                                  ? false
                                  : disabledByFilter === true
                                  ? true
                                  : undefined
                              }
                              requiredByFilter={requiredByFilter}
                            />
                            {!!f.jsxAfter && f.jsxAfter}
                          </React.Fragment>
                        );
                      })}
                  </FormGroup>
                );
              return null;
            })}
            {!!p.jsxAtBottom && p.jsxAtBottom}
          </PanelContainer>
        );
      })}
      {additionalContent && additionalContent}
      <FormGroup wrap={false}>
        {showRequireTerms && (
          <Checkbox
            id="requireTermsCheckbox"
            name="requireTermsCheckbox"
            className="oq__checkbox__require-terms"
            label={
              "I have reviewed and accurately answered the eligibility questions above."
            }
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.requireTermsCheckbox}
            required
            noLabel
          />
        )}
        {cancelButton}
        {hideSubmitBtn ? (
          ""
        ) : (
          <Button
            variant="primary"
            disabled={invalidFields.length > 0 || submitBtnSpinner}
            spinner={submitBtnSpinner}
            onClick={() => {
              handleFormSubmit({ values });
            }}
          >
            {submitBtnLabel || "Continue"}
          </Button>
        )}
      </FormGroup>
    </Form>
  );
};

FormSetup.propTypes = {
  sectionHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  panels: PropTypes.arrayOf(
    PropTypes.shape({
      rows: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            fields: PropTypes.arrayOf(
              PropTypes.oneOfType([
                PropTypes.shape({
                  label: PropTypes.string.isRequired,
                  id: PropTypes.string.isRequired,
                  type: PropTypes.oneOf(inputTypes),
                  disabled: PropTypes.bool,
                  validation: PropTypes.shape({
                    pattern: PropTypes.oneOf([
                      PropTypes.RegExp,
                      PropTypes.undefined
                    ]),
                    errorMessage: PropTypes.string,
                    validatorfunc: PropTypes.func
                  }),
                  options: PropTypes.array,
                  placeholder: PropTypes.string,
                  isPrefilled: PropTypes.bool,
                  defaultValue: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.bool,
                    PropTypes.object
                  ]),
                  className: PropTypes.string,
                  required: PropTypes.bool,
                  multi: PropTypes.bool,
                  filters: PropTypes.arrayOf(PropTypes.object),
                  display: PropTypes.bool
                }),
                PropTypes.shape({
                  jsx: PropTypes.object
                })
              ])
            )
          })
        ),
        PropTypes.shape({
          jsx: PropTypes.object
        })
      ]).isRequired,
      columns: PropTypes.number,
      title: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object])
    })
  ),
  cancelButton: PropTypes.object,
  additionalContent: PropTypes.object,
  handleFormSubmit: PropTypes.func,
  showRequireTerms: PropTypes.bool,
  submitBtnLabel: PropTypes.string,
  submitBtnSpinner: PropTypes.bool,
  saveCurrentValues: PropTypes.func,
  hideSubmitBtn: PropTypes.bool,
  collapsiblePanel: PropTypes.bool,
  isPanelOpen: PropTypes.bool,
  useCoveragePanel: PropTypes.bool,
  updateFormStatus: PropTypes.func,
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  eligibilityChecked: PropTypes.bool,
  clickingDisabledContinueSubmits: PropTypes.bool,
  setFormIsInvalid: PropTypes.any,
  onValuesUpdate: PropTypes.func,
  coverageUpdating: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string
};

export default FormSetup;
