/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useContext } from "react";
import _set from "lodash/set";
import { Button, FlexRow } from "@ufginsurance/ui-kit";
import {
  quotePath,
  productKeys,
  getCoverageGroupPath,
  vehicleTypes
} from "../../shared/constants";
import coveragePanels, {
  getCoverageDetailsForLogging,
  applyExclusions
} from "../../shared/coveragePanels";
import { massageMetadata, sortCoverages } from "../../shared/util";
import vehicleCoverages from "./vehicleCoverages.js";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import ProductCoverages from "../ProductCoverages.js";
import getAllCoverages, {
  saveLineLevelCoverages
} from "../../step4/getAllCoverages";

import "../../oq-globals.scss";

const VehicleFormCoveragesStep3 = ({
  onNextStep,
  onPrevStep,
  currentVehicle,
  setScheduleItemOpenInCoverable,
  coverageExclusions,
  addAnotherItem,
  goToStep
}) => {
  const {
    quoteData,
    quoteIsUpdating,
    updateCoveragesPromise,
    validateCoverablesPromise,
    updateSupportingDataPromise,
    toastErrr,
    getQuotePart,
    showUpdatingToast,
    closeUpdatingToast
  } = useContext(OnlineQuotingContext);

  const [panels, setPanels] = useState([]);
  const [submitWasClicked, setSubmitWasClicked] = useState(false);
  const [haveCoveragesChanged, setHaveCoveragesChanged] = useState(false);

  const gotoPreviousStep = () => {
    if (!haveCoveragesChanged) {
      onPrevStep(currentVehicle);
    } else {
      validateCoverablesPromise({
        coverableName: "vehicle",
        coverablefixedId: currentVehicle?.fixedId
      }).then(({ success }) => {
        setHaveCoveragesChanged(false);
        if (success) onPrevStep(currentVehicle);
      });
    }
  };
  //on submit
  const onSubmit = () => {
    setSubmitWasClicked(false);

    if (!haveCoveragesChanged) {
      onNextStep();
    } else {
      validateCoverablesPromise({
        coverableName: "vehicle",
        coverablefixedId: currentVehicle?.fixedId
      }).then(({ success }) => {
        setHaveCoveragesChanged(false);
        if (success) onNextStep();
      });
    }
  };

  const updateVehicleCoverages = (coverage, termToUpdate, action = "added") => {
    // object shape of the coverage to be updated:
    // lobData.ca7CommAuto.coverages.vehicleClausesGroups(filter by coverableFixedId)[0].clausesGroups.
    // either defaults:[] or coverages:[] or addlCoverages:[],

    if (!getCoverageGroupPath(coverage.coverageCategoryCode) && !termToUpdate)
      toastErrr({
        action: "updateVehicleCoverages",
        description: "update vehicle coverage",
        misc: { coverage, termToUpdate },
        displayMessage: `An error occurred. Coverage(s) were not ${action} (category unknown).`
      });

    coverage.updated = true;
    coverage.selected = action === "added";

    const coverableToSave = {
      coverableFixedId: coverage.coverableFixedId,
      entityName: coverage.vehicleType.entityName
    };

    _set(coverableToSave, getCoverageGroupPath(coverage.coverageCategoryCode), [
      coverage
    ]);

    const coveragesToSave = {
      ca7CommAuto: {
        [coverage.vehicleType.coverageGroupName]: [coverableToSave]
      }
    };

    updateCoveragesPromise({
      coveragesToSave,
      productName: "ca7CommAuto",
      coveragePath: quotePath.ca7VehicleCoverages,
      coverageDetails: getCoverageDetailsForLogging({
        coverage,
        termToUpdate,
        action
      })
    }).then(() => {
      setHaveCoveragesChanged(true);
      if (submitWasClicked) {
        onSubmit();
      }
      setSubmitWasClicked(false);
    });
  };

  const removeCoverageAndSave = coverageToRemove => {
    coverageToRemove.selected = !coverageToRemove.selected;
    coverageToRemove.terms = [];
    updateVehicleCoverages(
      coverageToRemove,
      null,
      coverageToRemove.selected ? "added" : "removed"
    );
  };

  const getAllCoveragesFromQuote = useCallback(
    fixedId => {
      const vehicleType = vehicleTypes.find(
        t => t.key === currentVehicle.vehicleType
      );
      return vehicleCoverages(quoteData, fixedId, vehicleType);
    },
    [quoteData]
  );

  useEffect(() => {
    if (quoteData) {
      const _panels = [];
      let _coverageFields = sortCoverages(
        getAllCoveragesFromQuote(currentVehicle?.fixedId)
      );

      _coverageFields = applyExclusions(_coverageFields, coverageExclusions);

      // OOQ-14751 if State is NJ and gross weight of more than 26,000
      // change Auto-line Liability Limit to 1.5M

      if (
        quoteData?.baseData?.baseState_UFG === "NJ" &&
        (currentVehicle?.grossVehicleWeight || 0) > 26000
      ) {
        //get line auto level auto coverages...
        const coveragesPath = `lobData.ca7CommAuto.${quotePath.coverages}`;
        const lineLevelcoverageFields = getAllCoverages({
          coveragePaths: { coveragesPath },
          getQuotePart
        });

        const truckLiabCoverage = (lineLevelcoverageFields || []).find(
          c => c.codeIdentifier === "CA7VehicleLiab"
        );

        const termToUpdate = (truckLiabCoverage?.terms || []).find(
          t => t.patternCode === "CA7LimitText"
        );

        if (termToUpdate.chosenTermValue !== "1,500,000") {
          const options1_5M = (termToUpdate?.options || []).find(
            o => o.name === "1,500,000"
          );

          if (truckLiabCoverage && termToUpdate) {
            termToUpdate.chosenTerm = options1_5M?.code;
            termToUpdate.chosenTermValue = options1_5M?.name;
            termToUpdate.updated = true;

            saveLineLevelCoverages({
              showUpdatingToast,
              closeUpdatingToast,
              updateCoveragesPromise,
              updateSupportingDataPromise,
              coverage: truckLiabCoverage,
              termToUpdate
            });
          }
        }
      }

      const innerPanels = coveragePanels({
        fields: _coverageFields,
        coverageExclusions,
        removeCoverageAndSave,
        quoteData,
        setScheduleItemOpenInCoverable
      });

      _panels.push({
        vehicleId: currentVehicle?.fixedId,
        panels: massageMetadata(innerPanels, quoteData),
        allCoverages: _coverageFields
      });
      setPanels(_panels);
    }
  }, [quoteData, currentVehicle.fixedId]);

  // manage the status of the form errors to disable the continue button
  const [formValidStatus, setFormValidStatus] = useState(false);
  const updateFormStatus = (formId, invalidFields) => {
    if (formId && invalidFields) {
      const hasErrors = invalidFields.length > 0;
      if (formId === "coverableCoverages" && formValidStatus !== hasErrors)
        setFormValidStatus(hasErrors);
    }
  };

  return (
    <div id="oq__vehicle__step3" className="oq__form__vehicle__step3">
      <>
        <div className="oq__coverage-wrapper">
          {panels &&
            panels.length > 0 &&
            panels.map((p, index) => (
              <ProductCoverages
                key={p.vehicleId}
                panels={p.panels}
                allCoverages={p.allCoverages}
                selectedCoverageTitle={productKeys.ca7CommAuto.label}
                saveCoverages={updateVehicleCoverages}
                isOpen={index < 3}
                popupCoverageSearch={false}
                updateFormStatus={updateFormStatus}
                formId="coverableCoverages"
                searchTableRowKey="publicID"
                className="oq__coverage-panel__Auto"
              />
            ))}
          <FlexRow>
            <Button
              isLink
              inline
              className="back"
              disabled={formValidStatus}
              onClick={() => gotoPreviousStep()}
            >
              Back to Classes
            </Button>
            <FlexRow className="pull-right">
              <Button
                variant="outline"
                className="add-another"
                disabled={formValidStatus}
                onClick={() => {
                  addAnotherItem();
                  goToStep(1, null);
                }}
              >
                Save and Add Another Vehicle
              </Button>
              <Button
                className="continue"
                onMouseDown={() => setSubmitWasClicked(true)}
                variant="primary"
                disabled={formValidStatus || quoteIsUpdating}
                onClick={onSubmit}
              >
                {!haveCoveragesChanged ? "Close" : "Continue"}
              </Button>
            </FlexRow>
          </FlexRow>
        </div>
      </>
    </div>
  );
};

export default VehicleFormCoveragesStep3;
