import { productKeys, productsByLineCode } from "../shared/constants";
import structuredClone from "@ungap/structured-clone";

// These functions help populate the data for these fields
// ... located in the quote at quoteData?.lobData?.wcmWorkersComp?.coverables
//  {
//   wcmlineDiseaseEachEmployeeLimit: "300,000",
//   wcmlineDiseasePolicyLimit: "8,000,000",
//   wcmlineEachAccidentLimit: "300,000",
//   wcmlineStopGap: "No"
// };

export const workCompFieldOptions = ({ state }) => {
  const eachAccidentAndEmployeeLimitOptions = {
    OR: [
      { name: "500,000", code: "500,000" },
      { name: "1,000,000", code: "1,000,000" }
    ],
    default: [
      { name: "100,000", code: "100,000" },
      { name: "500,000", code: "500,000" },
      { name: "1,000,000", code: "1,000,000" }
    ]
  };

  return {
    eachAccidentLimits:
      eachAccidentAndEmployeeLimitOptions[state] ||
      eachAccidentAndEmployeeLimitOptions.default,
    diseaseEachEmployeeLimits:
      eachAccidentAndEmployeeLimitOptions[state] ||
      eachAccidentAndEmployeeLimitOptions.default,
    diseasePolicyLimits: [
      { name: "500,000", code: "500,000" },
      { name: "1,000,000", code: "1,000,000" }
    ],
    stopGap: [
      { name: "Yes", code: "Yes" },
      { name: "No", code: "No" }
    ]
  };
};
export const addWorkmansCompDetailsFields = ({ quoteData, wcLineOptions }) => {
  const workersCompLine =
    quoteData?.lobData?.wcmWorkersComp?.coverables?.workersCompLine;
  return {
    codeIdentifier: "workmansComp.details",
    name: "Employers Liability Limits",
    description: "Details",
    required: true,
    updated: false,
    publicID: `workmansComp.details`,
    selected: true,
    terms: [
      {
        name: "Each Accident Limit",
        type: "workCompLine",
        options: wcLineOptions?.eachAccidentLimits || [],
        required: true,
        patternCode: "wcmlineEachAccidentLimit",
        updated: false,
        chosenTerm: workersCompLine?.wcmlineEachAccidentLimit || "",
        publicID: "wcmlineEachAccidentLimit",
        chosenTermValue: "",
        isAscendingBetter: true,
        isCovTermEditable_UFG: true,
        coveragePublicID: "workmansComp.details"
      },
      {
        name: "Disease Each Employee Limit",
        type: "workCompLine",
        options: wcLineOptions?.diseaseEachEmployeeLimits || [],
        required: false,
        patternCode: "wcmlineDiseaseEachEmployeeLimit",
        updated: false,
        chosenTerm: workersCompLine?.wcmlineDiseaseEachEmployeeLimit || "",
        publicID: "wcmlineDiseaseEachEmployeeLimit",
        chosenTermValue: "",
        isAscendingBetter: true,
        isCovTermEditable_UFG: false,
        coveragePublicID: "workmansComp.details"
      },
      {
        name: "Disease Policy Limit",
        type: "workCompLine",
        options: wcLineOptions?.diseasePolicyLimits || [],
        required: true,
        patternCode: "wcmlineDiseasePolicyLimit",
        updated: false,
        chosenTerm: workersCompLine?.wcmlineDiseasePolicyLimit || "",
        publicID: "wcmlineDiseasePolicyLimit",
        chosenTermValue: "",
        isAscendingBetter: true,
        isCovTermEditable_UFG: true,
        coveragePublicID: "workmansComp.details"
      },
      {
        name: "Stop Gap",
        type: "workCompLine",
        options: wcLineOptions?.stopGap || [],
        required: true,
        patternCode: "wcmlineStopGap",
        updated: false,
        chosenTerm: workersCompLine?.wcmlineStopGap || "",
        publicID: "wcmlineStopGap",
        chosenTermValue: "",
        isAscendingBetter: true,
        isCovTermEditable_UFG: true,
        coveragePublicID: "workmansComp.details"
      }
    ],
    isValid: true,
    hasTerms: true,
    coverageCategoryCode: "WorkersCompStdGrp",
    coverageCategoryDisplayName: `${productKeys.wcmWorkersComp.label} Details`
  };
};

export const addWorkmansFEINField = ({ quoteData }) => {
  const fein = quoteData?.baseData?.accountHolder?.officialIds_UFG?.feinid;

  return {
    codeIdentifier: "workmansComp.fein",
    name: `Official IDs`,
    description: "fein",
    required: true,
    updated: false,
    publicID: `workmansComp.fein`,
    selected: true,
    terms: [
      {
        name: "FEIN",
        type: "workCompLine",
        options: [],
        required: true,
        patternCode: "wcmlineFein",
        mask: "ein",
        stripMaskFromValue: false,
        updated: false,
        publicID: "wcmlineFein",
        chosenTermValue: fein || "",
        valueType: "shorttext",
        isAscendingBetter: true,
        isCovTermEditable_UFG: true,
        coveragePublicID: "workmansComp.fein"
      }
    ],
    isValid: true,
    hasTerms: true,
    coverageCategoryCode: "WorkersCompStdGrp",
    coverageCategoryDisplayName: `${productKeys.wcmWorkersComp.label} fein`
  };
};

export const updateWorkersCompLineFields = ({
  field,
  value,
  quoteData,
  updateLineDetailsPromise,
  callback
}) => {
  const workersCompLineFromDTO =
    quoteData?.lobData?.wcmWorkersComp?.coverables?.workersCompLine;

  const workersCompLine = structuredClone(workersCompLineFromDTO);
  const payload = { ...workersCompLine, [field]: value };

  // there's a dependency on the diseaseEachEmployeeLimits field
  // ... it always matches the value of the eachAccidentLimits field
  if (field === "wcmlineEachAccidentLimit") {
    payload.wcmlineDiseaseEachEmployeeLimit = value;
  }

  const termToUpdate = workersCompLine?.[field];

  // if value has changed, then do the save if...
  // and of these conditions are true:
  // 1. if there's a value, update it
  // 2. if we're changing the existing value to be null (or "")
  // 3. if there was an existing value and we're changing it because the new value is different

  if ((!!value && value !== termToUpdate) || (!value && !!termToUpdate))
    updateLineDetailsPromise({
      payload,
      line: productsByLineCode.WCMWorkersCompLine
    }).then(() => {
      if (callback) callback();
    });
};

export const updateWorkersCompOfficialId = ({
  value,
  quoteData,
  patchQuotePromise,
  toastErrr
}) => {
  const quoteId = quoteData.quoteID;

  patchQuotePromise({
    newData: {
      feinNumber: value
    },
    quoteId,
    updateMessage: "Updating FEIN"
  })
    .then(() => {})
    .catch(({ error }) => {
      toastErrr({
        action: "updateWorkersCompOfficialId",
        description: "unable to update work comp fein",
        error
      });
    });
};
