import React, { useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { PhoneLink } from "@ufginsurance/ui-kit";
import { faEnvelope as farEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translations } from "./translations";
import * as routes from "../constants/routes";
import { GlobalContext } from "../shared/globalContext";
import { agencyNumberReducerUpdated } from "../actions/globalactivity";
import { ONLINE_ONLY } from "../constants/dictionary";
import HelpDeskMessage from "../help-desk/helpDeskMessage";

const FooterContent = ({
  activateUmailCard,
  isSpecialtyPortalView,
  agencyContractBranchCode
}) => {
  const aboutUsText = () => {
    return isSpecialtyPortalView
      ? Translations.footer.about_us_broker
      : Translations.footer.About_Us;
  };

  const aboutUFGText = () => {
    return isSpecialtyPortalView
      ? Translations.footer.about_ufg_specialty
      : Translations.footer.about_ufg;
  };

  const aboutUFGLink = () => {
    return isSpecialtyPortalView
      ? window.env.REACT_APP_ABOUT_UFG_SPECIALTY
      : window.env.REACT_APP_ABOUT_UFG;
  };

  const connectLink = () => {
    return isSpecialtyPortalView
      ? window.env.REACT_APP_CORP_EXTERNAL_WEB_DOMAIN_SPECIALTY
      : window.env.REACT_APP_CORP_EXTERNAL_WEB_DOMAIN;
  };

  const connectLinkText = () => {
    return isSpecialtyPortalView ? "ufgspecialty.com" : "ufginsurance.com";
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-3">
            <h3>{Translations.footer.about_us_heading}</h3>
            <p>{aboutUsText()}</p>
            <p>
              <a href={aboutUFGLink()}>{aboutUFGText()}</a>
            </p>
            <p>
              <a href={window.env.REACT_APP_TERMS_OF_USE_PAGE}>
                {Translations.footer.privacy}
              </a>
            </p>
            <p>
              <a href={window.env.REACT_APP_DISCLAIMER_LEGAL_DOCS}>
                {Translations.footer.disclaimers}
              </a>
            </p>
            <p>
              <Link to={routes.WEBSITEUSAGE}>{Translations.footer.usage}</Link>
            </p>
          </div>
          <div className="col-sm-3">
            <div>
              <div>
                <h3>{Translations.footer.navigate}</h3>
              </div>
            </div>
            <ul>
              <li>
                <Link to={routes.HOME}>{Translations.footer.home}</Link>
              </li>
              <li>
                <Link to={routes.ACCOUNT_HOME}>
                  {Translations.footer.account}
                </Link>
              </li>
              <li>
                <Link to={routes.COMMERCIAL}>
                  {Translations.footer.commercial}
                </Link>
              </li>
              {!isSpecialtyPortalView && (
                <>
                  <li className="nav-path-selected">
                    <Link to={routes.SURETY}>{Translations.footer.surety}</Link>
                  </li>
                </>
              )}
              <li className="nav-path-selected">
                <Link to={routes.REPORTS}>{Translations.footer.reports}</Link>
              </li>
              <li className="nav-path-selected">
                <Link to={routes.RESOURCES}>
                  {Translations.footer.resources}
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <div>
              <div>
                <h3>{Translations.footer.connect}</h3>
              </div>
            </div>
            {!isSpecialtyPortalView &&
              agencyContractBranchCode !== ONLINE_ONLY && (
                <p>
                  <a
                    href={window.env.REACT_APP_MARKETING_SOLUTIONS_BASEURL}
                    target="blank"
                  >
                    ufgmarketingsolutions.com
                  </a>
                </p>
              )}
            <p>
              <a href={connectLink()} target="blank">
                {connectLinkText()}
              </a>
            </p>
            {!isSpecialtyPortalView && (
              <div className="social-media-icons">
                <a href={window.env.REACT_APP_FACEBOOK_URL} target="blank">
                  <img
                    alt="Facebook"
                    data-displaymode="Original"
                    className="social-icon"
                    src={window.env.REACT_APP_FACEBOOK_IMAGE_URL}
                    title="ico-facebook"
                  />
                </a>
                <a href={window.env.REACT_APP_TWITTER_URL} target="blank">
                  <img
                    alt="Twitter"
                    data-displaymode="Original"
                    className="social-icon"
                    src={window.env.REACT_APP_TWITTER_IMAGE_URL}
                    title="ico-twitter"
                  />
                </a>
                <a href={window.env.REACT_APP_LINKEDIN_URL} target="blank">
                  <img
                    alt="LinkedIn"
                    data-displaymode="Original"
                    className="social-icon"
                    src={window.env.REACT_APP_LINKEDIN_IMAGE_URL}
                    title="ico-linkedin"
                  />
                </a>
                <a href={window.env.REACT_APP_INSTAGRAM_URL} target="blank">
                  <img
                    alt="Instagram"
                    data-displaymode="Original"
                    src={window.env.REACT_APP_INSTAGRAM_IMAGE_URL}
                    title="ico-Instagram"
                  />
                </a>
              </div>
            )}
          </div>
          <div className="col-sm-3">
            <div>
              <div>
                <h3>{Translations.footer.contact}</h3>
                <p>
                  <HelpDeskMessage before="For technical assistance," />
                </p>
                {!isSpecialtyPortalView && (
                  <p>
                    <PhoneLink phoneNumber="+1800332797">
                      800-332-7977 (main)
                    </PhoneLink>
                  </p>
                )}
                {isSpecialtyPortalView && (
                  <p>
                    <a href="mailto:specialtymarketing@unitedfiregroup.com">
                      specialtymarketing@unitedfiregroup.com
                    </a>
                  </p>
                )}
                <p>
                  <a href="mailto:claimsufg@unitedfiregroup.com">
                    claimsufg@unitedfiregroup.com
                  </a>
                </p>
                {!isSpecialtyPortalView &&
                  agencyContractBranchCode !== ONLINE_ONLY && (
                    <p>
                      <a href="mailto:doingbusiness@unitedfiregroup.com">
                        doingbusiness@unitedfiregroup.com
                      </a>
                    </p>
                  )}
                {agencyContractBranchCode === ONLINE_ONLY && (
                  <p>
                    <a href="mailto:online@unitedfiregroup.com">
                      online@unitedfiregroup.com
                    </a>
                  </p>
                )}

                {isSpecialtyPortalView && (
                  <p>
                    <a href="mailto:wholesale@unitedfiregroup.com">
                      wholesale@unitedfiregroup.com
                    </a>
                  </p>
                )}
                <div className="ufgmail">
                  <FontAwesomeIcon
                    onClick={activateUmailCard}
                    className="card-menu-icon"
                    icon={farEnvelope}
                  />
                  <span>ufgMail</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const Footer = ({
  activateUmailCard,
  isSpecialtyPortalView,
  agencyContractBranchCode
}) => {
  const { footer } = useContext(GlobalContext);

  return (
    <>
      <Switch>
        <Route
          path={[
            "/quick_quoting*",
            "/underwriter-review",
            routes.SURETY_CONSENT_OF_SURETY,
            routes.SURETY_PACKET_DOWNLOAD,
            routes.BUILDERS_RISK
          ]}
          component={null}
        />
        <Route path={"/online_quoting*"} component={null} />
        <Route>
          <FooterContent
            isSpecialtyPortalView={isSpecialtyPortalView}
            activateUmailCard={activateUmailCard}
            agencyContractBranchCode={agencyContractBranchCode}
          />
        </Route>
      </Switch>

      <div ref={footer} id="footer_bottom" className="footer_bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <p className="copy">
                &copy; {new Date().getFullYear()} UFG Insurance | All rights
                reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const propTypes = {
  activateUmailCard: PropTypes.func.isRequired,
  isSpecialtyPortalView: PropTypes.bool.isRequired,
  agencyContractBranchCode: PropTypes.string
};

Footer.propTypes = propTypes;

const mapStateToProps = state => {
  return {
    agencyContractBranchCode: sessionSelector.getAgencyContractBranchCode(state)
  };
};

const mapDispatchToProps = {
  handleAgentNumberUpdate: agencyNumberReducerUpdated
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
