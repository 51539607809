import React, { useCallback } from "react";
import { Button, Form, FormGroup, Input, useForm } from "@ufginsurance/ui-kit";
import {
  ACCOUNT_NUM_MAX_LENGTH,
  BOND_NUM_LENGTH
} from "../../shared/constants";
import "./quickInquiry.scss";
import { openPopupWindow } from "../../shared/utils";
import { Eportal } from "../../shared/eportal";

export default function QuickInquiry() {
  const handleSubmit = useCallback(({ values }) => {
    if (values.accountBondSearch.length === BOND_NUM_LENGTH) {
      openPopupWindow(Eportal.getBondInfoLink(values.accountBondSearch));
    } else {
      openPopupWindow(Eportal.getAccountInfoLink(values.accountBondSearch));
    }
  }, []);

  const form = useForm({
    values: { accountBondSearch: "" },
    onSubmit: handleSubmit
  });

  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  const handleValidation = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });

    if (
      value &&
      (!(
        value.length <= ACCOUNT_NUM_MAX_LENGTH &&
        value.length >= BOND_NUM_LENGTH
      ) ||
        value === "0000000000" ||
        value === "00000000")
    ) {
      fieldErrors.push("Not a valid account or bond number.");
    }

    return fieldErrors;
  };

  return (
    <div className="quick-inquiry">
      <b>Account/Bond Inquiry</b>
      <p>Inquire on a specific bond number or account number</p>
      <Form context={form}>
        <FormGroup>
          <Input
            placeholder="123..."
            id="accountBondSearch"
            name="accountBondSearch"
            label="Account/Bond Number"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleValidation}
            value={values.accountBondSearch}
            minLength={BOND_NUM_LENGTH}
            maxLength={ACCOUNT_NUM_MAX_LENGTH}
            required
          />
          <div className="uikit__input-wrapper uikit__input-size__auto">
            <label htmlFor="accountBondInquiry">&nbsp;</label>
            <Button
              id="accountBondInquiry"
              type="submit"
              variant="primary"
              disabled={
                !(
                  values.accountBondSearch.length <= ACCOUNT_NUM_MAX_LENGTH &&
                  values.accountBondSearch.length >= BOND_NUM_LENGTH
                )
              }
            >
              Search
            </Button>
          </div>
        </FormGroup>
        <span>
          Dont know your account/bond number?{" "}
          <Button
            isLink
            href={Eportal.accountBondInquiryUrl}
            target="_blank"
            rel="noreferrer"
            className="eportal-link"
          >
            View your account list.
          </Button>
        </span>
      </Form>
    </div>
  );
}
