import React from "react";
import { ToolTip, Icon } from "@ufginsurance/ui-kit";

/**
 * https://ufginsurance.atlassian.net/browse/OOQ-14757
 * if NJ and has a heavy truck, display tooltip in term field label
 */
export const CA7LimitText = (field, quoteData) => {
  const hasAHeavyTruck =
    quoteData?.lobData?.ca7CommAuto?.coverables?.vehicles?.some(
      v => v?.grossVehicleWeight > 26000
    );

  if (quoteData?.baseData?.baseState_UFG === "NJ" && hasAHeavyTruck)
    field.labelElement = (
      <ToolTip
        nowrap={false}
        width={200}
        variant="white"
        content={
          <span>
            Due to New Jersey jurisdictional rules, this liability limit is
            locked. To discuss changing your limit, please contact your
            underwriter
          </span>
        }
        trigger={<Icon size="lg" icon="fasInfoCircle" />}
      />
    );

  return field;
};
