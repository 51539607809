import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as routes from "../../constants/routes";
import { withRouter } from "react-router";
import CommercialLinesContainer from "./commercial-lines/CommercialLinesContainer";
import QuoteProposalContainer from "./commercial-lines/QuoteProposalContainer";
import AgentManualsContainer from "../../agent-manuals/components/AgentManualsContainer";
import DocumentLibrary from "../../document-library/components/DocumentLibrary";
import DocumentLibraryPrint from "../../document-library/components/PrintForms";
import CommercialValueContainer from "./commercial-value/CommercialValueContainer";
import RiskControlContainer from "./risk-control/RiskControlContainer";
import NewBusinessProcedures from "../../new-business/components/NewBusiness";
import ClassAppetiteGuideContainer from "../../class-appetite-guide/containers/ClassAppetiteGuideContainer";
import ViewUnderwritingTeamContainer from "./view-underwriting-team/containers/ViewUnderwritingTeamContainer";

const CommercialHome = props => {
  const { isSpecialtyPortalView } = props;
  return (
    <div>
      <Switch>
        <Redirect exact from={routes.COMMERCIAL} to={routes.COMMERCIAL_LINES} />
        <Route
          exact
          from={routes.COMMERCIAL_LINES}
          component={CommercialLinesContainer}
        />
        <Route
          exact
          from={routes.QUOTE_PROPOSAL}
          component={QuoteProposalContainer}
        />
        <Route path={routes.AGENT_MANUALS} component={AgentManualsContainer} />
        <Route path={routes.DOCUMENT_LIBRARY_PRINT_FORM_NUMBER} component={DocumentLibraryPrint} />
        <Route path={routes.DOCUMENT_LIBRARY} component={DocumentLibrary} />
        <Route
          path={routes.COMMERCIAL_VALUE}
          exact
          component={CommercialValueContainer}
        />
        {!isSpecialtyPortalView && (
          <Route
            path={routes.RISK_CONTROL}
            exact
            component={RiskControlContainer}
          />
        )}
        <Route
          path={routes.NEW_BUSINESS}
          exact
          component={NewBusinessProcedures}
        />
        <Route
          path={routes.NEW_BUSINESS_HEADER}
          exact
          component={NewBusinessProcedures}
        />
        
        <Route
          exact
          path={routes.NEW_BUSINESS_SUB_HEADER}
          component={NewBusinessProcedures}
        />
        {!isSpecialtyPortalView && (
          <>
            <Route
              path={routes.CLASS_APPETITE_GUIDE}
              exact
              component={ClassAppetiteGuideContainer}
            />
          </>
        )}
        <Route
          path={
            isSpecialtyPortalView
              ? routes.VIEW_UNDERWRITING_TEAM
              : routes.VIEW_UNDERWRITING_TEAM_UFG_ONLINE
          }
          exact
          component={ViewUnderwritingTeamContainer}
        />
      </Switch>
    </div>
  );
};

export default withRouter(CommercialHome);
