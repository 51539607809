import React, { useState } from "react";
import {
  ContentHeader,
  FlexRow,
  Button,
  Modal,
  Alert
} from "@ufginsurance/ui-kit";
import "./Confirmation.scss";
import * as routes from "../../../constants/routes";
import PropTypes from "prop-types";
import { clearDocumentsPacketError } from "./actions/documentPacket";
import DocumentUpload from "./DocumentUpload";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const Confirmation = ({
  quickSubmissionData,
  prevSubmissionData,
  history,
  cacheId,
  clearSuretyQuote,
  isNewAccount,
  hasBond,
  isSubmissionError,
  isSubmissionStatusPended,
  isBondHasBidBond,
  isBondHasPerformanceAndPayment,
  activeAgencyCode,
  postQuickDocuments,
  suretyQuickUserAttachments,
  suretyQuickDocumentsPacket,
  fetchDocumentsPacket
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const backToSurety = () => {
    clearSuretyQuote(cacheId);
    history.push(`${routes.HOME_ROOT_FRAGMENT}/surety`);
  };

  const handlePrint = () => {
    setIsFetching(true);
    const queryParams = {
      agency_code: activeAgencyCode,
      account_number: quickSubmissionData.account_number,
      is_new_account: isNewAccount
    };
    if (!!quickSubmissionData.bond_number)
      queryParams.bond_number = quickSubmissionData.bond_number;
    fetchDocumentsPacket(queryParams).then(() => setIsFetching(false));
  };

  const isQuickAccepted =
    quickSubmissionData?.account_status === "QUICK ACCEPTED";

  const isQuickAcceptedAccountOnlyApproved =
    isNewAccount && !isSubmissionStatusPended && !hasBond;

  const isQuickAcceptedExistingAccountOnlyApproved =
    !isNewAccount && !isSubmissionStatusPended && !hasBond;

  const isNewAccountOnlyPended =
    isNewAccount && isSubmissionStatusPended && !hasBond;

  const isExistingAccountOnlyPended =
    !isNewAccount && isSubmissionStatusPended && !hasBond;

  const isNewAccountBidBondApproved =
    isNewAccount && !isSubmissionStatusPended && hasBond && isBondHasBidBond;

  const isNewAccountBidBondPended =
    isNewAccount && isSubmissionStatusPended && hasBond && isBondHasBidBond;

  const isExistingAccountBidBondPended =
    !isNewAccount && isSubmissionStatusPended && hasBond && isBondHasBidBond;

  const isNewAccountPPBondAccepted =
    isNewAccount &&
    isQuickAccepted && // isQuickAccepted after submission
    isSubmissionStatusPended &&
    hasBond &&
    isBondHasPerformanceAndPayment;

  const isNewAccountPPBondPended =
    isNewAccount &&
    isSubmissionStatusPended &&
    hasBond &&
    isBondHasPerformanceAndPayment;

  const isExistingAccountPPBondAccepted =
    !isNewAccount &&
    !isSubmissionStatusPended &&
    hasBond &&
    isBondHasPerformanceAndPayment;

  const isExistingAccountPPBondPended =
    !isNewAccount &&
    isSubmissionStatusPended &&
    hasBond &&
    isBondHasPerformanceAndPayment;

  const isExistingAccountBidBondApproved =
    !isNewAccount && !isSubmissionStatusPended && hasBond && isBondHasBidBond;

  const isPrevQuick = prevSubmissionData?.status === "Q";

  // Show always, except 12.13.5.b and 12.13.5.d.
  const hidePrintButton =
    isPrevQuick &&
    isQuickAccepted &&
    !isNewAccount &&
    isSubmissionStatusPended &&
    hasBond;

  if (isSubmissionError) {
    return (
      <>
        <ContentHeader>Confirmation</ContentHeader>
        <strong className="account_title">
          Thank you for your ufgQuick request. Your submission has been sent to
          your underwriter for review.
        </strong>

        <p className="mt-2">
          We will email you once the request has been reviewed.
        </p>

        <div className="qq_thankyou_text">
          <b>
            The UFG Surety team appreciates your business. We hope you found the
            experience quick and easy-to-use.
          </b>
        </div>

        <FlexRow className="qq__bottom-row">
          <div className="align-right">
            <Button variant="primary" onClick={backToSurety}>
              Back to Surety
            </Button>
          </div>
        </FlexRow>
      </>
    );
  }

  return (
    <>
      <ContentHeader>Confirmation</ContentHeader>
      {!!suretyQuickDocumentsPacket?.error && (
        <>
          <Alert type="error" onDismiss={() => clearDocumentsPacketError()}>
            Sorry, we were unable to retrieve your packet.{" "}
            <HelpDeskMessage display="pleaseTryAgain" />
          </Alert>
          <br />
        </>
      )}
      <div className="confirmation_box">
        <div className="account_title">
          {isQuickAcceptedAccountOnlyApproved && ( // 12.7
            <b>
              Thank you for your ufgQuick request. Your submission has been
              approved.
            </b>
          )}
          {isPrevQuick &&
            isQuickAcceptedExistingAccountOnlyApproved && ( // 12.7a
              <b>
                Thank you for the Quick Account submission. The account is
                APPROVED.
              </b>
            )}
          {!isPrevQuick &&
            isQuickAcceptedExistingAccountOnlyApproved && ( // 12.7b
              <b>
                Thank you for your ufgQuick request. Your submission has been
                approved.
              </b>
            )}
          {!isQuickAccepted &&
            isNewAccountOnlyPended && ( // 12.8
              <b>
                Thank you for your ufgQuick request. Your submission has been
                sent to your underwriter for review.
              </b>
            )}
          {!isQuickAccepted &&
            isExistingAccountOnlyPended && ( // 12.8
              <b>
                Thank you for your ufgQuick request. Your submission has been
                sent to your underwriter for review.
              </b>
            )}
          {!isQuickAccepted &&
            isNewAccountBidBondPended && ( //12.10
              <b>
                Thank you for your ufgQuick request. Your submission has been
                sent to your underwriter for review.
              </b>
            )}
          {!isQuickAccepted &&
            isExistingAccountBidBondPended && ( //12.10
              <b>
                Thank you for your ufgQuick request. Your submission has been
                sent to your underwriter for review.
              </b>
            )}
          {!isQuickAccepted &&
            isNewAccountPPBondPended && ( //12.12
              <b>
                Thank you for your ufgQuick request. Your submission has been
                sent to your underwriter for review.
              </b>
            )}
          {!isQuickAccepted &&
            isExistingAccountPPBondPended && ( //12.12
              <b>
                Thank you for your ufgQuick request. Your submission has been
                sent to your underwriter for review.
              </b>
            )}
          {isNewAccountBidBondApproved && ( // 12.9
            <b>
              Thank you for your ufgQuick request. Your submission has been
              approved.
            </b>
          )}
          {!isQuickAccepted &&
            isExistingAccountBidBondApproved && ( // 12.9
              <b>
                Thank you for your ufgQuick request. Your submission has been
                approved.
              </b>
            )}
          {isQuickAccepted &&
            isExistingAccountBidBondApproved &&
            !isPrevQuick && (
              <b>
                Thank you for your ufgQuick request. Your submission has been
                approved.
              </b>
            )}
          {isQuickAccepted &&
            isNewAccountPPBondAccepted &&
            !isPrevQuick && ( //12.11
              <b>
                Thank you for your ufgQuick request. Your submission has been
                approved pending a signed application and indemnity.
              </b>
            )}
          {isQuickAccepted &&
            isExistingAccountPPBondAccepted &&
            !isPrevQuick && ( //12.11
              <b>
                Thank you for your ufgQuick request. Your submission has been
                approved pending a signed application and indemnity.
              </b>
            )}
          {isQuickAccepted &&
            isExistingAccountPPBondPended &&
            !isPrevQuick && ( //12.11
              <b>
                Thank you for your ufgQuick request. Your submission has been
                approved pending a signed application and indemnity.
              </b>
            )}
          {isQuickAccepted && isExistingAccountOnlyPended && (
            <b>Thank you for the Quick Account submission.</b>
          )}
          {isQuickAccepted &&
            isExistingAccountBidBondApproved &&
            isPrevQuick && ( //12.13.5c
              <b>
                Thank you for your ufgQuick request. Your submission has been
                approved.
              </b>
            )}
          {isQuickAccepted &&
            isExistingAccountPPBondAccepted &&
            isPrevQuick && ( //12.13.5a
              <b>
                Thank you for your ufgQuick request. Your submission has been
                approved.
              </b>
            )}
          {isQuickAccepted &&
            isExistingAccountBidBondPended &&
            isPrevQuick && ( //12.13.5b
              <b>
                Thank you for your ufgQuick request. Your submission has been
                sent to your underwriter for review.
              </b>
            )}
          {isQuickAccepted &&
            isExistingAccountPPBondPended &&
            isPrevQuick && ( //12.13.5d
              <b>
                Thank you for your ufgQuick request. Your submission has been
                sent to your underwriter for review.
              </b>
            )}
        </div>
        {
          // 12.13.5b & 12.13.5d
          isQuickAccepted &&
          (isExistingAccountBidBondPended || isExistingAccountPPBondPended) ? (
            <p className="confirmation_subheading">
              We will email you once the request has been reviewed.
            </p>
          ) : (
            <div className="confirmation_subheading">
              Here&apos;s what you need to know:
            </div>
          )
        }
        <div className="customer_instructions">
          <div>
            {isQuickAcceptedAccountOnlyApproved && ( //12.7a
              <ul>
                <li>The surety packet includes your documents.</li>
                <li>
                  After printing and obtaining signatures, email signed
                  documents to your underwriter or to{" "}
                  <a href="mailto:surety@unitedfiregroup.com">
                    surety@unitedfiregroup.com
                  </a>
                  ; or upload them below.
                </li>
                <li>
                  We will contact you once the line of authority has been
                  issued.
                </li>
              </ul>
            )}
            {!isPrevQuick &&
              isQuickAcceptedExistingAccountOnlyApproved && ( //12.7b
                <ul>
                  <li>The surety packet includes your documents.</li>
                  <li>
                    After printing and obtaining signatures, email signed
                    documents to your underwriter or to{" "}
                    <a href="mailto:surety@unitedfiregroup.com">
                      surety@unitedfiregroup.com
                    </a>
                    ; or upload them below.
                  </li>
                  <li>
                    We will contact you once the line of authority has been
                    issued.
                  </li>
                </ul>
              )}
            {isPrevQuick && isQuickAcceptedExistingAccountOnlyApproved && (
              <p>
                We will issue an account line of authority upon review of the
                signed application and indemnity. You may upload the signed
                application and indemnity OR print the documents to obtain
                signatures and email them to your underwriter or to{" "}
                <a href="mailto:surety@unitedfiregroup.com">
                  surety@unitedfiregroup.com
                </a>
                . You will receive an email when the line of authority has been
                issued.
              </p>
            )}
            {!isQuickAccepted &&
              isNewAccountOnlyPended && ( // 12.8
                <ul>
                  <li>The surety packet includes your documents.</li>
                  <li>
                    {" "}
                    After printing and obtaining signatures, email signed
                    documents to your underwriter or to{" "}
                    <a href="mailto:surety@unitedfiregroup.com">
                      surety@unitedfiregroup.com
                    </a>
                    ; or upload them below.
                  </li>
                </ul>
              )}
            {!isQuickAccepted &&
              isExistingAccountOnlyPended && ( // 12.8
                <ul>
                  <li>The surety packet includes your documents.</li>
                  <li>
                    {" "}
                    After printing and obtaining signatures, email signed
                    documents to your underwriter or to{" "}
                    <a href="mailto:surety@unitedfiregroup.com">
                      surety@unitedfiregroup.com
                    </a>
                    ; or upload them below.
                  </li>
                </ul>
              )}
            {!isQuickAccepted &&
              isNewAccountBidBondPended && ( //12.10
                <ul>
                  <li>The surety packet includes your documents.</li>
                  <li>
                    {" "}
                    After printing and obtaining signatures, email signed
                    documents to your underwriter or to{" "}
                    <a href="mailto:surety@unitedfiregroup.com">
                      surety@unitedfiregroup.com
                    </a>
                    ; or upload them below.
                  </li>
                </ul>
              )}
            {!isQuickAccepted &&
              isExistingAccountBidBondPended && ( //12.10
                <ul>
                  <li>The surety packet includes your documents.</li>
                  <li>
                    {" "}
                    After printing and obtaining signatures, email signed
                    documents to your underwriter or to{" "}
                    <a href="mailto:surety@unitedfiregroup.com">
                      surety@unitedfiregroup.com
                    </a>
                    ; or upload them below.
                  </li>
                </ul>
              )}
            {!isQuickAccepted &&
              isNewAccountPPBondPended && ( //12.12
                <ul>
                  <li>The surety packet includes your documents.</li>
                  <li>
                    {" "}
                    After printing and obtaining signatures, email signed
                    documents to your underwriter or to{" "}
                    <a href="mailto:surety@unitedfiregroup.com">
                      surety@unitedfiregroup.com
                    </a>
                    ; or upload them below.
                  </li>
                </ul>
              )}
            {!isQuickAccepted &&
              isExistingAccountPPBondPended && ( //12.12
                <ul>
                  <li>The surety packet includes your documents.</li>
                  <li>
                    {" "}
                    After printing and obtaining signatures, email signed
                    documents to your underwriter or to{" "}
                    <a href="mailto:surety@unitedfiregroup.com">
                      surety@unitedfiregroup.com
                    </a>
                    ; or upload them below.
                  </li>
                </ul>
              )}
            {isNewAccountBidBondApproved && ( // 12.9
              <ul>
                <li>The surety packet includes your documents.</li>
                <li>
                  {" "}
                  After printing and obtaining signatures, email signed
                  documents to your underwriter or to{" "}
                  <a href="mailto:surety@unitedfiregroup.com">
                    surety@unitedfiregroup.com
                  </a>
                  ; or upload them below.
                </li>
              </ul>
            )}
            {!isQuickAccepted &&
              isExistingAccountBidBondApproved && ( //12.9
                <ul>
                  <li>The surety packet includes your documents.</li>
                  <li>
                    {" "}
                    After printing and obtaining signatures, email signed
                    documents to your underwriter or to{" "}
                    <a href="mailto:surety@unitedfiregroup.com">
                      surety@unitedfiregroup.com
                    </a>
                    ; or upload them below.
                  </li>
                </ul>
              )}
            {isQuickAccepted &&
              isExistingAccountBidBondApproved &&
              !isPrevQuick && ( //12.9c
                <ul>
                  <li>The surety packet includes your documents.</li>
                  <li>
                    {" "}
                    After printing and obtaining signatures, email signed
                    documents to your underwriter or to{" "}
                    <a href="mailto:surety@unitedfiregroup.com">
                      surety@unitedfiregroup.com
                    </a>
                    ; or upload them below.
                  </li>
                </ul>
              )}
            {isQuickAccepted &&
              isNewAccountPPBondAccepted && ( //12.11
                <ul>
                  <li>The surety packet includes your documents.</li>
                  <li>
                    {" "}
                    After printing and obtaining signatures, email signed
                    documents to your underwriter or to{" "}
                    <a href="mailto:surety@unitedfiregroup.com">
                      surety@unitedfiregroup.com
                    </a>
                    ; or upload them below.
                  </li>
                  <li>
                    Your underwriter will be notified to review the documents
                    for signatures. After that you will receive an email with
                    links to print the bond for your customer.
                  </li>
                </ul>
              )}
            {isQuickAccepted &&
              isExistingAccountPPBondPended &&
              !isPrevQuick && ( //12.11
                <ul>
                  <li>The surety packet includes your documents.</li>
                  <li>
                    {" "}
                    After printing and obtaining signatures, email signed
                    documents to your underwriter or to{" "}
                    <a href="mailto:surety@unitedfiregroup.com">
                      surety@unitedfiregroup.com
                    </a>
                    ; or upload them below.
                  </li>
                  <li>
                    Your underwriter will be notified to review the documents
                    for signatures. After that you will receive an email with
                    links to print the bond for your customer.
                  </li>
                </ul>
              )}
            {isQuickAccepted && isExistingAccountOnlyPended && (
              <p>
                The account has been sent to your underwriter to review. You
                will receive a response in one business day. You may upload the
                signed application and indemnity OR print the documents to
                obtain signatures and email them to your underwriter or to{" "}
                <a href="mailto:surety@unitedfiregroup.com">
                  surety@unitedfiregroup.com
                </a>
                . You will receive an email when the account has been reviewed.
              </p>
            )}
            {isQuickAccepted &&
              isPrevQuick &&
              (isExistingAccountBidBondApproved ||
                isExistingAccountPPBondAccepted) && ( //12.13.5a and 12.13.5c
                <ul>
                  <li>The surety packet includes your documents.</li>
                  <li>
                    Please print the surety bond packet for your customer. Your
                    underwriter has received a copy as well and will contact you
                    if there are any questions.
                  </li>
                </ul>
              )}
          </div>
          <div className="button_row">
            <FlexRow className="button_align">
              {!hidePrintButton && (
                <Button
                  onClick={handlePrint}
                  variant="primary"
                  icon="fasPrint"
                  spinner={isFetching}
                >
                  Print Surety Packet
                </Button>
              )}
              <Button
                icon="fasArrowCircleUp"
                variant="primary"
                onClick={() => setIsUploading(true)}
              >
                Upload Documents
              </Button>
            </FlexRow>
          </div>
          <div className="qq_thankyou_text">
            <b>
              The UFG Surety team appreciates your business. We hope you found
              the experience quick and easy-to-use.
            </b>
          </div>
        </div>
        <FlexRow className="qq__bottom-row">
          <div className="align-right">
            <Button variant="primary" onClick={backToSurety}>
              Back to Surety
            </Button>
          </div>
        </FlexRow>
      </div>
      <div className="disclamer-ufg-insura">
        <b>Disclaimer</b>
        <div>
          UFG Insurance reserves the right to review, deny and/or cancel
          underwriting on any bond submitted through this online service. Any
          changes to bonds submitted through this online service must be
          communicated directly to the assigned underwriter. Agents submitting
          bonds through UFG Insurance’s online service also agree to abide by
          all&nbsp;
          <a href="https://www.ufginsurance.com/about-ufg/privacy-terms-of-use">
            Legal Disclaimers and Privacy Notices
          </a>
          &nbsp; posted on UFG Insurance’s website. Abuse or misuse of this
          online service may result in a termination of the Agent’s ability to
          submit bonds online at UFG Insurance’s sole discretion.
        </div>
      </div>
      <Modal
        title="Document Upload"
        show={isUploading}
        onHide={() => setIsUploading(false)}
        body={
          <DocumentUpload
            setIsUploading={setIsUploading}
            quickSubmissionData={quickSubmissionData}
            agencyCode={activeAgencyCode}
            postQuickDocuments={postQuickDocuments}
            suretyQuickUserAttachments={suretyQuickUserAttachments}
          />
        }
      />
    </>
  );
};

export default Confirmation;

Confirmation.propTypes = {
  quickSubmissionData: PropTypes.object.isRequired,
  prevSubmissionData: PropTypes.object.isRequired,
  history: PropTypes.any,
  clearSuretyQuote: PropTypes.func.isRequired,
  cacheId: PropTypes.string.isRequired,
  hasBond: PropTypes.bool.isRequired,
  isSubmissionError: PropTypes.bool,
  isSubmissionStatusPended: PropTypes.bool.isRequired,
  isNewAccount: PropTypes.bool.isRequired,
  isBondHasBidBond: PropTypes.bool.isRequired,
  isBondHasPerformanceAndPayment: PropTypes.bool.isRequired,
  activeAgencyCode: PropTypes.string.isRequired,
  postQuickDocuments: PropTypes.func,
  suretyQuickUserAttachments: PropTypes.object,
  suretyQuickDocumentsPacket: PropTypes.object,
  fetchDocumentsPacket: PropTypes.func
};
