import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../../../translations";
import Iframe from "../../../iframe/Iframe";
import { ContentHeader } from "@ufginsurance/ui-kit";

const ViewUnderwritingTeam = props => {
  const { isHomeAgent } = props;

  const URL = `${
    window.env.REACT_APP_LEGACY_SITE_URL
  }/AgentsOnly/ufgAgent/PcPageOne/AgencyContacts_no_layout.aspx?BusType=${
    isHomeAgent && "true"
  }#Small%20Business%20Unit`;

  return (
    <div>
      <ContentHeader>
        {Translations.commercial.view_underwriting_team}
      </ContentHeader>
      <Iframe url={URL} />
    </div>
  );
};

ViewUnderwritingTeam.propTypes = {
  isHomeAgent: PropTypes.bool
};

export default ViewUnderwritingTeam;
