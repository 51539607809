import React from "react";
import Iframe from "../../components/iframe/Iframe";
import { Alert, ContentHeader } from "@ufginsurance/ui-kit";
import QuickInquiry from "./account-bond-search/QuickInquiry";
const URL = `${window.env.REACT_APP_LEGACY_SITE_URL}/AgentsOnly/ufgBonds/default_commercial_no_layout.aspx`;

const CommercialSurety = () => (
  <>
    <ContentHeader>Commercial Surety</ContentHeader>
    <Alert label="Note:" className="surety-xpress-notification">
      If you would like to issue or order a bond through SuretyXpress, or review
      bond account activity, return to the Home page and select the Surety tab.
    </Alert>
    <QuickInquiry />
    <Iframe url={URL} className="commercial-surety-iframe" />
  </>
);

export default CommercialSurety;
