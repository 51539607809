import * as types from "../constants/actionTypes";
import * as billingService from "../services/billingService";
import { logger } from "../loggers";
import { DocumentService } from "../services/documentService";

export const fetchBillingActivityStarted = () => ({
  type: types.FETCH_BILLING_ACTIVITY_STARTED
});

export const fetchBillingActivitySucceeded = data => ({
  type: types.FETCH_BILLING_ACTIVITY_SUCCEEDED,
  payload: data
});

export const fetchBillingActivityFailed = error => ({
  type: types.FETCH_BILLING_ACTIVITY_FAILED,
  payload: error,
  error: true
});

export const fetchBillingActivityStatement = (
  policy_account_number,
  agency_code
) => {
  return dispatch => {
    dispatch(fetchBillingActivityStarted());

    return billingService
      .getBillingActivity(policy_account_number, agency_code)
      .then(
        res => dispatch(fetchBillingActivitySucceeded(res.data)),
        error => {
          dispatch(fetchBillingActivityFailed(error.response));
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };
};

export const getLegacyViewStatementPDF = (
  bill_type,
  bill_date,
  policy_number,
  category
) => () => {
  const documentService = new DocumentService();
  return billingService
    .getLegacyDocuments(bill_type, bill_date, policy_number, category)
    .then(response => {
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(
        response.data,
        `${policy_number}-legacy-Document.pdf`
      );
    })
    .catch(() => {
      documentService.clearRenderer();
    });
};

export const getGWViewStatementPDF = (
  bill_type,
  policy_number,
  document_id
) => () => {
  const documentService = new DocumentService();
  return billingService
    .getBillingGWDocuments(bill_type, policy_number, document_id)
    .then(response => {
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(
        response.data,
        `${policy_number}-Guidewire-Document.pdf`
      );
    })
    .catch(() => {
      documentService.clearRenderer();
    });
};
