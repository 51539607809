import React, { useState, useEffect, useCallback, useContext } from "react";
import * as routes from "../constants/routes";

import _equal from "lodash/isEqual";
import _set from "lodash/set";
import { ContentHeader } from "@ufginsurance/ui-kit";

import { toast } from "react-toastify";

import { useLocation } from "react-router-dom";

import {
  productKeys,
  productsByLineCode,
  getProductPaths,
  flowValidation,
  cyberDisabledStates
} from "./shared/constants";
import coveragePanels from "./shared/coveragePanels";
import {
  massageMetadata,
  sortCoverages,
  scrollToViewItem
} from "./shared/util";
import {
  workCompFieldOptions,
  addWorkmansCompDetailsFields,
  updateWorkersCompLineFields,
  addWorkmansFEINField,
  updateWorkersCompOfficialId
} from "./step4/workmansCompDetails";
import { AddCyberLinePanelStep4 } from "./shared/CyberLine";
import getAllCoverages, {
  saveLineLevelCoverages
} from "./step4/getAllCoverages";
import BottomNavigation from "./shared/BottomNavigation";
import OnlineQuotingContext from "./OnlineQuotingContext";
import ProductCoverages from "./step4/ProductCoverages.js";
import getExclusionStep4 from "./step4/getExclusionStep4";
import {
  addBopFieldsAtLineLevel,
  updateBopLineFields
} from "./step4/bopLineFields";

import "./Step4PolicyInfo.scss";

const getPanelTitle = lobDataPath => {
  const prodName = lobDataPath?.productName;
  return prodName && productKeys[prodName]
    ? productKeys[prodName].label
    : "Unknown Product";
};

const Step4PolicyInfo = ({ history }) => {
  const {
    quoteData,
    getQuotePart,
    get,
    updateCoveragesPromise,
    updateSupportingDataPromise,
    supportingDataIsUpdating,
    updateLineDetailsPromise,
    navigateToStep,
    pageValidation,
    step,
    supportingData,
    patchQuotePromise,
    loggingData,
    quoteIsUpdating,
    showUpdatingToast,
    closeUpdatingToast,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const { hash } = useLocation();

  useEffect(() => {
    if (step !== 4 && !supportingDataIsUpdating)
      updateSupportingDataPromise({
        dataToMergeAndSave: { currentPage: "4" }
      });
  }, [step, updateSupportingDataPromise, supportingDataIsUpdating]);

  const [panels, setPanels] = useState([]);
  const [coverageExclusions, setCoverageExclusions] = useState();
  // const [wcLineOptions, setWcLineOptions] = useState({});
  const [hiredNonOwnedAllowed, setHiredNonOwnedAllowed] = useState(false);
  const [continueWasClicked, setContinueWasClicked] = useState(false);
  const [navNextSpinner, setNavNextSpinner] = useState(false);

  // handle invalid fields in all panels
  // formIsInvalid state is a boolean used for disabling the button
  const [formIsInvalid, setFormIsInvalid] = useState(false);
  // an object to hold the invalid state of each lob
  const [invalidSections, setInvalidSections] = useState({});

  const primaryLocation = get.location({ isPrimary: true });

  // when we add a LOB on step6, we redirect the user back to step4 with
  // a querystring parameter (eg: /step4/?ca7CommAuto)
  // this useEffect waits for the panels to display on the page and then scrolls the user to the new LOB
  // after the scroll, the history.replace resets the url to the current page so it doesn't scroll after more updates
  useEffect(() => {
    scrollToViewItem({ itemId: `panel-title-${hash}`, hash, history });
    // using coverageExclusions changes to make sure the page is loaded before
    // we do... document.getElementById(...) Exclusions has an api that takes some time to return.
  }, [history, coverageExclusions, hash]);

  const [loadingMetadata, setLoadingMetadata] = useState(false);

  useEffect(() => {
    if (!coverageExclusions && !loadingMetadata) {
      setLoadingMetadata(true);
      getExclusionStep4({
        toastErrr,
        quoteData,
        supportingData,
        callback: (exclusions, hiredNonOwnedAllowed) => {
          setCoverageExclusions(exclusions);
          setHiredNonOwnedAllowed(hiredNonOwnedAllowed);
        }
      });
    }
  }, [
    quoteData,
    supportingData,
    loggingData,
    coverageExclusions,
    loadingMetadata,
    toastErrr
  ]);

  const getAllPolicyCoverages = useCallback(
    (prod, coverageExclusions) => {
      const coverageFields = getAllCoverages({
        coveragePaths: prod,
        getQuotePart
      });

      if (
        prod.productName === productsByLineCode.BP7BusinessOwnersLine &&
        coverageExclusions
      ) {
        coverageFields.push(
          ...addBopFieldsAtLineLevel({
            coverageExclusions,
            supportingData,
            quoteData
          })
        );
      }

      // if quote has Workers Comp, then add the non-coverage fields for WC
      // unshift adds to the begining of the array so these fields appear at the top
      if (prod.productName === productsByLineCode.WCMWorkersCompLine) {
        coverageFields.unshift(
          addWorkmansCompDetailsFields({
            quoteData,
            wcLineOptions: workCompFieldOptions({
              state: primaryLocation.address.state
            })
          })
        );

        if (quoteData?.baseData?.accountHolder?.subtype === "Company")
          coverageFields.unshift(addWorkmansFEINField({ quoteData }));
      }
      return coverageFields;
    },
    [getQuotePart, primaryLocation?.address?.state, quoteData, supportingData]
  );

  const getStep4CoverageValues = useCallback(() => {
    if (!!quoteData?.lobData) {
      const prodPaths = getProductPaths({ quoteData });
      if (!prodPaths.length) return;

      return (
        prodPaths
          .reduce((acc, prod) => {
            const all = getAllPolicyCoverages(prod, coverageExclusions)?.filter(
              c => c.selected
            );

            all.forEach(covg => {
              const coverageId = covg.codeIdentifier || covg.publicID;
              const termVals = covg?.terms.map(t => t.chosenTerm) || [];

              const coverageHash = `${coverageId}:${termVals.join("_")}`;
              acc.push(coverageHash);
            });

            return acc;
          }, [])
          // sort by coverage identifier
          .sort()
      );
    }
  }, [coverageExclusions, getAllPolicyCoverages, quoteData]);

  const continueAction = useCallback(() => {
    const currentStep4CoverageValues = getStep4CoverageValues();
    const previousStep4CoverageValues =
      supportingData?.step4?.step4CoverageValues;

    // compare current values with values from previously viewing step4
    // if are the same, then just navigate to step5, because nothing changed
    if (_equal(currentStep4CoverageValues, previousStep4CoverageValues)) {
      history.push(routes.ONLINE_QUOTING_STEP5);
    }
    // else, save the coverageValues to supporting data and do validations and navigation
    else {
      // save the current changes to supportingData
      updateSupportingDataPromise({
        dataToMergeAndSave: {
          step4: { step4CoverageValues: currentStep4CoverageValues }
        }
      }).then(() => {
        toast.dismiss();
        setNavNextSpinner(true);
        pageValidation(flowValidation.fromStep4, ({ success }) => {
          if (success)
            navigateToStep({ step: 5 }, ({ success }) => {
              if (success) history.push(routes.ONLINE_QUOTING_STEP5);
            });
          else {
            setNavNextSpinner(false);
          }
        });
      });
    }
  }, [
    getStep4CoverageValues,
    history,
    navigateToStep,
    pageValidation,
    supportingData?.step4?.step4CoverageValues,
    updateSupportingDataPromise
  ]);

  const returnToStep3 = useCallback(() => {
    toast.dismiss();
    history.push(routes.ONLINE_QUOTING_STEP3);
  }, [history]);

  const saveCoverages = useCallback(
    (coverage, termToUpdate, action) => {
      saveLineLevelCoverages({
        showUpdatingToast,
        closeUpdatingToast,
        updateCoveragesPromise,
        updateSupportingDataPromise,
        coverage,
        termToUpdate,
        action
      });
    },
    [
      showUpdatingToast,
      closeUpdatingToast,
      updateCoveragesPromise,
      updateSupportingDataPromise
    ]
  );

  const saveNonCoverage = ({ fieldName, value }) => {
    const item = fieldName.split(".");

    if (item[0] === "bop" && item[1] === "details") {
      updateBopLineFields({
        field: item[2],
        value,
        quoteData,
        updateLineDetailsPromise,
        loggingData
      });
    }

    if (item[0] === "workmansComp" && item[1] === "details") {
      updateWorkersCompLineFields({
        field: item[2],
        value,
        quoteData,
        updateLineDetailsPromise,
        loggingData
      });
    }

    if (
      item[0] === "workmansComp" &&
      item[1] === "fein" &&
      value !== quoteData?.baseData?.accountHolder?.officialIds_UFG?.feinid // this makes it save only if the value has changed
    ) {
      updateWorkersCompOfficialId({
        field: item[2],
        value,
        quoteData,
        patchQuotePromise,
        toastErrr
      });
    }
  };

  //OOQ-3049 remove behaves like a toggle add/remove
  const removeCoverageAndSave = coverageToRemove => {
    const action = coverageToRemove.selected === false ? "added" : "removed";
    coverageToRemove.terms = [];
    saveCoverages(coverageToRemove, null, action);
  };

  useEffect(() => {
    if (!!quoteData?.lobData) {
      const prodPaths = getProductPaths({ quoteData });
      const _panels = [];
      if (!prodPaths.length) return;

      prodPaths
        /**
         * FLORIDA CYBER
         * hide cyber if it's Florida and Cyber is not on the submission
         * https://ufginsurance.atlassian.net/browse/OOQ-14909
         */
        .filter(p => {
          if (
            p.productName === "internetSecurity" &&
            !quoteData?.lobData?.internetSecurity &&
            cyberDisabledStates.includes(primaryLocation?.address?.state)
          )
            return p;

          return p;
        })
        .forEach(prod => {
          const coverageFields = sortCoverages(
            getAllPolicyCoverages(prod, coverageExclusions)
          );
          _panels.push({
            panels: massageMetadata(
              coveragePanels({
                fields: coverageFields,
                coverageExclusions,
                removeCoverageAndSave,
                quoteData
                // setCoverageUpdating
              }),
              quoteData
            ),
            allCoverages: coverageFields,
            panelTitle: getPanelTitle(prod),
            coveragePaths: prod
          });
          return _panels;
        });

      if (!_equal(panels, _panels)) {
        setPanels(_panels);
      }
    }
    // this should only update when the quote or the SI values is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteData, coverageExclusions]);

  // function used to update the invalidSections object with each
  // lob invalidField state from the form
  const updateFormStatus = (formId, invalidFields) => {
    if (invalidSections?.[formId] !== !!invalidFields.length) {
      setInvalidSections({
        ...invalidSections,
        [formId]: !!invalidFields.length
      });
    }
  };

  // this useEffect watches the changes in the invalidSections object
  // ... and updates the formIsInvalid state
  useEffect(() => {
    if (
      formIsInvalid !==
      Object.keys(invalidSections).some(s => invalidSections[s])
    )
      setFormIsInvalid(
        Object.keys(invalidSections).some(s => invalidSections[s])
      );
  }, [formIsInvalid, invalidSections]);

  // CONTINUE AFTER SAVE
  useEffect(() => {
    if (continueWasClicked && !quoteIsUpdating && !formIsInvalid) {
      continueAction();
      setContinueWasClicked(false);
    }
  }, [continueAction, continueWasClicked, quoteIsUpdating, formIsInvalid]);

  return (
    <div id="oq-step4" className="oq__step-container">
      <ContentHeader>Does everything look correct?</ContentHeader>
      <div className="oq__coverages-wrapper">
        {panels &&
          panels.length > 0 &&
          panels.map((p, index) => (
            <ProductCoverages
              key={p.coveragePaths.productName}
              panels={p.panels}
              allCoverages={p.allCoverages}
              selectedCoverageTitle={p.panelTitle}
              saveCoverages={saveCoverages}
              saveNonCoverage={saveNonCoverage}
              isOpen={index < 3}
              updateFormStatus={updateFormStatus}
              formId={p.coveragePaths.productName}
              hideAddCoverageButton={
                p.coveragePaths.productName === "internetSecurity"
              }
              showHiredNonAutoSwitches={hiredNonOwnedAllowed}
              coveragePaths={p.coveragePaths}
            />
          ))}
        {!cyberDisabledStates.includes(primaryLocation?.address?.state) && (
          <AddCyberLinePanelStep4 />
        )}
      </div>
      <BottomNavigation
        left={{
          text: "Back to Eligibility",
          onClick: () =>
            setTimeout(() => {
              returnToStep3();
            }),
          disabled: quoteIsUpdating || navNextSpinner || !coverageExclusions
        }}
        right={{
          text: "Continue to Add Risk Information",
          onClick: () => {
            setTimeout(() => {
              setContinueWasClicked(true);
            });
          },
          spinner: continueWasClicked || navNextSpinner,
          disabled:
            quoteIsUpdating ||
            formIsInvalid ||
            navNextSpinner ||
            !coverageExclusions
        }}
      />
    </div>
  );
};

export default Step4PolicyInfo;
