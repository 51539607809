import React, { useEffect, useState, useMemo } from "react";
import * as api from "../../services/onlineQuotingService";
import API from "../../api";
import * as routes from "./../../constants/routes";
import isEqual from "lodash/isEqual";

import {
  ContentHeader,
  Button,
  Input,
  useForm,
  Form,
  FormGroup,
  Table,
  Select,
  LoadingIndicator,
  Icon,
  ToolTip
} from "@ufginsurance/ui-kit";
import { v4 } from "uuid";
import {
  toHtmlSelectOptions,
  arrayToOptions,
  toastError,
  encodeBase64,
  clearLocalQuoteManually
} from "../../online-quoting/shared/util";
import { classCodeFilter } from "../../online-quoting/shared/classCodeFilter";

import cn from "classnames";
import "../../online-quoting/Step2Classify.scss";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { convertStatesAndAbbreviations } from "../Factory";

const OQAppetiteQuide = ({
  history,
  is109015Agent,
  activeAgencyCode,
  activeAgencyState,
  userInfo
}) => {
  const [classData, setClassData] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [loading, setLoading] = useState();

  const [stateOptions, setStateOptions] = useState([]);
  // making USstate as a local state because future component updates will be allowing the user to change this via a dropdown

  const loggingData = useMemo(
    () => ({
      step: 0,
      activeAgencyCode,
      userInfo
    }),
    [activeAgencyCode, userInfo]
  );

  /**
   * initial values is a state because we update the primaryRateState...
   * and we want to reset back to the initial values....
   * and we do a compare/equal to show the reset button based on the intialvalues
   */
  const [initialValues, setInitialValues] = useState({
    business_type: [],
    search: "",
    lessorsRiskIndicator: "No",
    primaryRateState: ""
  });

  const form = useForm({ values: initialValues, onSubmit: () => {} });
  const { handleOnBlur, handleOnValidate, values, handleOnChange, updateForm } =
    form;

  const resetForm = () => updateForm({ values: initialValues });

  useEffect(() => {
    if (!businessTypes.length) {
      /**
       * API calls to get metadata
       *
       * 1. get metadata from ProQuote api: metadata/classifyCustomer-page-metadata
       *      - used to get the Business Type list
       *      - NOTE: this seems uncessary because the Business Type values found in this metadata...
       *          is available in the class table data and could be derived from the table data instead
       *
       * 2. get the list of UFG states from the agency API
       *      - used for the Primary Rate State dropdown options
       */
      const metaDataRequests = [
        api.getBusinessTypes().then(r => r),
        API.agent()
          .get(`/agent-manual/states`)
          .then(r => r)
      ];

      Promise.all(metaDataRequests).then(results => {
        const [getBusinessTypes, ufgStates] = results;

        // Business Types field OPTIONS
        if (!!getBusinessTypes?.data?.formData?.businessTypes_UFG?.length)
          setBusinessTypes(getBusinessTypes?.data?.formData?.businessTypes_UFG);
        // setting to empty array as a "failed" state if the api fails so it doesn't infinite loop
        else setBusinessTypes([]);

        // Primary Rate State OPTIONS
        if (!!ufgStates?.data) {
          const ufgStatesArray = ufgStates?.data?.map(st => st.state);
          setStateOptions(ufgStatesArray);

          // update state dropdown to the agent's primary state
          const defaultSt = activeAgencyState || ufgStatesArray?.[0];
          if (defaultSt) {
            updateForm({ values: { primaryRateState: defaultSt } });

            // also update the initial values, because it's used by the "reset" button
            setInitialValues({ ...initialValues, primaryRateState: defaultSt });
          }
        }
      });
    }
  }, [activeAgencyState, businessTypes.length, initialValues, updateForm]);

  // run first time after supportingData is available and if the class list is empty
  useEffect(() => {
    if (!loading && !classData.length && !is109015Agent) {
      setLoading(true);
      api
        .getClassCodesMetaData({
          businessType: "",
          products: ["bp7BusinessOwners"]
        })
        .then(results => {
          if (!(results && results.data) && results.data.length === 0) {
            //alert("No class codes returns");
            return;
          }
          // set a unique id for the class list
          const data = results.data.map(d => ({
            id: v4(),
            ...d
          }));

          // update the class lists with the new data from the api
          setFilteredClasses(data);
          setClassData(data);
        })
        .catch(error => {
          toastError({
            displayMessage: `An error occurred.  Unable to retreive class codes.`,
            log: {
              ...loggingData,
              action: "load class data",
              error: error?.response || error,
              description: "api load of class data in add class modal",
              severity: "bad",
              type: "general"
            },
            toastId: "oq_additional_class_code_loading"
          });
          //alert("Class codes metadata failure:" + error.response);
          console.error(
            "Class codes metadata failure:" + JSON.stringify(error)
          );
        });
    }
  }, [
    activeAgencyCode,
    classData.length,
    is109015Agent,
    loggingData,
    userInfo,
    loading
  ]);

  // FILTER THE CLASS DATA BASED ON UPDATED CRITERIA
  // filter the classes to include matches for the Business Type dropdown, the search text
  // ... and remove if the class is already selected
  // ... and display the appropriate class depending on the lessors' risk toggle
  useEffect(() => {
    const busType = (values.business_type || []).map(v => v.toLowerCase());
    const searchText = values.search.toLowerCase();
    const lessorsRiskIndicator =
      values.primaryRateState === "FL"
        ? false
        : values.lessorsRiskIndicator === "Yes";

    const filteredData = classCodeFilter({
      data: classData,
      busType,
      searchText,
      lessorsRiskIndicator,
      selectedClasses: []
    });
    // update table data state
    setFilteredClasses(filteredData);
  }, [classData, values]);

  const appetiteValue = ({ clss, st8 }) => {
    return (
      clss?.exceptions?.states?.[st8]?.bp7BusinessOwners ||
      clss?.default?.bp7BusinessOwners
    );
  };

  const tooltipContent = row => {
    const color = appetiteValue({
      clss: row,
      st8: values.primaryRateState || ""
    });
    return color === "green"
      ? "Likely acceptable"
      : color === "yellow"
      ? "May require UW review"
      : "Not eligible for Pro-Quote";
  };

  const colorBar = row => {
    const apptit = appetiteValue({
      clss: row,
      st8: values.primaryRateState || ""
    });
    const color =
      apptit === "green"
        ? "oq__green"
        : apptit === "yellow"
        ? "oq__orange"
        : "oq__red";

    return (
      <Button wrapperClassName="oq__appetite__color-bar__wrapper">
        <span className={cn("oq__appetite__color-bar", color)}></span>
      </Button>
    );
  };

  const addClassButton = row => {
    if (
      appetiteValue({ clss: row, st8: values.primaryRateState || "" }) === "red"
    ) {
      return <span className="oq_unavailable">Unavailable Online</span>;
    }
    return (
      <Button
        className="oq__button__add-class"
        wrapperClassName="oq__add-button__small"
        onClick={() => {
          //GOTO start a quote with a {classcode#description} base64 encoded param.
          const combinedClass = encodeURIComponent(
            encodeBase64(`${row.code}#${row.description}`)
          );
          // reset quote data before starting a new quote
          clearLocalQuoteManually();
          history.push(
            `${routes.ONLINE_QUOTING_STEP1}?combinedClass=${combinedClass}&lr=${
              values.lessorsRiskIndicator === "Yes" ? 1 : 0
            }`
          );
        }}
        size="sm"
        variant="info"
        icon="farPlusCircle"
      >
        Start a Quote
      </Button>
    );
  };

  const tableColumns = [
    {
      key: "code",
      label: "Class Code",
      sortable: true
    },
    {
      key: "description",
      label: "Class Description",
      className: "oq__classes__unselected__description",
      sortable: true
    },
    {
      key: "businessType_UFG",
      label: "Business Type",
      sortable: true
    },
    {
      key: "code",
      label: "Appetite",
      sortable: true,
      className: "oq__appetite__column",
      element: row => {
        return (
          <ToolTip
            id={`appetiteTooltip-${row.id}`}
            inline={false}
            type="hover"
            variant="white"
            trigger={colorBar(row)}
            content={tooltipContent(row)}
          />
        );
      }
    },
    {
      key: "",
      label: "Start a Quote",
      className: "oq__appetite__button",
      element: addClassButton,
      sortable: true,
      align: "right"
    }
  ];

  // if we don't have data at the beginning... show the loading indicator
  if (!classData.length) {
    return <LoadingIndicator />;
  }

  return (
    <div id="oq-appetite">
      <div>
        <ContentHeader>Small business appetite guide</ContentHeader>
        <b>Pro-Quote experience basic eligibility</b>
        <br /> <br />
        <p>
          BOP-Pro businessowners policy from UFG Insurance is designed for a
          wide range of small- to medium-sized businesses, with coverage
          available to owner occupants, lessors of property and tenant
          occupants.
        </p>
        <p>
          Basic eligibility criteria and any eligibility by business type must
          be met to qualify for the Pro-Quote experience. Preferred risk
          characteristics and eligibility criteria are noted in each segment.
          Only the classes listed within each segment are available to quote
          through the Pro-Quote experience.
        </p>
        <p>
          Supporting lines are available for eligible BOP-Pro risks - commercial
          auto, workers compensation and umbrella coverages.
        </p>
        <p>
          Risks that are not eligible for BOP-Pro are not eligible for any of
          the supporting lines.
        </p>
        <p>
          <Icon icon="fasSquare" size="sm" />
          &nbsp; Pro-Tip: Businesses that do not meet Pro-Quote eligibility
          criteria may be eligible for consideration on a commercial package
          policy. Start a conversation with your UFG underwriter or marketing
          representative to learn more.
        </p>
      </div>
      <div>
        <Form className="oq__classes-form" context={form}>
          <FormGroup>
            <Select
              className="oq__rate-state"
              id="primaryRateState"
              name="primaryRateState"
              label="Primary Rate State"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.primaryRateState}
              options={stateOptions.map(st => ({
                label: st,
                value: convertStatesAndAbbreviations(st, "abbr")
              }))}
              isClearable={false}
              size="md"
              isLoading={!stateOptions?.length}
            />
          </FormGroup>
          <FormGroup
            className="oq__classes__searchRow addPadUnder"
            noOuterPadding
            wrap={false}
          >
            <Select
              className="oq__classes__lessorRisk"
              id="lessorsRiskIndicator"
              name="lessorsRiskIndicator"
              label="Lessors' Risk"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={
                values.primaryRateState === "FL"
                  ? "No"
                  : values.lessorsRiskIndicator
              }
              options={arrayToOptions(["No", "Yes"])}
              isClearable={false}
              size="lg"
              disabled={values.primaryRateState === "FL"}
              labelElement={
                values.primaryRateState === "FL" ? (
                  <ToolTip
                    variant="white"
                    nowrap={false}
                    width={150}
                    trigger={<Icon size="1x" icon="fasExclamationCircle" />}
                    content={
                      <span>
                        We are not currently writing Lessors&lsquo; Risk in the
                        state of Florida
                      </span>
                    }
                  />
                ) : null
              }
            />

            <Input
              id="search"
              name="search"
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              label="Search Class or Description"
              onChange={handleOnChange}
              value={values.search}
              className="oq__classes__search"
              maxLength={150}
              size="fill"
            />
            <Select
              id="business_type"
              name="business_type"
              placeholder="Filter by business type..."
              label="Business Type"
              options={toHtmlSelectOptions(businessTypes)}
              className="oq__classes__bussinessType"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.business_type}
              size="md"
              multi
            />
            {!isEqual(values, initialValues) && (
              <Button isLink onClick={resetForm} labelSpace>
                Reset
              </Button>
            )}
          </FormGroup>
          <div className="oq__classes">
            <Table
              className="oq__class-table"
              rowKey="id"
              columns={tableColumns}
              data={filteredClasses}
              showPagination
              itemsPerPage={10}
              noResultsMessage="No results found"
              initialSort="description"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
  activeAgencyState: sessionSelector.getActiveAgencyState(state),
  userInfo: {
    loggedInUser: state?.oidc?.user?.profile?.username,
    employeeUsername: state?.oidc?.user?.profile?.employeeUsername
  }
});

export default connect(mapStateToProps, {})(OQAppetiteQuide);
