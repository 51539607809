import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import {
  Alert,
  Button,
  ContentHeader,
  DatePicker,
  Dropdown,
  FormGroup,
  Input,
  Panel,
  TextArea
} from "@ufginsurance/ui-kit";
import BondSearchInput from "./BondSearchInput";
import Address from "../../../quick-program/quick-quoting/shared/Address";
import { getAllowExistingObligeePoBoxOnly } from "../../../quick-program/quick-quoting/shared/util";

const CoSProjectInfo = ({ form, consentOfSuretyTypes }) => {
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    handleReset,
    updateForm
  } = form;
  const [alertType, setAlertType] = useState();
  const [errorMessageRenewal, setErrorMsg] = useState();

  const setAlertMessage = ({ alertMessage, alertType }) => {
    setErrorMsg(alertMessage);
    setAlertType(alertType);
  };

  const resetAlertMessage = () => {
    setErrorMsg(null);
    setAlertType(null);
  };

  const handleResetBondNumber = () => {
    updateForm({
      values: {
        ...values,
        bond_number: "",
        accountBondSearchInput: "",
        accountBondSearch: ""
      }
    });
    resetAlertMessage();
    handleReset();
  };

  useEffect(() => {
    if (
      (values.bond_status.toLowerCase() === "open" ||
        values.bond_status === "") &&
      (values.bond_type.toLowerCase() === "contract" ||
        values.bond_type === "") &&
      !values.recentConsentOfSurety
    ) {
      updateForm({
        values: {
          ...values,
          isValidForConsentOfSurety: true
        }
      });
    } else {
      updateForm({
        values: {
          ...values,
          isValidForConsentOfSurety: false
        }
      });
      if (!values.recentConsentOfSurety) {
        setAlertMessage({
          alertMessage: "This bond is not eligible for Consent of Surety",
          alertType: "error"
        });
      }
    }
    // Run effect only when the function is specifically called.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.bond_status, values.bond_type, values.recentConsentOfSurety]);

  return (
    <div>
      <ContentHeader>Consent of Surety</ContentHeader>
      <div>
        {errorMessageRenewal && (
          <Alert
            id="warning_msg"
            className="cos__alert"
            dismissible
            type={alertType}
            onDismiss={resetAlertMessage}
          >
            {errorMessageRenewal}
          </Alert>
        )}
      </div>
      <Panel
        rounded
        bgcolor="grey"
        title="Consent Information"
        titlebar
        className="os__formgroup__consent_info"
      >
        <FormGroup>
          <BondSearchInput
            label="Bond Number"
            form={form}
            initialSearch={values.bond_number}
            hasBondUrlParam={!!values.bond_number?.length}
            value={values.bond_number?.selected}
            setAlertMessage={setAlertMessage}
          />
          {(!values.receivedQuery || !values.isValidForConsentOfSurety) && (
            <Button labelSpace isLink onClick={handleResetBondNumber}>
              Reset
            </Button>
          )}
        </FormGroup>
        <FormGroup>
          <Address
            form={form}
            showDefaultAddressField
            disableProjectCity
            disableProjectState
            addressLineFieldLabel="Contractor Physical Address"
            zipPlusFour
          />
        </FormGroup>
        <FormGroup>
          <Dropdown
            id="consent_type"
            name="consent_type"
            label="Consent of Surety Type"
            placeholder="Select a Consent of Surety Type"
            options={consentOfSuretyTypes}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            size="full"
            value={values.consent_type}
            required
          />
        </FormGroup>
      </Panel>

      <Panel
        title="Project Owner / Obligee"
        bgcolor="grey"
        rounded
        titlebar
        className="os__formgroup__obligee_info"
      >
        <FormGroup>
          <Input
            id="obligee_name"
            name="obligee_name"
            label="Obligee Name"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.obligee_name}
            size="fill"
            disabled
            required
          />
        </FormGroup>
        <Address
          form={form}
          prefix="obligee"
          className="fill"
          addressLineFieldLabel="Project Owner/Obligee Address"
          replaceAddressWithPoBox={getAllowExistingObligeePoBoxOnly(values)}
          showDefaultAddressField
          disableProjectCity
          disableProjectState
        />
      </Panel>

      <Panel title="Project Information" bgcolor="grey" rounded titlebar>
        <FormGroup>
          <DatePicker
            id="sign_seal_date"
            name="sign_seal_date"
            label="Sign and Seal Date"
            value={values.sign_seal_date}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            format="YYYY-MM-DD"
            minDate={new Date()}
            required
          />
          {values?.consent_type === "F" && (
            <Input
              id="final_contract_price"
              name="final_contract_price"
              label="Final Contract Price"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.final_contract_price}
              size="auto"
              mask="currencyWithDecimals"
              required
            />
          )}
        </FormGroup>
        <FormGroup>
          <TextArea
            id="project_description"
            name="project_description"
            label="Project Description (you may add to the description for this Consent of Surety Request)"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.project_description}
          />
        </FormGroup>
        {values?.consent_type === "R" && (
          <FormGroup>
            <TextArea
              id="retainage_change_request"
              name="retainage_change_request"
              label="Requested Change In Retainage"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.retainage_change_request}
              required
            />
          </FormGroup>
        )}
      </Panel>

      <Panel title="Optional Information" bgcolor="grey" rounded titlebar>
        <FormGroup>
          <Input
            id="architect_project_num"
            name="architect_project_num"
            label="Architect's Project No"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.architect_project_num}
            size="33p"
          />
          <Input
            id="contract_for"
            name="contract_for"
            label="Contract For"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.contract_for}
            size="33p"
          />
          <DatePicker
            id="contract_dated"
            name="contract_dated"
            label="Contract Dated"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.contract_dated}
            size="33p"
          />
        </FormGroup>
        <FormGroup>
          <Dropdown
            id="all_bills_paid"
            name="all_bills_paid"
            label="Are all bills paid?"
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" }
            ]}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.all_bills_paid}
            size="25p"
          />
          <Input
            id="claims_against_contractor"
            name="claims_against_contractor"
            label="Number of Claims Against Contractor To Date"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.claims_against_contractor}
            size="fill"
            numbersOnly
          />
          <Input
            id="total_claim_value"
            name="total_claim_value"
            label="Total Value of Claims To Date"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.total_claim_value}
            size="33p"
            mask="currency"
            numbersOnly
          />
        </FormGroup>
        <FormGroup>
          <DatePicker
            id="completion_date"
            name="completion_date"
            label="Scheduled Completion Date"
            value={values.completion_date}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            format="YYYY-MM-DD"
            minDate={new Date()}
          />
        </FormGroup>
      </Panel>
    </div>
  );
};

export default withRouter(CoSProjectInfo);
