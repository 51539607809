import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withLDConsumer, useFlags } from "launchdarkly-react-client-sdk";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import CMSLayout from "../../../cms-content/CMSLayout";
import Iframe from "../../../components/iframe/Iframe";
import AgencyInterfaceChecklist from "./AgencyInterfaceChecklist";
import { useCMSContext } from "../../../cms-content/context";
import { addChecklistTrigger } from "../util";
import * as routes from "../../../constants/routes";
import * as agencyInterfaceServices from "../services/AgencyInterfaceServices";

const AgencyInterface = ({
  firstAndLastName,
  agentEmail,
  activeAgencyName,
  agencyCode,
  hasServiceCenterAccess
}) => {
  const { agencyInterfaceEnabled } = useFlags();
  const URL = `${window.env.REACT_APP_LEGACY_SITE_URL}/AgentsOnly/ufgAgent/Resource/AgencyInterface/overview_no_layout.aspx`;

  const {
    cmsData,
    updateAgencyInterfaceChecklistShow,
    fetchAgencyInterfaceContent,
    updateCMSHeaderRoute,
    headerRoute,
    fetchTableOfContents
  } = useCMSContext();

  const openChecklistModal = useCallback(() => {
    updateAgencyInterfaceChecklistShow(true);
  }, [updateAgencyInterfaceChecklistShow]);

  useEffect(() => {
    if (cmsData?.tableOfContents?.data && cmsData?.content?.data)
      addChecklistTrigger(openChecklistModal);
  }, [cmsData, openChecklistModal]);

  useEffect(() => {
    if (headerRoute !== routes.AGENCY_INTERFACE_HEADER) {
      updateCMSHeaderRoute(routes.AGENCY_INTERFACE_HEADER);
    }
  }, [updateCMSHeaderRoute, headerRoute]);

  return (
    <>
      {agencyInterfaceEnabled ? (
        <CMSLayout
          baseRoute={routes.AGENCY_INTERFACE}
          fetchContent={fetchAgencyInterfaceContent}
          defaultBreadcrumbText="Agency Interface"
          service={agencyInterfaceServices}
          fetchTableOfContents={fetchTableOfContents}
        >
          <AgencyInterfaceChecklist
            firstAndLastName={firstAndLastName}
            agentEmail={agentEmail}
            activeAgencyName={activeAgencyName}
            agencyCode={agencyCode}
            hasServiceCenterAccess={hasServiceCenterAccess}
          />
        </CMSLayout>
      ) : (
        <Iframe url={URL} className="agency-interface" />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  firstAndLastName: sessionSelector.getFirstAndLastName(state),
  agentEmail: sessionSelector.getEmail(state),
  activeAgencyName: sessionSelector.getActiveAgencyName(state),
  agencyCode: sessionSelector.getActiveAgencyCode(state),
  hasServiceCenterAccess: sessionSelector.hasServiceCenterAccess(state)
});

export default compose(
  withLDConsumer(),
  connect(mapStateToProps)
)(AgencyInterface);
