import * as types from "../constants/actionTypes";
import * as homePersonalService from "../services/homePersonalService";
import * as clientNoticesService from "../services/clientNoticesService";
import { DocumentService } from "../services/documentService";
import { logger } from "../loggers";
import { Translations } from "../components/translations";

const POLICY_DECS_FAIL_ERROR_MESSAGE =
  Translations.commercial_tab.policy_decs_fail_error_message;

const CLIENT_NOTICES_FAIL_ERROR_MESSAGE =
  Translations.commercial_tab.client_notices_fail_error_message;

const fetchPolicyDecsStarted = () => ({
  type: types.FETCH_PERSONAL_POLICY_DECS_STARTED
});

const fetchPolicyDecsSucceeded = data => ({
  type: types.FETCH_PERSONAL_POLICY_DECS_SUCCEEDED,
  payload: data
});

const fetchPolicyDecsFetchFailed = error => ({
  type: types.FETCH_PERSONAL_POLICY_DECS_FAILED,
  payload: error,
  error: true
});

const fetchClientNoticesStarted = () => ({
  type: types.FETCH_PERSONAL_CLIENT_NOTICES_STARTED
});

export const resetClientNotices = () => ({
  type: types.RESET_PERSONAL_CLIENT_NOTICES
});
const fetchClientNoticesSucceeded = data => ({
  type: types.FETCH_PERSONAL_CLIENT_NOTICES_SUCCEEDED,
  payload: data
});

const fetchClientNoticesFailed = error => ({
  type: types.FETCH_PERSONAL_CLIENT_NOTICES_FAILED,
  payload: error,
  error: true
});

const clientNoticesDeleteSucceeded = () => ({
  type: types.DELETE_PERSONAL_CLIENT_NOTICES_SUCCEEDED
});

const clientNoticesDeleteFailed = error => ({
  type: types.DELETE_PERSONAL_CLIENT_NOTICES_FAILED,
  payload: error
});

export const fetchPolicyDecs = (
  agency_code,
  begin_date,
  end_date,
  company_branch
) => dispatch => {
  dispatch(fetchPolicyDecsStarted());
  return homePersonalService
    .fetchPolicyDecs(agency_code, begin_date, end_date, company_branch)
    .then(
      response => {
        if (response.data && response.data.length > 0)
          dispatch(fetchPolicyDecsSucceeded(response.data));
        else {
          dispatch(fetchPolicyDecsFetchFailed(POLICY_DECS_FAIL_ERROR_MESSAGE));
        }
      },
      error => {
        dispatch(fetchPolicyDecsFetchFailed(POLICY_DECS_FAIL_ERROR_MESSAGE));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};
export const removePolicyDec = (
  agency_code,
  policy,
  process_date,
  begin_date,
  end_date
) => dispatch => {
  return homePersonalService
    .removePolicyDec(agency_code, policy, process_date)
    .then(
      () => {
        dispatch(fetchPolicyDecs(agency_code, begin_date, end_date));
      },
      error => {
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};

export const fetchClientNotices = (
  agency_code,
  begin_date,
  end_date,
  company_branch
) => dispatch => {
  dispatch(fetchClientNoticesStarted());
  return homePersonalService
    .fetchClientNotices(agency_code, begin_date, end_date, company_branch)
    .then(
      response => {
        if (response.data && response.data.length > 0)
          dispatch(fetchClientNoticesSucceeded(response.data));
        else {
          dispatch(fetchClientNoticesFailed(CLIENT_NOTICES_FAIL_ERROR_MESSAGE));
        }
      },
      error => {
        dispatch(fetchClientNoticesFailed(CLIENT_NOTICES_FAIL_ERROR_MESSAGE));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};

export const fetchClientNoticeDocument = data => () => {
  const documentService = new DocumentService();
  return clientNoticesService
    .fetchClientNoticePersonalDocument(data)
    .then(response => {
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(
        response.data,
        `${data.policy_number}-client-notice-personal.pdf`
      );
    })
    .catch(() => {
      documentService.clearRenderer();
    });
};

export const removeClientNotice = (
  agency_code,
  clientNoticeItem,
  begin_date,
  end_date
) => dispatch => {
  return homePersonalService
    .removeClientNotice(agency_code, clientNoticeItem)
    .then(
      () => {
        dispatch(fetchClientNotices(agency_code, begin_date, end_date));
        dispatch(clientNoticesDeleteSucceeded());
      },
      error => {
        dispatch(
          clientNoticesDeleteFailed(Translations.renewalUpdates.errors.default)
        );
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};
