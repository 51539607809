/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Button, FlexRow, LoadingIndicator } from "@ufginsurance/ui-kit";
import _get from "lodash/get";
import {
  quotePath,
  productKeys,
  getCoverageGroupPath
} from "../../shared/constants";
import coveragePanels, {
  applyExclusions,
  getCoverageDetailsForLogging
} from "../../shared/coveragePanels";
import { buildingCoverageControls } from "../../shared/dynamicCoverageControls/building";
import { massageMetadata, sortCoverages, _set } from "../../shared/util";
import { mergeExclusions } from "../../shared/helpers/mergeExclusions";
import buildingCoverages from "./buildingCoverages.js";

import OnlineQuotingContext from "../../OnlineQuotingContext";
import ProductCoverages from "../ProductCoverages.js";
import AdvantageModal from "../../shared/AdvantageModal";
import BadBuildingModal from "./BadBuildingModal.js";
import Step5Context from "../Step5Context";

import "../../oq-globals.scss";

const currentYear = new Date().getFullYear();

const BuildingFormCoveragesStep2 = ({
  onNextStep,
  onPrevStep,
  coverageExclusions,
  setScheduleItemOpenInCoverable,
  building,
  onCancel,
  locations
}) => {
  const {
    quoteData,
    supportingData,
    quoteIsUpdating,
    updateCoveragesPromise,
    validateCoverablesPromise,
    toastErrr
  } = useContext(OnlineQuotingContext);
  const { showBadBuildingModal, setShowBadBuildingModal } =
    useContext(Step5Context);

  const [panels, setPanels] = useState([]);
  const [submitWasClicked, setSubmitWasClicked] = useState(false);
  const [selectedCoverages, setSelectedCoverages] = useState([]);
  const [showAdvantageModal, setShowAdvantageModal] = useState(false);
  const [haveCoveragesChanged, setHaveCoveragesChanged] = useState(false);

  //Current location?
  const selectedLocation = locations.find(
    l => Number(l.bp7LocationFixedID) === Number(building.locationId)
  );

  const isBuildingBad = () => {
    // https://ufginsurance.atlassian.net/browse/OOQ-14723
    const buildingLimit =
      selectedCoverages
        .find(c => c.codeIdentifier === "BP7StructureBuilding")
        ?.terms?.find(t => t.name === "Limit")?.directValue || 0;
    const bpp =
      selectedCoverages
        .find(c => c.codeIdentifier === "BP7ClassificationBusnPrsnlProp")
        ?.terms?.find(t => t.name === "Business Personal Property Limit")
        ?.directValue || 0;

    // if building is bad, stop the user
    const isBadBuilding =
      (
        quoteData?.lobData?.bp7BusinessOwners?.coverables?.businessOwnersLine
          ?.businessType || ""
      ).includes("Restaurant") &&
      currentYear - Number(building?.yearBuilt) < 26 &&
      ["Frame Construction", "Joisted Masonry"].includes(
        building?.constructionType
      ) &&
      ((building?.automaticSprinklerSystemPresent === "No" &&
        buildingLimit + bpp > 500000) ||
        (building?.automaticSprinklerSystemPresent === "Yes" &&
          buildingLimit + bpp > 3500000));

    return isBadBuilding;
  };

  //on submit
  const onSubmit = () => {
    setSubmitWasClicked(false);

    if (isBuildingBad()) {
      setShowBadBuildingModal(true);
      return;
    }

    if (!haveCoveragesChanged) {
      onNextStep();
      return;
    }

    validateCoverablesPromise({
      coverableName: "building",
      coverablefixedId: building?.fixedId,
      classIds: (building?.classifications || []).map(c => c.fixedId)
    }).then(({ success }) => {
      setHaveCoveragesChanged(false);
      if (success) onNextStep();
    });
  };

  const updateBuildingCoverages = (
    coverage,
    termToUpdate,
    action = "added"
  ) => {
    // object shape of the coverage to be updated:
    // lobData.bp7BusinessOwners.coverages.buildingClausesGroups(filter by coverableFixedId)[0].clausesGroups.
    // either defaults:[] or coverages:[] or addlCoverages:[],

    if (!getCoverageGroupPath(coverage.coverageCategoryCode))
      toastErrr({
        action: "BuildingFormCoveragesStep2 > onSubmit",
        description: "unable to update coverage",
        misc: {
          details: `getCoverageGroupPath call failed to get ${coverage?.coverageCategoryCode} (coverage.coverageCategoryCode)`,
          termToUpdate,
          coverage,
          action
        },
        displayMessage: `An error occurred. Coverage(s) were not ${action} (category unknown).`
      });

    const coverableToSave = { coverableFixedId: coverage.coverableFixedId };

    _set(coverableToSave, getCoverageGroupPath(coverage.coverageCategoryCode), [
      coverage
    ]);

    const coverageType =
      coverage.coverableType === "building"
        ? "buildingClausesGroups"
        : "classificationClausesGroups";

    const coveragesToSave = {
      bp7BusinessOwners: {
        [coverageType]: [coverableToSave]
      }
    };

    updateCoveragesPromise({
      coveragesToSave,
      productName: "bp7BusinessOwners",
      coveragePath: quotePath.bp7BuildingCoverages,
      coverageDetails: getCoverageDetailsForLogging({
        coverage,
        termToUpdate,
        action
      })
    }).then(({ data }) => {
      setHaveCoveragesChanged(true);
      const advantagePoint = _get(
        data,
        quotePath.bp7BuildingCoverages,
        []
      ).find(
        c => c.coverableFixedId === building.fixedId
      )?.advantagePointDistanceToCoastRules;

      if (!!advantagePoint) {
        setShowAdvantageModal(true);
      }

      if (submitWasClicked) {
        onSubmit();
      }
      setSubmitWasClicked(false);
    });
  };

  const removeCoverageAndSave = coverageToRemove => {
    coverageToRemove.selected = !coverageToRemove.selected;
    coverageToRemove.terms = [];
    updateBuildingCoverages(
      coverageToRemove,
      null,
      coverageToRemove.selected ? "added" : "removed"
    );
  };

  const getAllCoveragesFromQuote = useCallback(
    buildingFixedId => {
      return buildingCoverages(quoteData, buildingFixedId);
    },
    [quoteData]
  );

  useEffect(() => {
    if (quoteData && !!building?.fixedId) {
      const _panels = [];
      const _coverageFields = sortCoverages(
        getAllCoveragesFromQuote(building.fixedId)
      );
      const mergedExclusions = mergeExclusions([
        coverageExclusions,
        buildingCoverageControls({ building, supportingData, quoteData })
      ]);
      const visibleCoverages = applyExclusions(
        _coverageFields,
        mergedExclusions
      );
      setSelectedCoverages(visibleCoverages.filter(x => x.selected));
      _panels.push({
        buildingId: building.fixedId,
        panels: massageMetadata(
          coveragePanels({
            fields: visibleCoverages,
            coverageExclusions: mergedExclusions,
            removeCoverageAndSave,
            quoteData,
            setScheduleItemOpenInCoverable
          }),
          quoteData
        ),
        allCoverages: visibleCoverages
      });
      setPanels(_panels);
    }
  }, [quoteData, coverageExclusions]);

  // manage the status of the form errors to disable the continue button
  const [formValidStatus, setFormValidStatus] = useState(false);
  const updateFormStatus = (formId, invalidFields) => {
    if (formId && invalidFields) {
      const hasErrors = invalidFields.length > 0;
      if (formId === "coverableCoverages" && formValidStatus !== hasErrors)
        setFormValidStatus(hasErrors);
    }
  };

  const goToPreviousStep = building => {
    if (isBuildingBad()) {
      setShowBadBuildingModal(true);
      return;
    }

    //if there were changes...run validation
    if (!haveCoveragesChanged) {
      onPrevStep(building.fixedId);
    } else {
      validateCoverablesPromise({
        coverableName: "building",
        coverablefixedId: building?.fixedId,
        classIds: (building?.classifications || []).map(c => c.fixedId)
      }).then(({ success }) => {
        setHaveCoveragesChanged(false);
        if (success) onPrevStep(building.fixedId);
      });
    }
  };

  // loading indicator
  if (!coverageExclusions) return <LoadingIndicator />;

  return (
    <div id="oq__building__step2" className="oq__form__building__step2">
      <>
        {showAdvantageModal ? (
          <AdvantageModal Location={selectedLocation} onCancel={onCancel} />
        ) : (
          <div className="oq__coverage-wrapper">
            {panels &&
              panels.length > 0 &&
              panels.map((p, index) => (
                <ProductCoverages
                  key={p.buildingId}
                  panels={p.panels}
                  quoteData={quoteData}
                  allCoverages={p.allCoverages}
                  selectedCoverageTitle={productKeys.bp7BusinessOwners.label}
                  saveCoverages={updateBuildingCoverages}
                  isOpen={index < 3}
                  popupCoverageSearch={false}
                  updateFormStatus={updateFormStatus}
                  formId="coverableCoverages"
                  searchTableRowKey="rowKey"
                />
              ))}
            {showBadBuildingModal && <BadBuildingModal building={building} />}
            <FlexRow>
              <Button
                isLink
                inline
                className="back"
                disabled={
                  (formValidStatus && selectedCoverages.length > 0) ||
                  quoteIsUpdating
                }
                onClick={() => {
                  goToPreviousStep(building);
                }}
              >
                Back to Building Details
              </Button>

              <Button
                onMouseDown={() => setSubmitWasClicked(true)}
                variant="primary"
                className="continue"
                disabled={
                  (formValidStatus && selectedCoverages.length > 0) ||
                  quoteIsUpdating
                }
                wrapperClassName="push-right"
                onClick={onSubmit}
              >
                {!haveCoveragesChanged ? "Close" : "Continue"}
              </Button>
            </FlexRow>
          </div>
        )}
      </>
    </div>
  );
};

export default BuildingFormCoveragesStep2;
