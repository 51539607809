//Ufg Agent Portal View State
export const CHANGE_TO_UFG_AGENT_PORTAL_VIEW_STATE_SUCCEEDED =
  "CHANGE_TO_UFG_AGENT_PORTAL_VIEW_STATE_SUCCEEDED";
export const CHANGE_TO_UFG_SPECIALTY_PORTAL_VIEW_STATE_SUCCEEDED =
  "CHANGE_TO_UFG_SPECIALTY_PORTAL_VIEW_STATE_SUCCEEDED";
export const CHANGE_TO_MERCER_PORTAL_VIEW_STATE_SUCCEEDED =
  "CHANGE_TO_MERCER_PORTAL_VIEW_STATE_SUCCEEDED";

export const AGENCY_CODE_CHANGED = "AGENCY_CODE_CHANGED";

export const FETCH_MY_POLICY_INFO_STARTED = "FETCH_MY_POLICY_INFO_STARTED";
export const FETCH_MY_POLICY_INFO_SUCCEEDED = "FETCH_MY_POLICY_INFO_SUCCEEDED";
export const FETCH_MY_POLICY_INFO_FAILED = "FETCH_MY_POLICY_INFO_FAILED";

export const FETCH_ACTIVITY_STARTED = "FETCH_ACTIVITY_STARTED";
export const FETCH_ACTIVITY_SUCCEEDED = "FETCH_ACTIVITY_SUCCEEDED";
export const FETCH_ACTIVITY_FAILED = "FETCH_ACTIVITY_FAILED";

// Account overview
export const FETCH_ACCOUNT_INFO_STARTED = "FETCH_MY_ACCOUNT_INFO_STARTED";
export const FETCH_ACCOUNT_INFO_SUCCEEDED = "FETCH_MY_ACCOUNT_INFO_SUCCEEDED";
export const FETCH_ACCOUNT_INFO_FAILED = "FETCH_MY_ACCOUNT_INFO_FAILED";
export const LEFT_ACCOUNT_OVERVIEW = "LEFT_ACCOUNT_OVERVIEW";

export const FETCH_ACCOUNT_SERVICE_LINKS_STARTED =
  "FETCH_ACCOUNT_SERVICE_LINKS_STARTED";
export const FETCH_ACCOUNT_SERVICE_LINKS_SUCCEEDED =
  "FETCH_ACCOUNT_SERVICE_LINKS_SUCCEEDED";
export const FETCH_ACCOUNT_SERVICE_LINKS_FAILED =
  "FETCH_ACCOUNT_SERVICE_LINKS_FAILED";

export const FETCH_AUTO_ID_CARDS_STARTED = "FETCH_AUTO_ID_CARDS_STARTED";
export const FETCH_AUTO_ID_CARDS_SUCCEEDED = "FETCH_AUTO_ID_CARDS_SUCCEEDED";
export const FETCH_AUTO_ID_CARDS_FAILED = "FETCH_AUTO_ID_CARDS_FAILED";

export const FETCH_DRIVERS_LIST_REPORT_STARTED =
  "FETCH_DRIVERS_LIST_REPORT_STARTED";
export const FETCH_DRIVERS_LIST_REPORT_SUCCEEDED =
  "FETCH_DRIVERS_LIST_REPORT_SUCCEEDED";
export const FETCH_DRIVERS_LIST_REPORT_FAILED =
  "FETCH_DRIVERS_LIST_REPORT_FAILED";

export const FETCH_VEHICLES_LIST_REPORT_STARTED =
  "FETCH_VEHICLES_LIST_REPORT_STARTED";
export const FETCH_VEHICLES_LIST_REPORT_SUCCEEDED =
  "FETCH_VEHICLES_LIST_REPORT_SUCCEEDED";
export const FETCH_VEHICLES_LIST_REPORT_FAILED =
  "FETCH_VEHICLES_LIST_REPORT_FAILED";

// TODO - These only appear to be used by action creators and reducer
// Delete the renewal code?
export const FETCH_RENEWAL_STARTED = "FETCH_RENEWAL_STARTED";
export const FETCH_RENEWAL_SUCCEEDED = "FETCH_RENEWAL_SUCCEEDED";
export const FETCH_RENEWAL_FAILED = "FETCH_RENEWAL_FAILED";

// billing activities
export const BILLED_BY_STATEMENT = "BILLED_BY_STATEMENT";

export const FETCH_BILLING_STARTED = "FETCH_BILLING_STARTED";
export const FETCH_BILLING_SUCCEEDED = "FETCH_BILLING_SUCCEEDED";
export const FETCH_BILLING_FAILED = "FETCH_BILLING_FAILED";

export const BILLING_HISTORY_PRINT_POST_STARTED =
  "BILLING_HISTORY_PRINT_POST_STARTED";
export const BILLING_HISTORY_PRINT_POST_SUCCEEDED =
  "BILLING_HISTORY_PRINT_POST_SUCCEEDED";
export const BILLING_HISTORY_PRINT_POST_FAILED =
  "BILLING_HISTORY_PRINT_POST_FAILED";

export const FETCH_BILLING_ACTIVITY_STARTED = "FETCH_BILLING_ACTIVITY_STARTED";
export const FETCH_BILLING_ACTIVITY_SUCCEEDED =
  "FETCH_BILLING_ACTIVITY_SUCCEEDED";
export const FETCH_BILLING_ACTIVITY_FAILED = "FETCH_BILLING_ACTIVITY_FAILED";

export const FETCH_DIRECT_BILL_PAY_NOW_ENROLLMENT_STARTED =
  "FETCH_DIRECT_BILL_PAY_NOW_ENROLLMENT_STARTED";
export const FETCH_DIRECT_BILL_PAY_NOW_ENROLLMENT_SUCCEEDED =
  "FETCH_DIRECT_BILL_PAY_NOW_ENROLLMENT_SUCCEEDED";
export const FETCH_DIRECT_BILL_PAY_NOW_ENROLLMENT_FAILED =
  "FETCH_DIRECT_BILL_PAY_NOW_ENROLLMENT_FAILED";

export const FILTER_BILLING_ACTIVITY_STARTED =
  "FILTER_BILLING_ACTIVITY_STARTED";
export const FILTER_BILLING_ACTIVITY_SUCCEEDED =
  "FILTER_BILLING_ACTIVITY_SUCCEEDED";

//Policy Search Actions
export const FETCH_MY_POLICY_SEARCH_STARTED = "FETCH_MY_POLICY_SEARCH_STARTED";
export const FETCH_MY_POLICY_SEARCH_SUCCEEDED =
  "FETCH_MY_POLICY_SEARCH_SUCCEEDED";
export const FETCH_MY_POLICY_SEARCH_FAILED = "FETCH_MY_POLICY_SEARCH_FAILED";
export const SEARCH_KEYWORD_UPDATED = "SEARCH_KEYWORD_UPDATED";
export const FETCH_MY_POLICY_SEARCH_RETURNED_NODATA =
  "FETCH_MY_POLICY_SEARCH_RETURNED_NODATA";
export const DISMISS_MODAL_WINDOW = "DISMISS_MODAL_WINDOW";
export const RESET_POLICY_SEARCH_KEYWORD = "RESET_POLICY_SEARCH_KEYWORD";
export const SET_POLICY_SEARCH_TYPE = "SET_POLICY_SEARCH_TYPE";

export const FETCH_POLICY_TERMS_HISTORY_STARTED =
  "FETCH_POLICY_TERMS_HISTORY_STARTED";
export const FETCH_POLICY_TERMS_HISTORY_SUCCESS =
  "FETCH_POLICY_TERMS_HISTORY_SUCCESS";
export const FETCH_POLICY_TERMS_HISTORY_FAILED =
  "FETCH_POLICY_TERMS_HISTORY_FAILED";

//commercial
export const FETCH_QUOTE_PROPOSAL_SUCCEEDED = "FETCH_QUOTE_PROPOSAL_SUCCEEDED";
export const FETCH_QUOTE_PROPOSAL_START = "FETCH_QUOTE_PROPOSAL_START";
export const FETCH_QUOTE_PROPOSAL_FAILED = "FETCH_QUOTE_PROPOSAL_FAILED";
export const UPDATE_QUOTE_PROPOSAL_FILTER = "UPDATE_QUOTE_PROPOSAL_FILTER";

export const FETCH_SUBMISSION_LIST_START = "FETCH_SUBMISSION_LIST_START";
export const FETCH_SUBMISSION_LIST_SUCCEEDED =
  "FETCH_SUBMISSION_LIST_SUCCEEDED";
export const DELETE_QUOTE_SUCCEEDED = "DELETE_QUOTE_SUCCEEDED";
export const FETCH_SUBMISSION_LIST_FAILED = "FETCH_SUBMISSION_LIST_FAILED";
export const UPDATE_SUBMISSION_LIST_FILTER = "UPDATE_SUBMISSION_LIST_FILTER";

export const FETCH_INSTALLMENT_STARTED = "FETCH_INSTALLMENT_STARTED";
export const FETCH_INSTALLMENT_SUCCEEDED = "FETCH_INSTALLMENT_SUCCEEDED";
export const FETCH_INSTALLMENT_FAILED = "FETCH_INSTALLMENT_FAILED";

export const INSTALLMENT_PRINT_POST_STARTED = "INSTALLMENT_PRINT_POST_STARTED";
export const INSTALLMENT_PRINT_POST_SUCCEEDED =
  "INSTALLMENT_PRINT_POST_SUCCEEDED";
export const INSTALLMENT_PRINT_POST_FAILED = "INSTALLMENT_PRINT_POST_FAILED";

export const FETCH_CLASS_APPETITE_STATES_STARTED =
  "FETCH_CLASS_APPETITE_STATES_STARTED";
export const FETCH_CLASS_APPETITE_STATES_SUCCEEDED =
  "FETCH_CLASS_APPETITE_STATES_SUCCEEDED";
export const FETCH_CLASS_APPETITE_STATES_FAILED =
  "FETCH_CLASS_APPETITE_STATES_FAILED";

export const FETCH_CLASS_APPETITE_PRODUCTS_STARTED =
  "FETCH_CLASS_APPETITE_PRODUCTS_STARTED";
export const FETCH_CLASS_APPETITE_PRODUCTS_SUCCEEDED =
  "FETCH_CLASS_APPETITE_PRODUCTS_SUCCEEDED";
export const FETCH_CLASS_APPETITE_PRODUCTS_FAILED =
  "FETCH_CLASS_APPETITE_PRODUCTS_FAILED";

export const FETCH_CLASS_APPETITE_CLASSES_FILTER =
  "FETCH_CLASS_APPETITE_CLASSES_FILTER";

export const FETCH_CLASS_APPETITE_CLASSES_STARTED =
  "FETCH_CLASS_APPETITE_CLASSES_STARTED";
export const FETCH_CLASS_APPETITE_CLASSES_SUCCEEDED =
  "FETCH_CLASS_APPETITE_CLASSES_SUCCEEDED";
export const FETCH_CLASS_APPETITE_CLASSES_FAILED =
  "FETCH_CLASS_APPETITE_CLASSES_FAILED";

//Account change requests
export const FETCH_CHANGE_REQUEST_ELEGIBILTY_INFO_STARTED =
  "FETCH_CHANGE_REQUEST_ELEGIBILTY_INFO_STARTED";
export const FETCH_CHANGE_REQUEST_ELEGIBILTY_INFO_SUCCEEDED =
  "FETCH_CHANGE_REQUEST_ELEGIBILTY_INFO_SUCCEEDED";
export const FETCH_CHANGE_REQUEST_ELEGIBILTY_INFO_FAILED =
  "FETCH_CHANGE_REQUEST_ELEGIBILTY_INFO_FAILED";

export const FETCH_POST_REQUEST_ELEGIBILTY_INFO_STARTED =
  "FETCH_POST_REQUEST_ELEGIBILTY_INFO_STARTED";
export const FETCH_POST_REQUEST_ELEGIBILTY_INFO_SUCCEEDED =
  "FETCH_POST_REQUEST_ELEGIBILTY_INFO_SUCCEEDED";
export const FETCH_POST_REQUEST_ELEGIBILTY_INFO_FAILED =
  "FETCH_POST_REQUEST_ELEGIBILTY_INFO_FAILED";

// submission info
export const FETCH_ATTACHMENT_LIST = "FETCH_ATTACHMENT_LIST";
export const RESET_ATTACHMENTS = "RESET_ATTACHMENTS";
export const FETCH_POLICY_ACTIVITY_LIST_STARTED =
  "FETCH_POLICY_ACTIVITY_LIST_STARTED";
export const FETCH_POLICY_ACTIVITY_LIST_SUCCEEDED =
  "FETCH_POLICY_ACTIVITY_LIST_SUCCEEDED";
export const FETCH_POLICY_ACTIVITY_LIST_FAILED =
  "FETCH_POLICY_ACTIVITY_LIST_FAILED";

export const UPLOAD_ATTACHMENT_PROGRESS = "UPLOAD_ATTACHMENT_PROGRESS";
export const UPLOAD_ATTACHMENT_SUCCESS = "UPLOAD_ATTACHMENT_SUCCESS";
export const UPLOAD_ATTACHMENT_FAIL = "UPLOAD_ATTACHMENT_FAIL";

export const FETCH_PROPOSAL_DOCUMENT_STARTED =
  "FETCH_PROPOSAL_DOCUMENT_STARTED";
export const FETCH_PROPOSAL_DOCUMENT_SUCCESS =
  "FETCH_PROPOSAL_DOCUMENT_SUCCESS";
export const FETCH_PROPOSAL_DOCUMENT_FAIL = "FETCH_PROPOSAL_DOCUMENT_FAIL";

export const FETCH_POLICY_ACTIVITY_CANCELLATION_LIST_STARTED =
  "FETCH_POLICY_ACTIVITY_CANCELLATION_LIST_STARTED";
export const FETCH_POLICY_ACTIVITY_CANCELLATION_LIST_SUCCEEDED =
  "FETCH_POLICY_ACTIVITY_CANCELLATION_LIST_SUCCEEDED";
export const FETCH_POLICY_ACTIVITY_CANCELLATION_LIST_FAILED =
  "FETCH_POLICY_ACTIVITY_CANCELLATION_LIST_FAILED";

export const FETCH_NEWS_CURRENT_STARTED = "FETCH_NEWS_CURRENT_STARTED";
export const FETCH_NEWS_CURRENT_SUCCEEDED = "FETCH_NEWS_CURRENT_SUCCEEDED";
export const FETCH_NEWS_CURRENT_FAILED = "FETCH_NEWS_CURRENT_FAILED";

export const FETCH_NEWS_ARCHIVE_STARTED = "FETCH_NEWS_ARCHIVE_STARTED";
export const FETCH_NEWS_ARCHIVE_SUCCEEDED = "FETCH_NEWS_ARCHIVE_SUCCEEDED";
export const FETCH_NEWS_ARCHIVE_FAILED = "FETCH_NEWS_ARCHIVE_FAILED";

export const SEARCH_KEYWORD_UPDATED_REPORT_CLAIMS =
  "SEARCH_KEYWORD_UPDATED_REPORT_CLAIMS";
//Messages
export const FETCH_MESSAGES_STARTED = "FETCH_MESSAGES_STARTED";
export const FETCH_MESSAGES_SUCCEEDED = "FETCH_MESSAGES_SUCCEEDED";
export const FETCH_MESSAGES_FAILED = "FETCH_MESSAGES_FAILED";
export const RESET_MESSAGES = "RESET_MESSAGES";
export const MARK_CONVERSATION_READ = "MARK_CONVERSATION_READ";
export const START_CONVERSATIONS_STARTED = "START_CONVERSATIONS_STARTED";
export const START_CONVERSATIONS_SUCCEEDED = "START_CONVERSATIONS_SUCCEEDED";
export const CONVERSATIONS_FAILED = "CONVERSATIONS_FAILED";
export const REPLY_MESSAGE_STARTED = "REPLY_MESSAGE_STARTED";
export const REPLY_MESSAGE_SUCCEEDED = "REPLY_MESSAGE_SUCCEEDED";
export const REPLY_FAILED = "REPLY_FAILED";

export const FETCH_CURRENT_SUMMARY_STARTED = "FETCH_CURRENT_SUMMARY_STARTED";
export const FETCH_CURRENT_SUMMARY_SUCCEEDED =
  "FETCH_CURRENT_SUMMARY_SUCCEEDED";
export const FETCH_CURRENT_SUMMARY_FAILED = "FETCH_CURRENT_SUMMARY_FAILED";

export const FETCH_OASIS_DOCUMENT_SUMMARY_STARTED =
  "FETCH_OASIS_DOCUMENT_SUMMARY_STARTED";
export const FETCH_OASIS_DOCUMENT_SUMMARY_SUCCEEDED =
  "FETCH_OASIS_DOCUMENT_SUMMARY_SUCCEEDED";
export const FETCH_OASIS_DOCUMENT_SUMMARY_FAILED =
  "FETCH_OASIS_DOCUMENT_SUMMARY_FAILED";

export const FETCH_UFG_DOCUMENT_SUMMARY_STARTED =
  "FETCH_UFG_DOCUMENT_SUMMARY_STARTED";
export const FETCH_UFG_DOCUMENT_SUMMARY_SUCCEEDED =
  "FETCH_UFG_DOCUMENT_SUMMARY_SUCCEEDED";
export const FETCH_UFG_DOCUMENT_SUMMARY_FAILED =
  "FETCH_UFG_DOCUMENT_SUMMARY_FAILED";

//home commercial
export const FETCH_RENEWAL_UPDATES_STARTED = "FETCH_RENEWAL_UPDATES_STARTED";
export const FETCH_RENEWAL_UPDATES_SUCCEEDED =
  "FETCH_RENEWAL_UPDATES_SUCCEEDED";
export const FETCH_RENEWAL_UPDATES_FAILED = "FETCH_RENEWAL_UPDATES_FAILED";
export const DELETE_RENEWAL_UPDATES_SUCCEEDED =
  "DELETE_RENEWAL_UPDATES_SUCCEEDED";
export const DELETE_RENEWAL_UPDATES_FAILED = "DELETE_RENEWAL_UPDATES_FAILED";

export const FETCH_COMMERCIAL_POLICY_DECS_STARTED =
  "FETCH_COMMERCIAL_POLICY_DECS_STARTED";
export const FETCH_COMMERCIAL_POLICY_DECS_SUCCEEDED =
  "FETCH_COMMERCIAL_POLICY_DECS_SUCCEEDED";
export const FETCH_COMMERCIAL_POLICY_DECS_FAILED =
  "FETCH_COMMERCIAL_POLICY_DECS_FAILED";
export const FETCH_POLICY_DECS_COMMERICIAL_DOCUMENT_FAILED =
  "FETCH_POLICY_DECS_COMMERICIAL_DOCUMENT_FAILED";

export const FETCH_COMMERCIAL_CLIENT_NOTICES_STARTED =
  "FETCH_COMMERCIAL_CLIENT_NOTICES_STARTED";
export const FETCH_COMMERCIAL_CLIENT_NOTICES_SUCCEEDED =
  "FETCH_COMMERCIAL_CLIENT_NOTICES_SUCCEEDED";
export const FETCH_COMMERCIAL_CLIENT_NOTICES_FAILED =
  "FETCH_COMMERCIAL_CLIENT_NOTICES_FAILED";
export const DELETE_COMMERCIAL_CLIENT_NOTICES_SUCCEEDED =
  "DELETE_COMMERCIAL_CLIENT_NOTICES_SUCCEEDED";
export const DELETE_COMMERCIAL_CLIENT_NOTICES_FAILED =
  "DELETE_COMMERCIAL_CLIENT_NOTICES_FAILED";
export const RESET_COMMERCIAL_CLIENT_NOTICES =
  "RESET_COMMERCIAL_CLIENT_NOTICES";

//home personal

export const FETCH_PERSONAL_POLICY_DECS_STARTED =
  "FETCH_PERSONAL_POLICY_DECS_STARTED";
export const FETCH_PERSONAL_POLICY_DECS_SUCCEEDED =
  "FETCH_PERSONAL_POLICY_DECS_SUCCEEDED";
export const FETCH_PERSONAL_POLICY_DECS_FAILED =
  "FETCH_PERSONAL_POLICY_DECS_FAILED";

export const FETCH_PERSONAL_CLIENT_NOTICES_STARTED =
  "FETCH_PERSONAL_CLIENT_NOTICES_STARTED";
export const FETCH_PERSONAL_CLIENT_NOTICES_SUCCEEDED =
  "FETCH_PERSONAL_CLIENT_NOTICES_SUCCEEDED";
export const FETCH_PERSONAL_CLIENT_NOTICES_FAILED =
  "FETCH_PERSONAL_CLIENT_NOTICES_FAILED";
export const DELETE_PERSONAL_CLIENT_NOTICES_SUCCEEDED =
  "DELETE_PERSONAL_CLIENT_NOTICES_SUCCEEDED";
export const DELETE_PERSONAL_CLIENT_NOTICES_FAILED =
  "DELETE_PERSONAL_CLIENT_NOTICES_FAILED";
export const RESET_PERSONAL_CLIENT_NOTICES = "RESET_PERSONAL_CLIENT_NOTICES";

//Home Claims
//Home Claims Statuses
export const FETCH_CLAIMS_STATUSES_STARTED = "FETCH_CLAIMS_STATUSES_STARTED";
export const FETCH_CLAIMS_STATUSES_SUCCEEDED =
  "FETCH_CLAIMS_STATUSES_SUCCEEDED";
export const FETCH_CLAIMS_STATUSES_FAILED = "FETCH_CLAIMS_STATUSES_FAILED";
export const REMOVE_CLAIMS_STATUS_FAILED = "REMOVE_CLAIMS_STATUS_FAILED";
//Home claims opened
export const FETCH_CLAIMS_OPENED_STARTED = "FETCH_CLAIMS_OPENED_STARTED";
export const FETCH_CLAIMS_OPENED_SUCCEEDED = "FETCH_CLAIMS_OPENED_SUCCEEDED";
export const FETCH_CLAIMS_OPENED_FAILED = "FETCH_CLAIMS_OPENED_FAILED";
export const REMOVE_CLAIM_OPENED_FAILED = "REMOVE_CLAIM_OPENED_FAILED";
//Home claim payments
export const FETCH_CLAIM_PAYMENTS_STARTED = "FETCH_CLAIM_PAYMENTS_STARTED";
export const FETCH_CLAIM_PAYMENTS_SUCCEEDED = "FETCH_CLAIM_PAYMENTS_SUCCEEDED";
export const FETCH_CLAIM_PAYMENTS_FAILED = "FETCH_CLAIM_PAYMENTS_FAILED";
export const REMOVE_CLAIM_PAYMENT_FAILED = "REMOVE_CLAIM_PAYMENT_FAILED";

//home billing
//billing past due
export const FETCH_BILLING_PAST_DUE_STARTED = "FETCH_BILLING_PAST_DUE_STARTED";
export const FETCH_BILLING_PAST_DUE_SUCCEEDED =
  "FETCH_BILLING_PAST_DUE_SUCCEEDED";
export const FETCH_BILLING_PAST_DUE_FAILED = "FETCH_BILLING_PAST_DUE_FAILED";
//billing due soon
export const FETCH_BILLING_DUE_SOON_STARTED = "FETCH_BILLING_DUE_SOON_STARTED";
export const FETCH_BILLING_DUE_SOON_SUCCEEDED =
  "FETCH_BILLING_DUE_SOON_SUCCEEDED";
export const FETCH_BILLING_DUE_SOON_FAILED = "FETCH_BILLING_DUE_SOON_FAILED";

//home service center

export const FETCH_CERTIFICATE_HISTORY_STARTED =
  "FETCH_CERTIFICATE_HISTORY_STARTED";
export const FETCH_CERTIFICATE_HISTORY_SUCCEEDED =
  "FETCH_CERTIFICATE_HISTORY_SUCCEEDED";
export const FETCH_CERTIFICATE_HISTORY_FAILED =
  "FETCH_CERTIFICATE_HISTORY_FAILED";
//moratorium

//morotorium listings alert
export const FETCH_MORATORIUM_LISTINGS_STARTED =
  "FETCH_MORATORIUM_LISTINGS_STARTED";
export const FETCH_MORATORIUM_LISTINGS_SUCCEEDED =
  "FETCH_MORATORIUM_LISTINGS_SUCCEEDED";
export const FETCH_MORATORIUM_LISTINGS_FAILED =
  "FETCH_MORATORIUM_LISTINGS_FAILED";
//moratorium Details
export const FETCH_MORATORIUM_DETAILS_STARTED =
  "FETCH_MORATORIUM_DETAILS_STARTED";
export const FETCH_MORATORIUM_DETAILS_SUCCEEDED =
  "FETCH_MORATORIUM_DETAILS_SUCCEEDED";
export const FETCH_MORATORIUM_DETAILS_FAILED =
  "FETCH_MORATORIUM_DETAILS_FAILED";

//claims saved losses list

export const FETCH_CLAIMS_SAVED_LOSSES_LIST_STARTED =
  "FETCH_CLAIMS_SAVED_LOSSES_LIST_STARTED";
export const FETCH_CLAIMS_SAVED_LOSSES_LIST_SUCCEEDED =
  "FETCH_CLAIMS_SAVED_LOSSES_LIST_SUCCEEDED";
export const FETCH_CLAIMS_SAVED_LOSSES_LIST_FAILED =
  "FETCH_CLAIMS_SAVED_LOSSES_LIST_FAILED";

export const DELETE_CLAIMS_SAVED_LOSSES_LIST_SUCCEEDED =
  "DELETE_CLAIMS_SAVED_LOSSES_LIST_SUCCEEDED";
export const DELETE_CLAIMS_SAVED_LOSSES_LIST_FAILED =
  "DELETE_CLAIMS_SAVED_LOSSES_LIST_FAILED";

//claims submitted losses

export const FETCH_CLAIMS_SUBMITTED_LOSSES_LIST_STARTED =
  "FETCH_CLAIMS_SUBMITTED_LOSSES_LIST_STARTED";
export const FETCH_CLAIMS_SUBMITTED_LOSSES_LIST_SUCCEEDED =
  "FETCH_CLAIMS_SUBMITTED_LOSSES_LIST_SUCCEEDED";
export const FETCH_CLAIMS_SUBMITTED_LOSSES_LIST_FAILED =
  "FETCH_CLAIMS_SUBMITTED_LOSSES_LIST_FAILED";

export const BILLING_SAME_AS_MAILING_CHANGE = "BILLING_SAME_AS_MAILING_CHANGE";
export const CANCEL_QUOTE = "CANCEL_QUOTE";
export const CREATE_OR_UPDATE_CUSTOMER_FAILED =
  "CREATE_OR_UPDATE_CUSTOMER_FAILED";
export const CREATE_OR_UPDATE_CUSTOMER_STARTED =
  "CREATE_OR_UPDATE_CUSTOMER_STARTED";
export const CREATE_OR_UPDATE_CUSTOMER_SUCCEEDED =
  "CREATE_OR_UPDATE_CUSTOMER_SUCCEEDED";
export const CREATE_SUBMISSION_FAILED = "CREATE_SUBMISSION_FAILED";
export const CREATE_SUBMISSION_STARTED = "CREATE_SUBMISSION_STARTED";
export const CREATE_SUBMISSION_SUCCEEDED = "CREATE_SUBMISSION_SUCCEEDED";
export const DISMISS_CUSTOMER_AND_ADDRESS_VALIDATION_ERROR =
  "DISMISS_CUSTOMER_AND_ADDRESS_VALIDATION_ERROR";
export const DISMISS_PILOT_AGENTS_ERROR = "DISMISS_PILOT_AGENTS_ERROR";
export const DISMISS_QUOTE_DETAILS_ERROR = "DISMISS_QUOTE_DETAILS_ERROR";
export const FETCH_PILOT_ACCOUNT_STARTED = "FETCH_PILOT_ACCOUNT_STARTED";
export const FETCH_PILOT_ACCOUNT_SUCCEEDED = "FETCH_PILOT_ACCOUNT_SUCCEEDED";
export const FETCH_PILOT_ACCOUNT_FAILED = "FETCH_PILOT_ACCOUNT_FAILED";
export const DEFAULT_PILOT_FORM_FROM_ACCOUNT =
  "DEFAULT_PILOT_FORM_FROM_ACCOUNT";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const EXISTING_CUSTOMER_SELECTED = "EXISTING_CUSTOMER_SELECTED";
export const FETCH_IS_ADVERSE_RISK_STARTED = "FETCH_IS_ADVERSE_RISK_STARTED";
export const FETCH_IS_ADVERSE_RISK_SUCCEEDED =
  "FETCH_IS_ADVERSE_RISK_SUCCEEDED";
export const FETCH_IS_ADVERSE_RISK_FAILED = "FETCH_IS_ADVERSE_RISK_FAILED";
export const FETCH_EXISTING_CUSTOMERS_FAILED =
  "FETCH_EXISTING_CUSTOMERS_FAILED";
export const FETCH_EXISTING_CUSTOMERS_STARTED =
  "FETCH_EXISTING_CUSTOMERS_STARTED";
export const FETCH_EXISTING_CUSTOMERS_SUCCEEDED =
  "FETCH_EXISTING_CUSTOMERS_SUCCEEDED";
export const FETCH_PRIMARY_RATE_STATES_FAILED =
  "FETCH_PRIMARY_RATE_STATES_FAILED";
export const FETCH_PRIMARY_RATE_STATES_STARTED =
  "FETCH_PRIMARY_RATE_STATES_STARTED";
export const FETCH_PRIMARY_RATE_STATES_SUCCEEDED =
  "FETCH_PRIMARY_RATE_STATES_SUCCEEDED";
export const FETCH_PRODUCERS_FAILED = "FETCH_PRODUCERS_FAILED";
export const FETCH_PRODUCERS_STARTED = "FETCH_PRODUCERS_STARTED";
export const FETCH_PRODUCERS_SUCCEEDED = "FETCH_PRODUCERS_SUCCEEDED";
export const FINISH_QUOTE = "FINISH_QUOTE";
export const GO_TO_NEXT_STEP = "GO_TO_NEXT_STEP";
export const GO_TO_PREVIOUS_STEP = "GO_TO_PREVIOUS_STEP";
export const LOOKUP_LOCATION_BY_ZIP_CODE_FAILED =
  "LOOKUP_LOCATION_BY_ZIP_CODE_FAILED";
export const LOOKUP_LOCATION_BY_ZIP_CODE_STARTED =
  "LOOKUP_LOCATION_BY_ZIP_CODE_STARTED";
export const LOOKUP_LOCATION_BY_ZIP_CODE_SUCCEEDED =
  "LOOKUP_LOCATION_BY_ZIP_CODE_SUCCEEDED";
export const NEW_QUOTE_DISMISS_ERROR = "NEW_QUOTE_DISMISS_ERROR";
export const PILOT_CUSTOMER_TYPE_CHANGE = "PILOT_CUSTOMER_TYPE_CHANGE";
export const PILOT_LOOKUP_LOCATION_BY_ZIP_CODE_FAILED =
  "PILOT_LOOKUP_LOCATION_BY_ZIP_CODE_FAILED";
export const PILOT_LOOKUP_LOCATION_BY_ZIP_CODE_STARTED =
  "PILOT_LOOKUP_LOCATION_BY_ZIP_CODE_STARTED";
export const PILOT_LOOKUP_LOCATION_BY_ZIP_CODE_SUCCEEDED =
  "PILOT_LOOKUP_LOCATION_BY_ZIP_CODE_SUCCEEDED";
export const PILOT_QUOTE_TYPE_CHANGE = "PILOT_QUOTE_TYPE_CHANGE";
export const PILOT_SAVE_BILLING_ADDRESS = "PILOT_SAVE_BILLING_ADDRESS";
export const PILOT_SAVE_ENTERED_ADDRESS = "PILOT_SAVE_ENTERED_ADDRESS";
export const PILOT_UPDATE_TO_RECOMMENDED_ADDRESS =
  "PILOT_UPDATE_TO_RECOMMENDED_ADDRESS";
export const PILOT_VERIFY_ADDRESS_FAILED = "PILOT_VERIFY_ADDRESS_FAILED";
export const PILOT_VERIFY_ADDRESS_STARTED = "PILOT_VERIFY_ADDRESS_STARTED";
export const PILOT_VERIFY_ADDRESS_SUCCEEDED = "PILOT_VERIFY_ADDRESS_SUCCEEDED";
export const SAVE_ENTERED_ADDRESS = "SAVE_ENTERED_ADDRESS";
export const SAVE_MAILING_ADDRESS = "SAVE_MAILING_ADDRESS";
export const SAVE_STEP = "SAVE_STEP";
export const START_QUOTE = "START_QUOTE";
export const PILOT_UPDATE_FORM_FIELD = "PILOT_UPDATE_FORM_FIELD";
export const VERIFY_ADDRESS_FAILED = "VERIFY_ADDRESS_FAILED";
export const VERIFY_ADDRESS_STARTED = "VERIFY_ADDRESS_STARTED";
export const VERIFY_ADDRESS_SUCCEEDED = "VERIFY_ADDRESS_SUCCEEDED";

// your UFG Team
export const FETCH_UFG_DEPARTMENT_DATA_STARTED =
  "FETCH_UFG_DEPARTMENT_DATA_STARTED";
export const FETCH_UFG_DEPARTMENT_DATA_SUCCEEDED =
  "FETCH_UFG_DEPARTMENT_DATA_SUCCEEDED";
export const FETCH_UFG_DEPARTMENT_DATA_FAILED =
  "FETCH_UFG_DEPARTMENT_DATA_FAILED";

export const FETCH_CONTACT_DETAILS_STARTED = "FETCH_CONTACT_DETAILS_STARTED";
export const FETCH_CONTACT_DETAILS_SUCCEEDED =
  "FETCH_CONTACT_DETAILS_SUCCEEDED";
export const FETCH_CONTACT_DETAILS_FAILED = "FETCH_CONTACT_DETAILS_FAILED";

export const SET_YOUR_UFG_TEAM_PREVIOUS_DEPARTMENT =
  "SET_YOUR_UFG_TEAM_PREVIOUS_DEPARTMENT";

// Agency Sweep - Complete Payment
export const COPY_ACCOUNT_BILLING_ACTIVITY_SUCCEEDED =
  "COPY_ACCOUNT_BILLING_ACTIVITY_SUCCEEDED";
export const FETCH_OASIS_INVOICE_DETAIL_STARTED =
  "FETCH_OASIS_INVOICE_DETAIL_STARTED";
export const FETCH_OASIS_INVOICE_DETAIL_SUCCEEDED =
  "FETCH_OASIS_INVOICE_DETAIL_SUCCEEDED";
export const FETCH_OASIS_INVOICE_DETAIL_FAILED =
  "FETCH_OASIS_INVOICE_DETAIL_FAILED";
export const COPY_AGENCY_SWEEP_PAYMENT_REQUEST_DETAILS_SUCCEEDED =
  "COPY_AGENCY_SWEEP_PAYMENT_REQUEST_DETAILS_SUCCEEDED";
// Agency Sweep - Verify Payment
export const PROCESS_AGENCY_SWEEP_PAYMENT_STARTED =
  "PROCESS_AGENCY_SWEEP_PAYMENT_STARTED";
export const PROCESS_AGENCY_SWEEP_PAYMENT_SUCCEEDED =
  "PROCESS_AGENCY_SWEEP_PAYMENT_SUCCEEDED";
export const PROCESS_AGENCY_SWEEP_PAYMENT_FAILED =
  "PROCESS_AGENCY_SWEEP_PAYMENT_FAILED";
// Agency Sweep - Payment confirmation
export const CREATE_AGENCY_SWEEP_PAYMENT_CONFIRMATION_SUCCEEDED =
  "CREATE_AGENCY_SWEEP_PAYMENT_CONFIRMATION_SUCCEEDED";
export const CLEAR_AGENCY_SWEEP_PAYMENT_SUCCEEDED =
  "CLEAR_AGENCY_SWEEP_PAYMENT_SUCCEEDED";
export const SEND_OASIS_PAYMENT_CONFIRMATION_STARTED =
  "SEND_OASIS_PAYMENT_CONFIRMATION_STARTED";
export const SEND_OASIS_PAYMENT_CONFIRMATION_SUCCEEDED =
  "SEND_OASIS_PAYMENT_CONFIRMATION_SUCCEEDED";
export const SEND_OASIS_PAYMENT_CONFIRMATION_FAILED =
  "SEND_OASIS_PAYMENT_CONFIRMATION_FAILED";
export const AGENCY_SWEEP_PAYMENT_PDF_LOADING =
  "AGENCY_SWEEP_PAYMENT_PDF_LOADING";
export const AGENCY_SWEEP_PAYMENT_PDF_SUCCEEDED =
  "AGENCY_SWEEP_PAYMENT_PDF_SUCCEEDED";
export const AGENCY_SWEEP_PAYMENT_PDF_FAILED =
  "AGENCY_SWEEP_PAYMENT_PDF_FAILED";

//Agent Manuals
export const FETCH_STATES_LIST_STARTED = "FETCH_STATES_LIST_STARTED";
export const FETCH_STATES_LIST_SUCCEEDED = "FETCH_STATES_LIST_SUCCEEDED";
export const FETCH_STATES_LIST_FAILED = "FETCH_STATES_LIST_FAILED";
export const SET_SELECTED_STATE = "SET_SELECTED_STATE";

export const FETCH_STATE_PRODUCTS_STARTED = "FETCH_STATE_PRODUCTS_STARTED";
export const FETCH_STATE_PRODUCTS_SUCCEEDED = "FETCH_STATE_PRODUCTS_SUCCEEDED";
export const FETCH_STATE_PRODUCTS_STARTED_FAILED =
  "FETCH_STATE_PRODUCTS_STARTED_FAILED";
export const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT";

export const FETCH_TABLE_OF_CONTENTS_STARTED =
  "FETCH_TABLE_OF_CONTENTS_STARTED";
export const FETCH_TABLE_OF_CONTENTS_SUCCEEDED =
  "FETCH_TABLE_OF_CONTENTS_SUCCEEDED";
export const FETCH_TABLE_OF_CONTENTS_FAILED = "FETCH_TABLE_OF_CONTENTS_FAILED";

export const FETCH_CONTENT_STARTED = "FETCH_CONTENT_STARTED";
export const FETCH_CONTENT_SUCCEEDED = "FETCH_CONTENT_SUCCEEDED";
export const FETCH_CONTENT_FAILED = "FETCH_CONTENT_FAILED";

export const FETCH_POLICY_DRAFT_STARTED = "FETCH_POLICY_DRAFT_STARTED";
export const FETCH_POLICY_DRAFT_SUCCEEDED = "FETCH_POLICY_DRAFT_SUCCEEDED";
export const FETCH_POLICY_DRAFT_FAILED = "FETCH_POLICY_DRAFT_FAILED";

export const CREATE_POLICY_DRAFT_STARTED = "CREATE_POLICY_DRAFT_STARTED";
export const CREATE_POLICY_DRAFT_SUCCEEDED = "CREATE_POLICY_DRAFT_SUCCEEDED";
export const CREATE_POLICY_DRAFT_FAILED = "CREATE_POLICY_DRAFT_FAILED";

export const PUT_POLICY_CHANGE_STARTED = "PUT_POLICY_CHANGE_STARTED";
export const PUT_POLICY_CHANGE_SUCCEEDED = "PUT_POLICY_CHANGE_SUCCEEDED";
export const PUT_POLICY_CHANGE_FAILED = "PUT_POLICY_CHANGE_FAILED";
