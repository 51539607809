import React from "react";
import PropTypes from "prop-types";
import ReactIframeResizer from "react-iframe-resizer-super";

const appendRehydrateQueryParam = url => {
  const [baseUrl, originalQueryParams] = url.split("?");
  const queryParams = originalQueryParams ? `&${originalQueryParams}` : "";

  // Need rehydrateSession included before any other parts
  // of query params due to possible '#' routing.
  return `${baseUrl}?rehydrateSession=Y${queryParams}`;
};

const defaultIframeResizerOptions = {
  // Didn't change heightCalculationMethod to 'max' for IE11.
  // It would sometimes result in an y-scrollbar when changing departments
  // in 'Your UFG Team' until you clicked the screen again.
  heightCalculationMethod: "lowestElement",

  // This is the existing default, but being explicit that we didn't end up
  // needing 'sizeWidth' being set to true for IE11.
  // Was able to remove some hard-coded widths and set 'box-sizing: border-box'
  // in the legacy master page css which help prevent descendants from
  // overflowing their parent elements.
  sizeWidth: false,
  warningTimeout: 20000
};

const Iframe = ({ url, className }) => {
  const rehydratedUrl = appendRehydrateQueryParam(url);
  return (
    <ReactIframeResizer
      iframeResizerOptions={defaultIframeResizerOptions}
      interval={200}
      src={rehydratedUrl}
      className={className}
    />
  );
};

Iframe.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Iframe;
