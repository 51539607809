import moment from "moment";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { logger } from "../../../loggers";
import * as agentService from "../../../services/agentService";
import * as addressService from "../../../services/addressService";
import * as commercialService from "../../../services/commercialService";
import * as types from "../../../constants/actionTypes";
import { PILOT_STATES } from "../constants";

const PERSONAL_LINES_QUOTE_URL = `${window.env.REACT_APP_LEGACY_SITE_URL}/AgentsOnly/ufgAgent/PersLines/qq/accountdisplay.asp`;

const logError = fatal => error => {
  const description = typeof error === "string" ? error : JSON.stringify(error);
  logger.error({ description, fatal });
};

const logNonFatalError = logError(false);

export const convertFormToCustomer = state => {
  const existingCustomer = state.newQuote.existingCustomer;
  const customerForm = state.newQuote.steps.customer.form;
  const mailingAddress = state.newQuote.mailingAddress;

  const existingCustomerForm = {
    id: existingCustomer ? existingCustomer.id : "",
    policy_center_account_id: existingCustomer
      ? existingCustomer.policy_center_account_id
      : "",
    business_address: {
      id:
        existingCustomer && existingCustomer.business_address
          ? existingCustomer.business_address.id
          : ""
    }
  };

  return {
    ...existingCustomerForm,
    agency_management_system_id: customerForm.customerId,
    name: customerForm.companyName,
    business_address: {
      ...existingCustomerForm.business_address,
      ...mailingAddress
    }
  };
};

/*
 * TODO:
 * We should look into storing errors in a common value in redux state.
 * With that property being a `Map<String, Object>` which stores action object by
 * their `type` property. This would give us a common way to add errors and allow
 * dismissing by removing from `Map`.
 */
export const dismissCustomerAndAddressValidationError = () => ({
  type: types.DISMISS_CUSTOMER_AND_ADDRESS_VALIDATION_ERROR
});

export const dismissQuoteDetailsError = () => ({
  type: types.DISMISS_QUOTE_DETAILS_ERROR
});

export const dismissPilotAgentsError = () => ({
  type: types.DISMISS_PILOT_AGENTS_ERROR
});

const saveStep = (step, form) => ({
  type: types.SAVE_STEP,
  payload: {
    step,
    form
  }
});

const formatAddressResponse = addressResponse => {
  const x = addressResponse[0].address_output;
  return {
    address_line: x.address_line,
    city: x.city,
    state: x.state,
    zip: x.zip,
    latitude: x.latitude,
    longitude: x.longitude
  };
};

const fetchPilotAccountStarted = () => ({
  type: types.FETCH_PILOT_ACCOUNT_STARTED
});

const fetchPilotAccountSucceeded = account => ({
  type: types.FETCH_PILOT_ACCOUNT_SUCCEEDED,
  payload: { account }
});

const fetchPilotAccountFailed = error => ({
  type: types.FETCH_PILOT_ACCOUNT_SUCCEEDED,
  error: true,
  payload: { error }
});

const fetchPilotAccount = (customerId, accountId) => dispatch => {
  dispatch(fetchPilotAccountStarted());
  return commercialService.getAccount(customerId, accountId).then(
    response => {
      dispatch(fetchPilotAccountSucceeded(response.data));
      return response.data;
    },
    error => {
      dispatch(fetchPilotAccountFailed(error));
    }
  );
};

export const editCustomer = customer => dispatch => {
  //startLegacyQuote("ufg_core", "Edit Customer")(dispatch, getState);

  dispatch({
    type: types.START_QUOTE,
    payload: {
      initialStep: "ufg_core",
      initiatedFrom: "Edit Customer"
    }
  });

  dispatch({
    type: types.EDIT_CUSTOMER,
    payload: {
      customer
    }
  });

  if (customer.policy_center_account_id) {
    fetchPilotAccount(customer.id, customer.policy_center_account_id)(dispatch);
  }
};

export const finishQuote = () => ({
  type: types.FINISH_QUOTE
});

export const cancelQuote = () => dispatch => {
  dispatch({
    type: types.CANCEL_QUOTE
  });

  // return a promise so we can run a function after the state is updated
  return Promise.resolve();
};

const goToNextStep = step => ({
  type: types.GO_TO_NEXT_STEP,
  payload: {
    step
  }
});

export const goToPreviousStep = () => ({
  type: types.GO_TO_PREVIOUS_STEP
});

export const saveLineOfBusinessStep =
  ({ form }) =>
  (dispatch, getState) => {
    const state = getState();
    const isPilotAgent = sessionSelector.isPilotAgent(state);
    if (form.lineOfBusiness === "personal_lines") {
      window.open(PERSONAL_LINES_QUOTE_URL);
      dispatch(finishQuote());
    } else {
      dispatch(saveStep("lineOfBusiness", form));
      const nextStep = isPilotAgent ? "oasis" : "ufg_core";
      dispatch(goToNextStep(nextStep));
    }
  };

const addressesAreEqual = (enteredAddress, suggestedAddress) => {
  return (
    enteredAddress.address_line.trim() === suggestedAddress.address_line &&
    enteredAddress.city === suggestedAddress.city &&
    enteredAddress.state === suggestedAddress.state &&
    enteredAddress.zip === suggestedAddress.zip
  );
};

export const requiresAddressValidationStep = (
  suggestedAddress,
  enteredAddress
) => !addressesAreEqual(enteredAddress, suggestedAddress);

export const combineZipAndAreaCodes = (zipCode, areaCode) =>
  zipCode + (areaCode ? `-${areaCode}` : "");

export const convertFormToAddress = ({ mailingAddress, city, state, zip }) => ({
  address_line: mailingAddress.toUpperCase(),
  city: city.toUpperCase(),
  state: state.toUpperCase(),
  zip
});

const appendGeolocation = (address, suggestedAddress) => ({
  ...address,
  geolocation: {
    latitude: Number.parseFloat(suggestedAddress.latitude),
    longitude: Number.parseFloat(suggestedAddress.longitude)
  }
});

const saveEnteredAddress = address => ({
  type: types.SAVE_ENTERED_ADDRESS,
  payload: {
    address
  }
});

export const saveCustomerStep = form => dispatch => {
  dispatch(saveStep("customer", form));

  const enteredAddress = convertFormToAddress(form);
  dispatch(saveMailingAddress(enteredAddress));

  dispatch(verifyAddress(enteredAddress)).then(suggestedAddress => {
    if (requiresAddressValidationStep(suggestedAddress, enteredAddress)) {
      dispatch(saveEnteredAddress(enteredAddress));
      dispatch(goToNextStep("addressValidation"));
    } else {
      const mailingAddress = appendGeolocation(
        enteredAddress,
        suggestedAddress
      );
      dispatch(saveMailingAddress(mailingAddress));
      dispatch(createOrUpdateCustomer());
    }
  }, logNonFatalError);
};

const createOrUpdateCustomerStarted = () => ({
  type: types.CREATE_OR_UPDATE_CUSTOMER_STARTED
});

const createOrUpdateCustomerSucceeded = data => ({
  type: types.CREATE_OR_UPDATE_CUSTOMER_SUCCEEDED,
  payload: { data }
});

const createOrUpdateCustomerFailed = (error, isAdverseRisk) => ({
  type: types.CREATE_OR_UPDATE_CUSTOMER_FAILED,
  error: true,
  payload: { error, isAdverseRisk }
});

/** Save new or updated customer with api */
const createOrUpdateCustomer = () => (dispatch, getState) => {
  const state = getState();
  // TODO - Chris: Determine if new quote should use selected agency code
  const agencyCode = sessionSelector.getActiveAgencyCode(state);
  const agencyNumber = sessionSelector.getAgentCode(state);
  // Really only applicable if selected existing from dropdown or intiated from 'Edit Customer'
  const existingCustomer = state.newQuote.existingCustomer;
  const apiForm = convertFormToCustomer(state);

  dispatch(createOrUpdateCustomerStarted());

  const createOrUpdateCustomerPromise = !existingCustomer
    ? commercialService.createCustomer(agencyCode, agencyNumber, apiForm)
    : commercialService.updateCustomer(
        existingCustomer.id,
        agencyCode,
        agencyNumber,
        apiForm
      );

  const billingAddress = state.newQuote.steps.customer.isPilotCustomer
    ? Object.assign(
        {},
        state.newQuote.steps.customer.form.billingSameAsMailing
          ? state.newQuote.mailingAddress
          : {
              address1: state.newQuote.steps.customer.form.billingAddress,
              city: state.newQuote.steps.customer.form.billingCity,
              state: state.newQuote.steps.customer.form.billingState,
              zip: state.newQuote.steps.customer.form.billingZip
            },
        {
          public_id:
            state.newQuote.steps.customer.account.additional_addresses.find(
              a => a.address_type === "billing"
            ).public_id,
          address_type: "billing",
          country: "US"
        }
      )
    : undefined;
  const account = state.newQuote.steps.customer.isPilotCustomer
    ? {
        customer_id: existingCustomer.id,
        account_number: state.newQuote.steps.customer.account.account_number,
        email_address: state.newQuote.steps.customer.account.email_address,
        firstname: state.newQuote.steps.customer.form.firstName,
        lastname: state.newQuote.steps.customer.form.lastName,
        contact_name: state.newQuote.steps.customer.form.companyName,
        subtype: state.newQuote.steps.customer.account.subtype,
        primary_address: {
          ...state.newQuote.mailingAddress,
          address_type: "physical_ufg",
          country: "US"
        },
        additional_addresses: [
          {
            ...state.newQuote.mailingAddress,
            public_id:
              state.newQuote.steps.customer.account.additional_addresses.find(
                a => a.address_type === "mailing_ufg"
              ).public_id,
            address_type: "mailing_ufg",
            country: "US"
          },
          billingAddress
        ]
      }
    : undefined;

  const updateAccountPromise = state.newQuote.steps.customer.isPilotCustomer
    ? commercialService.updateAccount(existingCustomer.id, account)
    : // Empty "successful" promise because we don't need to do additional updates for non-pilot
      Promise.resolve();

  Promise.all([createOrUpdateCustomerPromise, updateAccountPromise]).then(
    responses => {
      const customerResponse = responses[0];
      dispatch(createOrUpdateCustomerSucceeded(customerResponse.data));

      const isCustomerEdit = state.newQuote.initiatedFrom === "Edit Customer";
      if (isCustomerEdit) {
        dispatch(cancelQuote());
      } else {
        dispatch(existingCustomerSelected(customerResponse.data));
        dispatch(goToNextStep("quoteDetails"));
      }
    },
    error => {
      const ADVERSE_RISK_ERROR_MESSAGE = "adverse risk: business unacceptable";
      const isAdverseRisk =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message === ADVERSE_RISK_ERROR_MESSAGE;
      const message = "Unable to save customer details.";
      dispatch(createOrUpdateCustomerFailed(message, isAdverseRisk));
      logNonFatalError(message);
    }
  );
};

// TODO - Chris: Verify this can remove dispatch and return action object to container
export const existingCustomerSelected = customer => dispatch => {
  dispatch({
    type: types.EXISTING_CUSTOMER_SELECTED,
    payload: {
      customer,
      error: null
    }
  });
};

const fetchExistingCustomersStarted = () => ({
  type: types.FETCH_EXISTING_CUSTOMERS_STARTED
});

const fetchExistingCustomersSucceeded = data => ({
  type: types.FETCH_EXISTING_CUSTOMERS_SUCCEEDED,
  payload: data
});

const fetchExistingCustomersFailed = error => ({
  type: types.FETCH_EXISTING_CUSTOMERS_FAILED,
  payload: error
});

export const fetchExistingCustomers = agencyCode => dispatch => {
  dispatch(fetchExistingCustomersStarted());

  return commercialService.getCustomers(agencyCode).then(
    response => {
      dispatch(fetchExistingCustomersSucceeded(response.data));
    },
    error => {
      dispatch(fetchExistingCustomersFailed(error.response));
    }
  );
};

const verifyAddressStarted = () => ({
  type: types.VERIFY_ADDRESS_STARTED
});

const verifyAddressSucceeded = suggestedAddress => ({
  type: types.VERIFY_ADDRESS_SUCCEEDED,
  payload: {
    suggestedAddress
  }
});

const verifyAddressFailed = error => ({
  type: types.VERIFY_ADDRESS_FAILED,
  payload: {
    error
  }
});

const verifyAddress =
  ({ address_line, city, state, zip }) =>
  dispatch => {
    dispatch(verifyAddressStarted());

    return addressService.verifyAddress(address_line, city, state, zip).then(
      response => {
        const address = formatAddressResponse(response.data);
        dispatch(verifyAddressSucceeded(address));
        return address;
      },
      () => {
        const message = "Unable to verify address.";
        dispatch(verifyAddressFailed(message));
        return Promise.reject(message);
      }
    );
  };

const lookupLocationByZipCodeStarted = () => ({
  type: types.LOOKUP_LOCATION_BY_ZIP_CODE_STARTED
});

const lookupLocationByZipCodeSucceeded = (
  addressType,
  zipCode,
  suggestions
) => ({
  type: types.LOOKUP_LOCATION_BY_ZIP_CODE_SUCCEEDED,
  payload: {
    addressType,
    zipCode,
    suggestions
  }
});

const lookupLocationByZipCodeFailed = (addressType, error) => ({
  type: types.LOOKUP_LOCATION_BY_ZIP_CODE_FAILED,
  payload: {
    addressType,
    error
  }
});

export const lookupLocationByZipCode = (addressType, zipCode) => dispatch => {
  dispatch(lookupLocationByZipCodeStarted());

  return addressService.searchLocaleByZipCode(zipCode).then(
    response => {
      dispatch(
        lookupLocationByZipCodeSucceeded(addressType, zipCode, response.data)
      );
    },
    () => {
      const message = "Unable to retrieve location for zip code entered.";
      dispatch(lookupLocationByZipCodeFailed(addressType, message));
      logNonFatalError(`${message} Zip code: ${zipCode}`);
    }
  );
};

export const saveMailingAddress = address => ({
  type: types.SAVE_MAILING_ADDRESS,
  payload: {
    address
  }
});

export const saveAddressValidationStep = address => dispatch => {
  dispatch(saveMailingAddress(address));
  dispatch(createOrUpdateCustomer());
};

const createSubmissionStarted = () => ({
  type: types.CREATE_SUBMISSION_STARTED
});

const createSubmissionSucceeded = submission => ({
  type: types.CREATE_SUBMISSION_SUCCEEDED,
  payload: {
    submission
  }
});

const createSubmissionFailed = error => ({
  type: types.CREATE_SUBMISSION_FAILED,
  payload: {
    error
  }
});

export const requiresPilotAgentStep = (isPilotAgent, primaryRateState) =>
  isPilotAgent && PILOT_STATES.includes(primaryRateState);

export const saveQuoteDetailsStep = form => (dispatch, getState) => {
  dispatch(saveStep("quoteDetails", form));

  const state = getState();
  const isPilotAgent = sessionSelector.isPilotAgent(state);
  const primaryRateStates = state.newQuote.steps.quoteDetails.primaryRateStates;
  const primaryRateState = primaryRateStates[form.primaryRateState];

  if (requiresPilotAgentStep(isPilotAgent, primaryRateState)) {
    dispatch(goToNextStep("pilotAgents"));
  } else {
    dispatch(saveStandardQuote());
  }
};

const saveStandardQuote = () => (dispatch, getState) => {
  const state = getState();
  const agentNumber = sessionSelector.getAgentCode(state);
  const customerDetails = state.newQuote.steps.customer.customerDetails;
  const quoteDetails = state.newQuote.steps.quoteDetails;

  const quote = {
    core_system: "ufg_core",
    customer_id: customerDetails.id,
    country: "US",
    entry_person_code: agentNumber,
    effective_date: moment(quoteDetails.form.effectiveDate).format(
      "YYYY-MM-DD"
    ),
    producer_number: quoteDetails.form.producer,
    primary_rate_state:
      quoteDetails.primaryRateStates[quoteDetails.form.primaryRateState]
  };

  dispatch(createSubmissionStarted());

  return commercialService.createSubmission(quote).then(
    response => {
      const submission = response.data;
      dispatch(createSubmissionSucceeded(submission));
      window.open(
        `${window.env.REACT_APP_NEW_BUSINESS_SUBMISSION_EDIT_BASE_URL}/${submission.id}`
      );
      dispatch(finishQuote());
    },
    () => {
      const message = "Unable to save quote.";
      dispatch(createSubmissionFailed(message));
      logNonFatalError(message);
    }
  );
};

const fetchPrimaryRateStatesStarted = () => ({
  type: types.FETCH_PRIMARY_RATE_STATES_STARTED
});

const fetchPrimaryRateStatesSucceeded = primaryRateStates => ({
  type: types.FETCH_PRIMARY_RATE_STATES_SUCCEEDED,
  payload: { primaryRateStates }
});

const fetchPrimaryRateStatesFailed = error => ({
  type: types.FETCH_PRIMARY_RATE_STATES_FAILED,
  payload: { error }
});

export const fetchPrimaryRateStates = agencyCode => dispatch => {
  dispatch(fetchPrimaryRateStatesStarted());

  agentService.getLicensedStates(agencyCode).then(
    response => {
      dispatch(fetchPrimaryRateStatesSucceeded(response.data));
    },
    () => {
      const message = "Unable to retrieve primary rate states.";
      dispatch(fetchPrimaryRateStatesFailed(message));
      logNonFatalError(message);
    }
  );
};

const fetchProducersStarted = () => ({
  type: types.FETCH_PRODUCERS_STARTED
});

const fetchProducersSucceeded = (producers, producerNumber) => ({
  type: types.FETCH_PRODUCERS_SUCCEEDED,
  payload: { producers, producerNumber }
});

const fetchProducersFailed = error => ({
  type: types.FETCH_PRODUCERS_FAILED,
  payload: { error }
});

// Would prefer to not need producerNumber in order for reducer to set producer value
// but calculating in QuoteDetails render didn't appear to work correctly when
// navigating to previous step and back to Quote Details.
export const fetchProducers = (agencyCode, producerNumber) => dispatch => {
  dispatch(fetchProducersStarted());

  agentService.getProducers(agencyCode).then(
    response => {
      dispatch(fetchProducersSucceeded(response.data, producerNumber));
    },
    () => {
      const message = "Unable to retrieve producers.";
      dispatch(fetchProducersFailed(message));
      logNonFatalError(message);
    }
  );
};

export const billingSameAsMailingChange = checked => ({
  type: types.BILLING_SAME_AS_MAILING_CHANGE,
  payload: {
    checked
  }
});

export const defaultPilotFormFromAccount = (
  account,
  mailingAddress,
  billingAddress,
  billingSameAsMailing
) => ({
  type: types.DEFAULT_PILOT_FORM_FROM_ACCOUNT,
  payload: {
    account,
    mailingAddress,
    billingAddress,
    billingSameAsMailing
  }
});

export const pilotQuoteTypeChange = quoteType => (dispatch, getState) => {
  const quoteTypeChange = {
    type: types.PILOT_QUOTE_TYPE_CHANGE,
    payload: {
      quoteType
    }
  };
  const customer = getState().newQuote.steps.customer.customerDetails;
  if (quoteType !== "standard_quote" && customer.policy_center_account_id) {
    dispatch(
      fetchPilotAccount(customer.id, customer.policy_center_account_id)
    ).then(account => {
      const getAddressOrDefault = lambda => {
        const address = account.additional_addresses.find(lambda);
        return address
          ? { ...address, address_line: address.address1 }
          : { address_line: "", city: "", state: "", zip: "" };
      };
      const billingAddress = getAddressOrDefault(
        _ => _.address_type === "billing"
      );
      const mailingAddress = getAddressOrDefault(
        _ => _.address_type === "mailing_ufg"
      );
      const billingSameAsMailing = addressesAreEqual(
        billingAddress,
        mailingAddress
      );
      dispatch(
        defaultPilotFormFromAccount(
          account,
          mailingAddress,
          billingAddress,
          billingSameAsMailing
        )
      );
      dispatch(quoteTypeChange);
    }, logNonFatalError);
  } else {
    dispatch(quoteTypeChange);
  }
};

export const pilotCustomerTypeChange = customerType => ({
  type: types.PILOT_CUSTOMER_TYPE_CHANGE,
  payload: {
    customerType
  }
});

export const updatePilotFormField = field => ({
  type: types.PILOT_UPDATE_FORM_FIELD,
  payload: {
    field
  }
});

export const savePilotAgentsStep = form => (dispatch, getState) => {
  if (form.quoteType === "standard_quote") {
    dispatch(saveStandardQuote());
    return;
  }

  const billingAddress = !form.billingSameAsMailing
    ? {
        address_line: form.billingAddress,
        city: form.billingCity,
        state: form.billingState,
        zip: form.billingZip
      }
    : { ...getState().newQuote.mailingAddress };

  dispatch(savePilotBillingAddress(billingAddress));

  if (form.billingSameAsMailing) {
    dispatch(savePilotQuote());
    return;
  }

  dispatch(verifyPilotBillingAddress(billingAddress)).then(suggestedAddress => {
    if (requiresAddressValidationStep(suggestedAddress, billingAddress)) {
      dispatch(savePilotEnteredAddress(billingAddress));
      dispatch(goToNextStep("pilotAddressValidation"));
    } else {
      dispatch(
        savePilotBillingAddress(
          appendGeolocation(billingAddress, suggestedAddress)
        )
      );
      dispatch(savePilotQuote());
    }
  }, logNonFatalError);
};

const savePilotEnteredAddress = address => ({
  type: types.PILOT_SAVE_ENTERED_ADDRESS,
  payload: {
    address
  }
});

const pilotLookupLocationByZipCodeStarted = () => ({
  type: types.PILOT_LOOKUP_LOCATION_BY_ZIP_CODE_STARTED
});

const pilotLookupLocationByZipCodeSucceeded = (zipCode, suggestions) => ({
  type: types.PILOT_LOOKUP_LOCATION_BY_ZIP_CODE_SUCCEEDED,
  payload: {
    zipCode,
    suggestions
  }
});

const pilotLookupLocationByZipCodeFailed = error => ({
  type: types.PILOT_LOOKUP_LOCATION_BY_ZIP_CODE_FAILED,
  payload: {
    error
  }
});

// TODO: Figure out how to eliminate this duplication
export const pilotLookupLocationByZipCode = zipCode => dispatch => {
  dispatch(pilotLookupLocationByZipCodeStarted());

  return addressService.searchLocaleByZipCode(zipCode).then(
    response => {
      dispatch(pilotLookupLocationByZipCodeSucceeded(zipCode, response.data));
    },
    () => {
      const message = "Unable to retrieve location for zip code.";
      dispatch(pilotLookupLocationByZipCodeFailed(message));
      logNonFatalError(`${message} Zip code: ${zipCode}`);
    }
  );
};

const savePilotBillingAddress = address => ({
  type: types.PILOT_SAVE_BILLING_ADDRESS,
  payload: {
    address
  }
});

const verifyPilotAddressStarted = () => ({
  type: types.PILOT_VERIFY_ADDRESS_STARTED
});

const verifyPilotAddressSucceeded = suggestedAddress => ({
  type: types.PILOT_VERIFY_ADDRESS_SUCCEEDED,
  payload: {
    suggestedAddress
  }
});

const verifyPilotAddressFailed = error => ({
  type: types.PILOT_VERIFY_ADDRESS_FAILED,
  payload: {
    error
  }
});

const verifyPilotBillingAddress =
  ({ address_line, city, state, zip }) =>
  dispatch => {
    dispatch(verifyPilotAddressStarted());

    return addressService.verifyAddress(address_line, city, state, zip).then(
      response => {
        const address = formatAddressResponse(response.data);
        dispatch(verifyPilotAddressSucceeded(address));
        return address;
      },
      () => {
        const message = "Unable to verify billing address.";
        dispatch(verifyPilotAddressFailed(message));
        return Promise.reject(message);
      }
    );
  };

export const savePilotAddressValidationStep = address => dispatch => {
  dispatch(savePilotBillingAddress(address));
  dispatch(savePilotQuote());
};

const getPilotAccountFromState = state => {
  const customerStep = state.newQuote.steps.customer;
  const pilotAgentsStep = state.newQuote.steps.pilotAgents;
  const primaryAddress = {
    address_type: "physical_ufg",
    country: "US",
    ...state.newQuote.mailingAddress
  };
  const mailingAddress = {
    address_type: "mailing_ufg",
    country: "US",
    ...state.newQuote.mailingAddress
  };
  const billingAddress = {
    address_type: "billing",
    country: "US",
    ...state.newQuote.billingAddress
  };

  const account = {
    guid: customerStep.customerDetails.id,
    customer_id: customerStep.customerDetails.agency_management_system_id,
    email_address: sessionSelector.getEmail(state),
    firstname: pilotAgentsStep.form.firstName
      ? pilotAgentsStep.form.firstName
      : undefined,
    lastname: pilotAgentsStep.form.lastName
      ? pilotAgentsStep.form.lastName
      : undefined,
    contact_name: pilotAgentsStep.form.companyName
      ? pilotAgentsStep.form.companyName
      : undefined,
    subtype: pilotAgentsStep.customerType,
    account_organization_type: pilotAgentsStep.form.legalEntity.value,
    primary_address: primaryAddress,
    additional_addresses: [mailingAddress, billingAddress]
  };
  return account;
};

const savePilotQuote = () => (dispatch, getState) => {
  let customerStep = getState().newQuote.steps.customer;
  // TODO - Chris: Determine if new quote should use selected agency code
  const agencyCode = sessionSelector.getActiveAgencyCode(getState());
  const agentNumber = sessionSelector.getAgentCode(getState());

  Promise.resolve(customerStep.customerDetails.policy_center_account_id)
    .then(policy_center_account_id => {
      if (!policy_center_account_id) {
        const account = getPilotAccountFromState(getState());
        return commercialService
          .createAccount(
            customerStep.customerDetails.id,
            account,
            agencyCode,
            agentNumber
          )
          .then(response => response.data.account_number);
      }
      return policy_center_account_id;
    })
    .then(policy_center_account_id => {
      const state = getState();
      const agentNumber = sessionSelector.getAgentCode(state);
      customerStep = state.newQuote.steps.customer;
      const quoteDetailsStep = state.newQuote.steps.quoteDetails;
      const producerNumber =
        agencyCode.padStart(6, "0") +
        quoteDetailsStep.form.producer.padStart(4, "0");
      const quote = {
        core_system: "oasis",
        country: "US",
        account_number: policy_center_account_id,
        customer_id: customerStep.customerDetails.id,
        entry_person_code: agentNumber,
        effective_date: moment(quoteDetailsStep.form.effectiveDate).format(
          "YYYY-MM-DD"
        ),
        producer_number: producerNumber,
        product_code: "Uni-Pak",
        primary_rate_state:
          quoteDetailsStep.primaryRateStates[
            quoteDetailsStep.form.primaryRateState
          ]
      };

      return commercialService.createSubmission(quote).then(response => {
        const submission = response.data;
        dispatch(createSubmissionSucceeded(submission));

        window.open(
          `${window.env.REACT_APP_POLICY_CENTER_QUOTE_BASE_URL}?JobNumber=${submission.submission_number}`
        );

        dispatch(finishQuote());
      });
    })
    .catch(() => {
      const message = "Unable to save quote.";
      dispatch(createSubmissionFailed(message));
      logNonFatalError(message);
    });
};

export const dismissError = () => ({
  type: types.NEW_QUOTE_DISMISS_ERROR
});
