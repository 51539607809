import * as types from "./umailActionTypes";
import * as umailService from "./umailService";

export const activateUmailCard = () => ({
  type: types.UMAIL_ACTIVATED
});

export const dismissUmail = dispatch => {
  dispatch({
    type: types.DISMISS_UMAIL
  });
};

export const postUmailMessageStarted = () => ({
  type: types.POST_UMAIL_MESSAGE_STARTED
});

export const postUmailMessageSucceeded = () => ({
  type: types.POST_UMAIL_MESSAGE_SUCCEEDED
});

export const postUmailMessageFailed = data => ({
  type: types.POST_UMAIL_MESSAGE_FAILED,
  payload: data
});

export const postUmailMessage = (form, attachments) => dispatch => {
  dispatch(postUmailMessageStarted());
  return umailService
    .postUmailMessage(form, attachments)
    .then(response => {
      dispatch(postUmailMessageSucceeded());
      return response;
    })
    .catch(error => {
      dispatch(postUmailMessageFailed(error.message));
      throw error;
    });
};
