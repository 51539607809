import React, { useState, useRef, useCallback, useContext } from "react";
import * as api from "../../../services/onlineQuotingService";
import { _get } from "../../shared/util";
import { Input, Table, LoadingIndicator } from "@ufginsurance/ui-kit";
import v4 from "uuid/v4";

import useOnClickOutside from "../../shared/useOnClickOutside";
import { getSizeClassByWeight } from "./getVehicleClassData";
import { quotePath } from "../../shared/constants";
import OQstrings from "../../shared/strings";
import OnlineQuotingContext from "../../OnlineQuotingContext";

const VinInputSelector = ({
  form,
  setSelectedVin,
  disableVinFields,
  setDisableVinFields
}) => {
  const { quoteData } = useContext(OnlineQuotingContext);

  const [showVinMenu, setVinShowMenu] = useState(false);
  const [vehiclesByVin, setVehiclesByVin] = useState([]);
  const [vinSearching, setVinSearching] = useState(false);

  const { handleOnBlur, handleOnChange, handleOnValidate, values } = form;

  const handleVinChange = ({ field, value, validation }) => {
    if (value.length === 17) {
      setVinSearching(true);
      api
        .getVehicleByVIN({ vin: value })
        .then(result => {
          const data = result?.data?.vehicle_details || [];
          const dataWithUniqueKey = data.map(d => ({ ...d, key: v4() }));
          setVehiclesByVin(dataWithUniqueKey);
          setVinShowMenu(true);
        })
        .finally(() => setVinSearching(false));
    } else {
      // if vin is empty or invalid, then remove the vehicle from state
      setVinShowMenu(false);
      setSelectedVin(null);
    }

    if (!value) {
      form.updateForm({
        values: { grossVehicleWeight: "", grossCombinationWeight: "", vin: "" },
        errors: { vin: [] }
      });
      setVehiclesByVin([]);
    } else handleOnChange({ field, value, validation });
  };

  // show vin table if user sets focus back to vin field (and there is vin data to display)
  const handleVinFocus = () => {
    if (vehiclesByVin && vehiclesByVin.length) setVinShowMenu(true);
  };

  // close vin table if user tabs out of field
  const handleVinKeydown = e => {
    if (e.event.keyCode === 9) setVinShowMenu(false);
  };

  const updateFieldsBasedOnVin = ({ row }) => {
    setSelectedVin({ ...row, vin: values.vin });
    form.updateForm({
      values: {
        year: String(row.year),
        make: row.make,
        model: row.model,
        originalCostNew: String(row.cost_new),
        grossVehicleWeight:
          form.values.vehicleType === "CA7Truck" && row?.gross_vehicle_weight
            ? String(row?.gross_vehicle_weight)
            : undefined,
        grossCombinationWeight:
          form.values.vehicleType === "CA7Truck" && row?.gross_combined_weight
            ? String(row?.gross_combined_weight)
            : undefined,
        sizeClass:
          form.values.vehicleType === "CA7Truck"
            ? getSizeClassByWeight(row?.gross_vehicle_weight)
            : form.values.sizeClass || ""
      },
      errors: {
        year: [],
        make: [],
        model: [],
        originalCostNew: [],
        grossVehicleWeight: [],
        grossCombinationWeight: [],
        sizeClass: []
      }
    });
    setVinShowMenu(!showVinMenu);

    setDisableVinFields({
      year: !!row.year,
      make: !!row.make,
      model: !!row.model,
      originalCostNew: !!row.cost_new
    });
  };

  const handleVinValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!value) return fieldErrors; //don't validate empty VIN

    const regex = /^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/;
    if (!regex.test(value)) {
      fieldErrors.push(OQstrings.error.vehicle_invalid_vin);
    }

    const existingVehicles = _get(quoteData, quotePath.ca7Vehicle, []);
    //OOQ-7049 skip current vehicle in existing list check.
    if (
      existingVehicles.some(
        v => values?.fixedId !== v.fixedId && v.vin === value
      )
    ) {
      fieldErrors.push("The VIN entered has already been added.");
    }

    return fieldErrors;
  };

  const vinMenucolumns = [
    {
      key: "",
      label: "Year Make Model",
      element: row => <div>{`${row.year}  ${row.make}  ${row.model}`}</div>
    },
    {
      key: "",
      label: "Cost New",
      element: row => <div>{row.cost_new}</div>
    },
    {
      key: "",
      label: "Gross Vehicle Weight",
      element: row => <div>{row.gross_vehicle_weight}</div>
    }
  ];

  const VinMenu = () => {
    const tableRef = useRef();

    useOnClickOutside(
      tableRef,
      useCallback(() => setVinShowMenu(false), [])
    );

    return (
      <div className="custom__table-select" ref={tableRef}>
        <Table
          rowKey="key"
          columns={vinMenucolumns}
          onRowClick={updateFieldsBasedOnVin}
          data={vehiclesByVin}
          showPagination={vehiclesByVin.length > 100}
          itemsPerPage={100}
          selectable
          nowrap
        />
      </div>
    );
  };

  return (
    <div className="custom__table-search">
      <Input
        placeholder=""
        id="vin"
        name="vin"
        label="VIN"
        labelElement={
          vinSearching ? (
            <LoadingIndicator
              className="oq__label-spinner"
              type="spinner"
              message="Searching"
            />
          ) : null
        }
        onChange={handleVinChange}
        onBlur={handleOnBlur}
        onValidate={handleVinValidate}
        onFocus={handleVinFocus}
        onKeyDown={handleVinKeydown}
        value={values.vin}
        disabled={disableVinFields.vin}
      />
      {showVinMenu && <VinMenu />}
      {form.errors.vin && (
        <ul className="uikit__errors__list">
          {form.errors.vin.map(e => (
            <li key={e} className="uikit__errors__item">
              {e}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default VinInputSelector;
