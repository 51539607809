import React, { useState } from "react";
import PropTypes from "prop-types";
import { Translations } from "../../../components/translations";
import moment from "moment";
import { DATE_FORMAT } from "../../../claims/services/claimsUtil";
import {
  Table,
  Icon,
  Alert,
  LoadingIndicator,
  Anchor,
  Modal
} from "@ufginsurance/ui-kit";
import * as routes from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { printPdf } from "../../../shared/documents/helpers";
import * as dictionary from "../../../constants/dictionary";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const ClaimStatusTable = ({
  claimsStatusesList,
  removeClaimStatus,
  agencyCode,
  claimsStatusesError,
  claimsStatusDeleteError,
  is109015Agent,
  claimsStatusesLoading
}) => {
  const history = useHistory();

  const [showAlert, setShowAlert] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [documentsLoading, setDocumentsLoading] = useState([]);

  const handleRemoveClaimStatus = document_din => () => {
    setShowAlert(true);
    removeClaimStatus(agencyCode, document_din);
  };

  const handleDismissAlert = () => {
    setShowAlert(false);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onPrintStart = claim_number => {
    const documentsLoadingWithCurrentSelection = !documentsLoading.includes(
      claim_number
    )
      ? [...documentsLoading, claim_number]
      : documentsLoading;

    setDocumentsLoading(documentsLoadingWithCurrentSelection);
  };

  const onPrintComplete = claim_number => {
    setDocumentsLoading(documentsLoading.filter(x => x !== claim_number));
  };

  const onPrintError = () => {
    setShowModal(true);
  };

  const ErrorModalBody = () => {
    return (
      <div className="">
        <HelpDeskMessage
          display="questionsOrAssistance"
          before="We're sorry, we could not locate the requested document."
        />
      </div>
    );
  };

  const columns = [
    { label: Translations.claims_tab.policy_holder, key: "insured_name" },
    {
      label: Translations.claims_tab.claim_number,
      key: "claim_number",
      element: row => {
        return (
          <Anchor
            onClick={() => {
              history.push(
                `${routes.CLAIM_INFORMATION}?claim_number=${row.claim_number}`
              );
            }}
          >
            {row.claim_number}
          </Anchor>
        );
      }
    },
    {
      label: Translations.claims_tab.policy_number,
      key: "policy_number",
      element: row => (
        <Anchor
          onClick={() => {
            history.push(`${routes.ACCOUNT_HOME}/${row.policy_number}`);
          }}
        >
          {row.policy_number}
        </Anchor>
      )
    },
    {
      label: Translations.claims_tab.notice,
      key: "claim_number",
      element: row => {
        const requestInProgress =
          row.claim_number && documentsLoading.includes(row.claim_number);

        const claim_number = `${row.claim_number ?? ""}`;

        return (
          <div className="claims-table-form-name__wrapper">
            {requestInProgress && (
              <span className="claims-table-spinner">
                <LoadingIndicator message="" type="spinner" />
              </span>
            )}
            <Anchor
              href={`${routes.DOCUMENT_LIBRARY_PRINT_FORM_ROOT}/${dictionary.DOCUMENT_FORM_TYPES.CLAIMS.AGAC}/${claim_number}`}
              className="text-capitalize form-number"
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => {
                e.preventDefault();

                printPdf({
                  form_number: claim_number,
                  form_type: dictionary.DOCUMENT_FORM_TYPES.CLAIMS.AGAC,
                  onStart: () => {
                    onPrintStart(claim_number);
                  },
                  onComplete: () => {
                    onPrintComplete(claim_number);
                  },
                  onError: onPrintError
                });
              }}
            >
              {row.document_type_description}
            </Anchor>
          </div>
        );
      }
    },
    {
      key: "",
      label: Translations.claims_tab.remove,
      align: "center",
      element: row => (
        <>
          {!is109015Agent && (
            <div
              className="icon"
              role="presentation"
              onClick={handleRemoveClaimStatus(row.document_din)}
            >
              <Icon icon="fasTimesCircle" />
            </div>
          )}
        </>
      )
    }
  ];

  const hasClaims = claimsStatusesList && claimsStatusesList.length > 0;
  return (
    <div>
      <div className="claim-status-heading">
        <b>{Translations.claims_tab.header_status}</b>
      </div>
      {!claimsStatusesLoading && (!hasClaims || claimsStatusesError) && (
        <div className="friendly-error-messaging">
          {Translations.claims_tab.claim_status_fail_error_message}
        </div>
      )}
      {claimsStatusDeleteError && showAlert && (
        <div className="row">
          <Alert type="error" onDismiss={handleDismissAlert}>
            {claimsStatusDeleteError}
          </Alert>
          &nbsp;
        </div>
      )}
      {claimsStatusesLoading ? (
        <LoadingIndicator />
      ) : (
        hasClaims && (
          <Table
            rowKey="document_din"
            groupHeaderKey="document_date"
            groupHeaderContents={row => (
              <>
                {Translations.claims_tab.date}{" "}
                {moment(row.document_date).format(DATE_FORMAT)}
              </>
            )}
            columns={columns}
            data={claimsStatusesList}
            showPagination
            itemsPerPage={20}
            className="claims-status-table"
          />
        )
      )}

      <Modal
        title="Document not found"
        show={showModal}
        onHide={onCloseModal}
        body={<ErrorModalBody />}
      />
    </div>
  );
};

ClaimStatusTable.propTypes = {
  claimsStatusesList: PropTypes.array,
  removeClaimStatus: PropTypes.func.isRequired,
  agencyCode: PropTypes.string,
  claimsStatusesError: PropTypes.string,
  claimsStatusDeleteError: PropTypes.string,
  is109015Agent: PropTypes.bool,
  claimsStatusesLoading: PropTypes.bool
};

export default ClaimStatusTable;
