import React from "react";
import PropTypes from "prop-types";
import { toTitleCase } from "../Factory";
import cn from "classnames";
import v4 from "uuid/v4";

const propTypes = {
  handleChange: PropTypes.func,
  agencies: PropTypes.array,
  activeAgencyCode: PropTypes.string
};

const getActiveAgencyStatus = (agencyCode, activeAgencyCode) => {
  return agencyCode === activeAgencyCode ? "active default-cursor" : "";
};

const disableActiveAgencyClick = (agencyCode, activeAgencyCode) => {
  return agencyCode === activeAgencyCode;
};

const AgencySelectionList = ({ handleChange, agencies, activeAgencyCode }) => {
  return (
    <li className="agencylist-container">
      <ul className="user-menu__list">
        {agencies &&
          agencies.map(agency => (
            <li
              key={`${agency.agencyCode}-${v4()}`}
              className={cn(
                "agency-selection",
                getActiveAgencyStatus(agency.agencyCode, activeAgencyCode)
              )}
            >
              <button
                key={agency.agencyCode}
                value={agency.agencyCode}
                data-agencycode={agency.agencyCode}
                onClick={
                  disableActiveAgencyClick(agency.agencyCode, activeAgencyCode)
                    ? null
                    : handleChange
                }
              >
                {toTitleCase(agency.name)}
                <br />
                {agency.agencyCode} | {toTitleCase(agency.city)}, {agency.state}
              </button>
            </li>
          ))}
      </ul>
    </li>
  );
};

AgencySelectionList.propTypes = propTypes;

export default AgencySelectionList;
