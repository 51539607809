import API from "../api";

export const getLicensedStates = agency_code => {
  return API.agent().get(`/agencies/${agency_code}/licensed-states`);
};

export const getProducers = agency_code => {
  return API.agent().get(`/agencies/${agency_code}/producers`);
};

export const saveFeedback = body => {
  return API.agent().post(`/feedback`, body);
};
