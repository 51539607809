import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getisSpecialtyPortalView,
  getIsSuretyOnlyAgency
} from "./portalview/changePortalViewStateSelectors";
import { GlobalContextStore } from "./shared/globalContext";
import * as routes from "./constants/routes";
import Account from "./components/account/Account";
import Error from "./shared/error/Error";
import AccountHomeContainer from "./components/account/AccountHomeContainer";
import Chat from "./Chat";
import ClaimInformationContainer from "./claims/containers/ClaimInformationContainer";
import ClaimsInquiryContainer from "./claims/containers/ClaimsInquiryContainer";
import CommercialHome from "./components/commercial/CommercialHome";
import DefaultLayout from "./layout/DefaultLayout";
import HelpDeskContainer from "./help-desk/helpDeskContainer";
import Home from "./components/home";
import MakeAPaymentContainer from "./top-action-cards/makeAPayment/makeAPaymentContainer";
import MoratoriumAlert from "./containers/moratorium/MoratoriumAlertContainer";
import Moratorium from "./containers/moratorium/Moratorium";
import NewsCurrentContainer from "./news/newsCurrentContainer";
import PolicyChanges from "./account-change-requests/policy-changes/PolicyChanges";
import PolicySearchContainerModalContainer from "./containers/PolicySearchContainerModalContainer";
import PolicySearchWrapperContainer from "./containers/PolicySearchWrapperContainer";
import ReportClaimContainer from "./top-action-cards/report-claim/containers/ReportClaimContainer";
import ReportsContainer from "./reports/containers/ReportsContainer";
import ResourcesHome from "./resources/components/ResourcesHome";
import ScrollToTop from "./components/menus/ScrollToTop";
import Sidebar from "./components/sidebar/Sidebar";
import StartNewQuoteContainer from "./top-action-cards/new-quote/startNewQuoteContainer";
import SuretyRouter from "./surety/components/SuretyRouter";
import SuretyRouterFrameless from "./surety/components/SuretyRouterFrameless";
import TopActionCardContainer from "./top-action-cards/topActionCardContainer";
import UmailContainer from "./umail/umailContainer";
import WebsiteLogoUsageContainer from "./components/website-logo-usage/WebsiteLogoUsageContainer";
import withPageViewTracking from "./loggers/withPageViewTracking";
import YourUfgTeamContainer from "./your-ufg-team/components/YourUfgTeamContainer";
import CompletePaymentLegacyContainer from "./agency-sweep/complete-payment/CompletePaymentLegacyContainer";
import CompletePaymentOasisContainer from "./agency-sweep/complete-payment/CompletePaymentOasisContainer";
import VerifyPaymentContainer from "./agency-sweep/verify-payment/VerifyPaymentContainer";
import PaymentConfirmationContainer from "./agency-sweep/payment-confirmation/PaymentConfirmationContainer";
import OnlineQuotingHome from "./online-quoting/OnlineQuotingHome";
import { ToastContainer, Slide } from "react-toastify";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import LegacyContentRedirect from "./components/LegacyContentRedirect";
import VoidPayments from "./void-payments/VoidPayments";
import ProductManagement from "./admin/ProductManagement";

import "./styles/index.scss";
import "@ufginsurance/ui-kit/build/styles/index.css";
import "react-toastify/dist/ReactToastify.css";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import OQAppetiteQuide from "./components/home/OQAppetiteQuide";
import { useFullStory } from "./useFullStory";
import { usePasswordResetNotification } from "./application-hooks/usePasswordResetNotification";
import { changeToMercerSpecialtyPortalViewState } from "./portalview/changePortalViewStateAction";
import BuildersRisk from "./commercial/builders-risk/BuildersRisk";
import ProductSelectionModal from "./commercial/shared/ProductSelection/ProductSelectionModal";
import ErrorBoundary from "./shared/error/ErrorBoundary";
import MyProfileContainer from "./agency-profile/my-profile/MyProfileContainer";
import FeedbackModalContainer from "./feedback/FeedbackModalContainer";

import AgencyProfileContainer from "./agency-profile/agency-profile/AgencyProfileContainer";

const App = props => {
  const {
    agentPortalAdminAccess,
    ufgagentPolicyChanges,
    driverChangesPhase2,
    agencyProfileMicrofrontendEnabled,
    agencyProfileMicrofrontendEnabledPhaseTwo
  } = useFlags();

  const {
    history,
    isSuretyOnlyAgency,
    isSpecialtyPortalView,
    fullStoryUserId,
    agentFirstAndLastName,
    agentEmail,
    employeeUsername,
    lastPasswordChangeDate,
    isHomeAgent,
    isEmployeeAuthentication,
    agencyCode,
    producerCode,
    agentCode,
    agency,
    employeeBranch,
    changeToMercer
  } = props;

  const hasAdminPortalAccess =
    agentPortalAdminAccess === "READ" ||
    agentPortalAdminAccess === "READ_WRITE";

  useFullStory(
    fullStoryUserId,
    agentFirstAndLastName,
    agentEmail,
    employeeUsername
  );

  usePasswordResetNotification(
    lastPasswordChangeDate,
    isHomeAgent,
    isEmployeeAuthentication,
    employeeUsername
  );

  // Update the Launch Darkly user from the userSession data
  // Launch Darkly flags that evaulate this user data...
  // should be additive... only enabling functionaily if a property is set...
  // otherwise, there is a risk that a feature is exposed to the user until this
  // LD indentify process is completed (normally milliseconds)
  const ldClient = useLDClient();
  useEffect(() => {
    if (ldClient) {
      const ldUser = ldClient.getUser();
      if (!ldUser?.agencyCode) {
        ldClient.identify({
          key: agentCode,
          email: agentEmail,
          custom: {
            employeeUsername,
            agentFirstAndLastName,
            agencyCode,
            producerCode,
            agentCode,
            agencyState: agency?.state
          }
        });
      }
    }
  }, [
    agency.state,
    agencyCode,
    agentCode,
    agentEmail,
    agentFirstAndLastName,
    ldClient,
    producerCode,
    employeeUsername
  ]);

  useEffect(() => {
    if (employeeBranch === ("9" || "09")) {
      changeToMercer();
    }
  }, [employeeBranch, changeToMercer]);

  useEffect(() => {
    if (isSuretyOnlyAgency && history.location.pathname === "/")
      history.push(routes.SURETY);
  }, [isSuretyOnlyAgency, history]);

  const agencyProfileRouteMatch = useRouteMatch(routes.AGENCY_PROFILE);
  const myProfileRouteMatch = useRouteMatch(routes.AGENCY_PROFILE_MY_PROFILE);
  const showSidebar = !agencyProfileRouteMatch && !myProfileRouteMatch;

  return (
    <GlobalContextStore>
      <ErrorBoundary>
        <Chat />
        <ToastContainer
          className="ufg__toast"
          closeOnClick={false}
          transition={Slide}
          draggable={false}
          enableMultiContainer
          position="top-center"
        />
        <Switch>
          <Route
            path={[
              routes.SURETY_PACKET_DOWNLOAD,
              routes.QUICK_QUOTING,
              routes.UW_REVIEW,
              routes.SURETY_CONSENT_OF_SURETY
            ]}
            component={SuretyRouterFrameless}
          />
          <Route exact path={routes.BUILDERS_RISK} component={BuildersRisk} />
          <Route path={routes.VOID_PAYMENTS} component={VoidPayments} />
          <Route path={routes.ONLINE_QUOTING} component={OnlineQuotingHome} />
          {ufgagentPolicyChanges && (
            <Route
              exact
              path={routes.POLICY_CHANGES_STEP.replace(":step", "1")}
              component={PolicyChanges}
            />
          )}

            {ufgagentPolicyChanges && driverChangesPhase2 && (
              <Route
                exact
                path={routes.POLICY_CHANGES_STEP.replace(":step", "2")}
                component={PolicyChanges}
              />
            )}

          <Route
          // This route doesn't have 'path' set. It will be the default route
          // if nothing else above matches
          >
            <DefaultLayout>
              <Route path={routes.HOME} component={MoratoriumAlert} />
              <Route path={routes.HOME} component={NewsCurrentContainer} />
              <Route
                path={routes.HOME}
                exact
                component={TopActionCardContainer}
              />
              <Route
                path={routes.HOME_OVERVIEW}
                exact
                component={TopActionCardContainer}
              />

              {hasAdminPortalAccess && (
                <Route
                  path={"/admin/products"}
                  exact
                  component={ProductManagement}
                />
              )}

                {/* 
                TO BE USED WHEN OOA IS READY FOR ADMIN ACCESS
                {hasAdminPortalAccess && (
                  <Route exact path={"/admin"} component={AdminHome} />
                )} 
                */}

              <div className="row bs-row-reset">
                {showSidebar && (
                  <div className="col-lg-3 col-md-12 col-sm-12 side-bar">
                    <Sidebar />
                  </div>
                )}
                <div
                  className={`${
                    showSidebar ? "col-lg-9" : "col-lg-12"
                  } col-md-12 col-sm-12 content-body`}
                >
                  <Switch>
                    <ScrollToTop>
                      <Route path={routes.ERROR} component={Error} />
                      <Route
                        exact
                        path={routes.HOME_OVERVIEW}
                        component={Home}
                      />
                      {agencyProfileMicrofrontendEnabled && (
                        <Route
                          exact
                          path={routes.AGENCY_PROFILE_MY_PROFILE}
                          component={MyProfileContainer}
                        />
                      )}
                      {agencyProfileMicrofrontendEnabledPhaseTwo && (
                        <>
                          <Route
                            exact
                            path={routes.AGENCY_PROFILE}
                            component={AgencyProfileContainer}
                          />
                          <Route
                            exact
                            path={routes.AGENCY_PROFILE_ACTIVE_TAB}
                            component={AgencyProfileContainer}
                          />
                        </>
                      )}

                      <Route exact path={routes.HOME} component={Home} />
                      <Route
                        exact
                        path={routes.ONLINE_QUOTING_APPETITE}
                        component={OQAppetiteQuide}
                      />
                      <Route
                        exact
                        path={routes.ACCOUNT_HOME}
                        component={AccountHomeContainer}
                      />
                      <Switch>
                        <Route
                          exact
                          path={routes.AGENCY_SWEEP_LEGACY_COMPLETE_PAYMENT}
                          component={CompletePaymentLegacyContainer}
                        />
                        <Route
                          exact
                          path={routes.AGENCY_SWEEP_OASIS_COMPLETE_PAYMENT}
                          component={CompletePaymentOasisContainer}
                        />
                        <Route
                          path={routes.PAYMENT_CONFIRMATION}
                          exact
                          component={PaymentConfirmationContainer}
                        />
                        <Route
                          exact
                          path={routes.AGENCY_SWEEP_VERIFY_PAYMENT}
                          component={VerifyPaymentContainer}
                        />
                        <Route
                          exact
                          path={routes.ACCOUNT_OVERVIEW}
                          component={Account}
                        />
                      </Switch>
                      <Route
                        path={routes.COMMERCIAL}
                        render={props => (
                          <CommercialHome
                            {...props}
                            isSpecialtyPortalView={isSpecialtyPortalView}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={routes.REPORTS}
                        component={ReportsContainer}
                      />

                      <Route
                        exact
                        path={routes.REPORTS_ACTIVE_TAB}
                        component={ReportsContainer}
                      />
                      <Route
                        path={routes.RESOURCES}
                        component={ResourcesHome}
                      />
                      <Route path={routes.MORATORIUM} component={Moratorium} />
                      <Route
                        path={routes.WEBSITEUSAGE}
                        component={WebsiteLogoUsageContainer}
                      />
                      <Route
                        path={routes.YOUR_UFG_TEAM_ROOT}
                        component={YourUfgTeamContainer}
                      />
                      {!isSpecialtyPortalView && (
                        <Route path={routes.SURETY} component={SuretyRouter} />
                      )}
                      <Route
                        path={routes.CLAIM_INFORMATION}
                        exact
                        component={ClaimInformationContainer}
                      />
                      <Route
                        path={routes.LEGACY_CONTENT_REDIRECT}
                        component={LegacyContentRedirect}
                      />
                    </ScrollToTop>
                  </Switch>
                </div>
              </div>

                <PolicySearchContainerModalContainer>
                  <PolicySearchWrapperContainer />
                </PolicySearchContainerModalContainer>
                <MakeAPaymentContainer />
                <StartNewQuoteContainer history={history} />
                <UmailContainer />
                <HelpDeskContainer />
                <ClaimsInquiryContainer />
                <ReportClaimContainer />
                <ProductSelectionModal />
                <FeedbackModalContainer />
              </DefaultLayout>
            </Route>
          </Switch>
        </ErrorBoundary>
    </GlobalContextStore>
  );
};

App.propTypes = {
  history: PropTypes.object.isRequired,
  isSuretyOnlyAgency: PropTypes.bool,
  isSpecialtyPortalView: PropTypes.bool,
  isEmployeeAuthentication: PropTypes.bool,
  fullStoryUserId: PropTypes.string,
  agentFirstAndLastName: PropTypes.string,
  agentEmail: PropTypes.string,
  employeeUsername: PropTypes.string,
  lastPasswordChangeDate: PropTypes.string,
  isHomeAgent: PropTypes.bool,
  email: PropTypes.string,
  agencyCode: PropTypes.string,
  producerCode: PropTypes.string,
  agentCode: PropTypes.string,
  agency: PropTypes.object,
  ldClient: PropTypes.any,
  employeeBranch: PropTypes.string,
  changeToMercer: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    isSuretyOnlyAgency: getIsSuretyOnlyAgency(state),
    isSpecialtyPortalView: getisSpecialtyPortalView(state),
    isEmployeeAuthentication: sessionSelector.isEmployeeAuthentication(state),
    fullStoryUserId: sessionSelector.getFullstoryUserId(state),
    agentFirstAndLastName: sessionSelector.getFirstAndLastName(state),
    agentEmail: sessionSelector.getEmail(state),
    employeeUsername: sessionSelector.getEmployeeUserName(state),
    lastPasswordChangeDate: sessionSelector.getLastPasswordChangeDate(state),
    isHomeAgent: sessionSelector.isHomeAgent(state),
    agency: sessionSelector.getActiveAgency(state),
    agencyCode: sessionSelector.getActiveAgencyCode(state),
    producerCode: sessionSelector.getAgentProducerCode(state),
    agentCode: sessionSelector.getAgentCode(state),
    employeeBranch: sessionSelector.getEmployeeBranch(state)
  };
};

const mapDispatchToProps = {
  changeToMercer: changeToMercerSpecialtyPortalViewState
};

export default withPageViewTracking(
  connect(mapStateToProps, mapDispatchToProps)(App)
);
