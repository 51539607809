import React, { useEffect, useState } from "react";
import {
  Alert,
  Anchor,
  Button,
  ContentHeader,
  FlexRow,
  LoadingIndicator,
  Panel
} from "@ufginsurance/ui-kit";
import { useSuretyContext } from "../../surety-context/SuretyContext";
import DefaultErrorResponse from "../../../shared/error/DefaultErrorResponse";
import { SURETY } from "../../../constants/routes";
import { getAgencyInfo } from "../../../services/agencyService";
import { logger } from "../../../loggers";

const Confirmation = ({ history, submission }) => {
  const { agency } = useSuretyContext();
  const [suretyServiceAssistantEmail, setSuretyServiceAssistantEmail] =
    useState("");
  const [suretyUnderwriterEmail, setSuretyUnderwriterEmail] = useState("");

  useEffect(() => {
    getAgencyInfo(agency?.agencyCode).then(
      response => {
        setSuretyServiceAssistantEmail(
          response?.data?.ufgteam?.surety_cst?.email || ""
        );
        setSuretyUnderwriterEmail(
          response?.data?.ufgteam?.surety_underwriter?.email || ""
        );
      },
      error => {
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  }, [agency]);

  if (!agency || submission.error) {
    return (
      <div className="footer-buffer">
        <Alert type="error" dismissible={false}>
          <DefaultErrorResponse />
        </Alert>
      </div>
    );
  }

  if (!suretyServiceAssistantEmail || !suretyUnderwriterEmail)
    return <LoadingIndicator />;

  return (
    <>
      <ContentHeader>Confirmation: Consent Of Surety</ContentHeader>
      <Panel title="Confirmation" bgcolor="grey" titlebar>
        <Panel>
          <b>
            Thank you. Your request has been sent to your underwriting team.
          </b>
        </Panel>
        {suretyServiceAssistantEmail && suretyUnderwriterEmail && (
          <Panel>
            <p>
              The surety team will review this request. This usually takes about{" "}
              <b>1 business day.</b>
              <br />
              <br />
              If you would like to inquire on the status of your request, e-mail
              your SSA at{" "}
              <Anchor
                href={`mailto:${suretyServiceAssistantEmail}`}
                onClick={() => {}}
              >
                {suretyServiceAssistantEmail}
              </Anchor>{" "}
              or your underwriter at{" "}
              <Anchor
                href={`mailto:${suretyUnderwriterEmail}`}
                onClick={() => {}}
              >
                {suretyUnderwriterEmail}
              </Anchor>
              .
            </p>
          </Panel>
        )}
        <b>Disclaimer</b>
        <p>
          UFG Insurance reserves the right to review, deny and/or cancel any
          consent of surety request submitted through this online service. Any
          changes to bond information such as contractor, owner or project
          description submitted through this online service must be communicated
          directly to the assigned customer service technician. <br />
          Agents submitting consents of surety through UFG Insurance&apos;s
          online service also agree to abide by all legal disclaimers and
          privacy notices posted on UFG Insurance&apos;s website.
          <br />
          <br />
          Abuse or misuse of this online service may result in a termination of
          the Agent&apos;s ability to submit consents of surety online at UFG
          Insurance&apos;s sole discretion.
        </p>
      </Panel>
      <FlexRow align="right">
        <Button variant="primary" onClick={() => history.push(SURETY)}>
          Back to Surety
        </Button>
      </FlexRow>
    </>
  );
};

export default Confirmation;
