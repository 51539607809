import React from "react";
import PropTypes from "prop-types";
import {
  ContentHeader,
  Alert,
  Icon,
  FlexRow,
  Anchor
} from "@ufginsurance/ui-kit";
import PolicyCard from "./PolicyCard";
import AccountCard from "./AccountCard";
import { Translations } from "../../translations";
import { iconPolicyStatusFactory } from "../PolicyFactory";
import * as routes from "../../../constants/routes";
import { Link } from "react-router-dom/cjs/react-router-dom";

const propTypes = {
  accountDetails: PropTypes.object,
  policyDetails: PropTypes.array,
  onSelectPolicy: PropTypes.func.isRequired,
  selectedPolicyDetail: PropTypes.any,
  isSpecialtyPortalView: PropTypes.bool
};

const AccountContainer = ({
  accountDetails,
  policyDetails,
  onSelectPolicy,
  selectedPolicyDetail,
  isSpecialtyPortalView
}) => {
  const { iconPolicyStatus, iconPolicyStatusColor } = iconPolicyStatusFactory(
    selectedPolicyDetail && selectedPolicyDetail.status
      ? selectedPolicyDetail.status
      : ""
  );

  const handleChange = selection => {
    const policyDetail = policyDetails.find(
      p => p.policy_number === selection.value
    );
    onSelectPolicy(policyDetail);
  };

  const handleLinkedAccountChange = selection => {
    if (selection.value !== accountDetails.account_number) {
      window.location = `${routes.ACCOUNT_HOME}/${selection.value}`;
    }
  };

  const relatedAccountNumber =
    accountDetails.guidewire_conversion_account_number
      ? accountDetails.guidewire_conversion_account_number
      : accountDetails.legacy_account_number
      ? accountDetails.legacy_account_number
      : null;

  return (
    <div className="account_overview">
      <div>
        <ContentHeader>
          {Translations.account_overview.accountContainer.contentHeader}
        </ContentHeader>
      </div>
      {relatedAccountNumber && (
        <div className="row account-converted-notice">
          <Alert dismissible={false}>
            <FlexRow align="left">
              <div>
                <Icon icon="fasExclamationCircle" />
                &nbsp; This account was rewritten at renewal.
              </div>
              <div className="align-right">
                <Anchor as={Link} to={`/account/${relatedAccountNumber}`}>
                  {`Switch to account ${relatedAccountNumber}`}
                </Anchor>
              </div>
            </FlexRow>
          </Alert>
        </div>
      )}
      <div className="row">
        <div>
          <div className="col-md-5 col-sm-12 account-left-card">
            {accountDetails && (
              <AccountCard
                accountDetails={accountDetails}
                handleLinkedAccountChange={handleLinkedAccountChange}
              />
            )}
          </div>
          <div className="col-md-7 col-sm-12 account-right-card">
            {selectedPolicyDetail && (
              <PolicyCard
                selectedPolicyDetail={selectedPolicyDetail}
                handlePolicyChange={handleChange}
                handleLinkedAccountChange={handleLinkedAccountChange}
                policyDetails={policyDetails}
                eDelivery={accountDetails && accountDetails.e_delivery}
                iconPolicyStatus={iconPolicyStatus}
                iconPolicyStatusColor={iconPolicyStatusColor}
                isSpecialtyPortalView={isSpecialtyPortalView}
                dba_name={accountDetails && accountDetails.dba_name}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AccountContainer.propTypes = propTypes;

export default AccountContainer;
