import PropTypes from "prop-types";
import { DocumentService } from "../../services/documentService";
import {
  printDocuments,
  printCoverageForms
} from "../../document-library/services/documentsService";
import * as dictionary from "../../constants/dictionary";
import { printDocuments as printClaimDocuments } from "../../claims/services/claimsService";
import { getCertificate } from "../../components/home/service-center/services";

const printPdf = props => {
  const {
    form_number,
    form_type,
    onStart,
    onSuccess,
    onComplete,
    onError,
    certificate
  } = props;

  if (
    (!form_number && !certificate) ||
    ![
      dictionary.DOCUMENT_FORM_TYPES.COVERAGE,
      dictionary.DOCUMENT_FORM_TYPES.DOCUMENT,
      dictionary.DOCUMENT_FORM_TYPES.CERTIFICATE,
      dictionary.DOCUMENT_FORM_TYPES.CLAIMS.AGAC
    ].includes(form_type)
  )
    return;

  const documentService = new DocumentService();

  if (onStart) onStart();

  const printFunction = [dictionary.DOCUMENT_FORM_TYPES.CLAIMS.AGAC].includes(
    form_type
  )
    ? printClaimDocuments
    : form_type === dictionary.DOCUMENT_FORM_TYPES.CERTIFICATE
    ? getCertificate
    : form_type === dictionary.DOCUMENT_FORM_TYPES.COVERAGE
    ? printCoverageForms
    : printDocuments;

  const printData = form_type === dictionary.DOCUMENT_FORM_TYPES.CERTIFICATE
    ? certificate
    : form_number;

  printFunction(printData)
    .then(
      res => {
        if (res && res.data) {
          documentService.initializeRenderer("/loading-document");

          documentService.saveOrOpenDocument(
            res.data,
            `Form-${certificate?.amend_id ?? form_number}.pdf`
          );

          if (onSuccess) {
            onSuccess();
          }
        }
      },
      error => {
        documentService.clearRenderer();
        console.log(error);
        if (onError) onError(error);
      }
    )
    .finally(() => {
      if (onComplete) {
        onComplete();
      }
    });
};

export { printPdf };

printPdf.propTypes = {
  certificate: PropTypes.any,
  form_number: PropTypes.string,
  form_type: PropTypes.string.isRequired,
  onStart: PropTypes.func,
  onSuccess: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func
};
