import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Input,
  useForm,
  Form,
  FormGroup,
  Table,
  Button
} from "@ufginsurance/ui-kit";
import { sortByProperty, massageMetadata } from "../shared/util";
import structuredClone from "@ungap/structured-clone";
import coveragePanels, {
  updateCoverageValue,
  applyExclusions
} from "../shared/coveragePanels";
import getCoverages from "../step4/getAllCoverages";
import AdditionalCoveragesName from "../shared/AdditionalCoveragesName";
import OnlineQuotingContext from "../OnlineQuotingContext";
import FormSetup from "../shared/FormSetup";

import "./CoverageSearch.scss";

const CoverageSearch = ({
  unselectedCoverages,
  onHide,
  addCoverageSpinner,
  saveCoverages,
  coveragePaths,
  formId,
  setScheduleItemOpenInCoverable,
  coverageExclusions
}) => {
  const { quoteData, getQuotePart, quoteIsUpdating, toastErrr } =
    useContext(OnlineQuotingContext);

  // coverages that are available to select and searchable in the table
  const [selectableCoverages, setSelectableCoverages] = useState(
    [...unselectedCoverages] || []
  );
  // coverages that are displayed in the table... processed through the search filter
  const [filteredCoverages, setFilteredCoverages] =
    useState(unselectedCoverages);
  // coverages already selected in the parent (step4)
  const [preSelectedCoverages] = useState(
    getCoverages({ coveragePaths, getQuotePart }).filter(c => c.selected)
  );

  const [panels, setPanels] = useState([]);
  const [invalidFields, setInvalidFields] = useState();
  const [formIsInvalid, setFormIsInvalid] = useState(false);

  // updateFormStatus keeps track of invalid fields in the form
  const updateFormStatus = (formId, invalidFields) => {
    setInvalidFields(invalidFields);
  };

  // the useEffect is needed because the form is conditional and if you remove a coverage...
  // ... while it's fields are invalid, the updateFormStatus doesn't get run and the invalidField...
  // ... state doesn't get updated... so we use the same conditional logic 'panels.length' to determine if it's invalid
  useEffect(() => {
    setFormIsInvalid(!!panels?.length && !!invalidFields?.length);
  }, [invalidFields, panels]);

  const initialValues = {
    search: ""
  };
  const form = useForm({ values: initialValues, onSubmit: () => {} });
  const { handleOnBlur, handleOnValidate, values, handleOnChange } = form;

  const getAddButton = row => {
    return (
      <Button
        onClick={() => handleAddCoverage(row)}
        className="addCoverageButton"
        wrapperClassName="oq__add-button__small"
        variant="info"
        size="sm"
        dataAttrib={[
          {
            name: "fullstory_oq_add_coverage_from_search",
            value: row.name
          }
        ]}
      >
        Add
      </Button>
    );
  };

  const tableColumns = [
    {
      key: "name",
      label: "Coverage",
      sortable: true,
      element: row => <AdditionalCoveragesName coverage={row} />
    },
    {
      key: "coverageCategoryDisplayName",
      label: "Category",
      sortable: true,
      element: row => row.coverageCategoryDisplayName.replace(/Line\s/g, ""),
      className: "oq__coverage-search-table__category-col"
    },
    {
      key: "",
      label: "",
      element: row => getAddButton(row),
      className: "oq__coverage-search-table__add-col__building",
      align: "center"
    }
  ];

  const localSaveCoverages = useCallback(
    (row, termToUpdate, action = "added") => {
      saveCoverages(row, termToUpdate, action);
    },
    [saveCoverages]
  );

  const localUpdateCoverageValue = (fieldName, value) => {
    const allCoverages = getCoverages({ coveragePaths, getQuotePart });
    updateCoverageValue({
      fieldName,
      value,
      allCoverages,
      toastErrr,
      saveCoverages: (coverage, termToUpdate) => {
        localSaveCoverages(coverage, termToUpdate, "added");
      }
    });
  };

  // adding and removing the coverage to/from the quote... the useEffect below updates the selectableCoverages state
  const handleAddCoverage = row => {
    const coverage = structuredClone(row);
    localSaveCoverages(coverage, null, "added");
  };

  const removeCoverageAndSave = useCallback(
    coverageToRemove => {
      const coverage = structuredClone(coverageToRemove);
      localSaveCoverages(coverage, null, "removed");
    },
    [localSaveCoverages]
  );

  // this useEffect is used to keep the filtered state (selectable coverages in the table)
  // ...updated as the coverage selections change
  useEffect(() => {
    const availCoverages = selectableCoverages.filter(c => !c.selected);
    const searchString = values.search;

    if (searchString) {
      const filtered = selectableCoverages.filter(
        c => c.name && c.name.toLowerCase().includes(searchString.toLowerCase())
      );
      setFilteredCoverages(filtered);
    } else {
      setFilteredCoverages(availCoverages);
    }
  }, [values, selectableCoverages]);

  // this useEffect is used to update the coverage panels...
  // it watches the quoteData and selected coverages
  // and it filters out coverages that were preSelected (on the parent component)
  useEffect(() => {
    let allCoverages = getCoverages({ coveragePaths, getQuotePart });
    allCoverages = [...applyExclusions(allCoverages, coverageExclusions)];
    const currentlySelectedCoverages = allCoverages.filter(
      c =>
        c.selected && !preSelectedCoverages.some(s => s.publicID === c.publicID)
    );
    const _panels = [
      ...massageMetadata(
        coveragePanels({
          fields: currentlySelectedCoverages,
          coverageExclusions: {},
          removeCoverageAndSave,
          quoteData,
          setScheduleItemOpenInCoverable
        }),
        quoteData
      )
    ];
    setPanels(_panels);
    setSelectableCoverages(allCoverages.filter(c => !c.selected));
  }, [
    coverageExclusions,
    coveragePaths,
    getQuotePart,
    preSelectedCoverages,
    quoteData,
    removeCoverageAndSave,
    setScheduleItemOpenInCoverable
  ]);

  const continueAction = () => {
    onHide();
  };

  return (
    <div id="step4-coverage-search-form">
      {panels.length > 0 && (
        <FormSetup
          sectionHeader=""
          panels={panels}
          handleFormSubmit={() => {}}
          submitBtnLabel=""
          saveCurrentValues={localUpdateCoverageValue}
          hideSubmitBtn
          useCoveragePanel
          updateFormStatus={updateFormStatus}
          formId={formId}
          coverageUpdating={quoteIsUpdating}
        />
      )}
      <Form context={form}>
        <FormGroup noOuterPadding className="searchInputRow addPadUnder">
          <Input
            id="search"
            name="search"
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            label="Search for Additional Coverages"
            onChange={handleOnChange}
            value={values.search}
            size="lg"
            maxLength={255}
          />
        </FormGroup>
        <Table
          rowKey="publicID"
          className="oq__coverage-search-table"
          initialSort="coverageCategoryDisplayName"
          initialDirection="desc"
          columns={tableColumns}
          data={filteredCoverages.sort(sortByProperty("name"))}
          showPagination
          itemsPerPage={5}
          noResultsMessage="No results found"
        />
        <FormGroup align="right">
          <Button
            variant="primary"
            disabled={addCoverageSpinner || formIsInvalid}
            onClick={continueAction}
          >
            Continue
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

export default CoverageSearch;
