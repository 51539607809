import * as types from "../constants/actionTypes";
import * as billingService from "../services/billingService";
import { DocumentService } from "../services/documentService";

const fetchInstallmentStarted = () => ({
  type: types.FETCH_INSTALLMENT_STARTED
});

const fetchInstallmentSucceeded = data => ({
  type: types.FETCH_INSTALLMENT_SUCCEEDED,
  payload: data
});

const fetchInstallmentFailed = error => ({
  type: types.FETCH_INSTALLMENT_FAILED,
  payload: error,
  error: true
});

const sortByDueDate = (a, b) => {
  return new Date(a.due_date) - new Date(b.due_date);
};

export const fetchInstallmentSchedule = (
  policy_account_number,
  agency_code
) => {
  return dispatch => {
    dispatch(fetchInstallmentStarted());

    return billingService
      .getInstallmentDetails(policy_account_number, agency_code)
      .then(
        response => {
          const printData = {
            ...response.data,
            user_type: "A",
            installments: response.data.installments.sort(sortByDueDate)
          };
          dispatch(
            fetchInstallmentSucceeded({
              installments: response.data.installments,
              print_installment_data: printData
            })
          );
        },
        error => {
          dispatch(fetchInstallmentFailed(error.response));
        }
      );
  };
};

export const fetchInstallmentDocument = data => () => {
  const documentService = new DocumentService();
  return billingService
    .getInstallmentPrintReport(data)
    .then(response => {
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(
        response.data,
        `Billing-installment-schedule.pdf`
      );
    })
    .catch(() => {
      documentService.clearRenderer();
    });
};
