import { DriverLicenseValidationRegex } from "../../online-quoting/shared/DriverLicenseValidationRegex";
import { convertStatesAndAbbreviations } from "../../../src/components/Factory";

export const DRIVER_LICENSE_STATE_DROPDOWN_LIST =
  Object.keys(DriverLicenseValidationRegex)?.map(state => {
    return {
      label: convertStatesAndAbbreviations(state, "name"),
      value: state
    };
  }) ?? [];

export const validateStateDriverLicenseNumberRules = (
  licenseNumber,
  licenseState
) => {
  if (!licenseState) {
    return {
      valid: false,
      errorString: "License State is empty. "
    };
  }
  const stateLicenseRuleObjects =
    DriverLicenseValidationRegex[licenseState] || [];
  let valid = false;
  let errorString = "";
  stateLicenseRuleObjects.forEach(licenseRule => {
    if (licenseRule.regex.test(licenseNumber) === true) {
      valid = true;
    } else {
      errorString =
        errorString.length === 0
          ? errorString.concat(licenseRule.description)
          : errorString.concat(" or ").concat(licenseRule.description);
    }
  });
  return {
    valid,
    errorString
  };
};
