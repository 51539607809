import * as types from "../constants/actionTypes";
import * as accountholderService from "../services/accountholderService";
import { DocumentService } from "../services/documentService";
import { logger } from "../loggers";

export const fetchAccountDataStarted = () => ({
  type: types.FETCH_ACCOUNT_INFO_STARTED
});

export const fetchAccountDataSucceeded = data => ({
  type: types.FETCH_ACCOUNT_INFO_SUCCEEDED,
  payload: data
});

export const fetchAccountDataFailed = error => ({
  type: types.FETCH_ACCOUNT_INFO_FAILED,
  payload: error,
  error: true
});

export const fetchAccountServiceLinksStarted = () => ({
  type: types.FETCH_ACCOUNT_SERVICE_LINKS_STARTED
});

export const fetchAccountServiceLinksSucceeded = (data, policyNumber) => ({
  type: types.FETCH_ACCOUNT_SERVICE_LINKS_SUCCEEDED,
  payload: data,
  policyNumber
});

export const fetchAccountServiceLinksFailed = (error, policyNumber) => ({
  type: types.FETCH_ACCOUNT_SERVICE_LINKS_FAILED,
  payload: error,
  error: true,
  policyNumber
});

export const fetchAutoIdCardsStarted = () => ({
  type: types.FETCH_AUTO_ID_CARDS_STARTED
});

export const fetchAutoIdCardsSucceeded = data => ({
  type: types.FETCH_AUTO_ID_CARDS_SUCCEEDED,
  payload: data
});

export const fetchAutoIdCardsFailed = error => ({
  type: types.FETCH_AUTO_ID_CARDS_FAILED,
  payload: error,
  error: true
});

export const fetchAccountData = (
  activeAgencyCode,
  searchNumber
) => dispatch => {
  dispatch(fetchAccountDataStarted());

  return accountholderService.getAccountDetails(searchNumber).then(
    response => {
      if (
        response &&
        response.data &&
        response.data.policy_summary &&
        response.data.policy_summary.length > 0
      ) {
        const policies = response.data.policy_summary;
        const matchOrFirstPolicy =
          policies.find(p => p.policy_number === searchNumber) || policies[0];
        const {
          company_branch,
          effective_date,
          policy_number
        } = matchOrFirstPolicy;

        dispatch(
          fetchAccountServiceLinks(
            policy_number,
            activeAgencyCode,
            company_branch,
            effective_date
          )
        );
      }
      dispatch(fetchAccountDataSucceeded(response.data));
    },
    error => {
      // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
      dispatch(fetchAccountDataFailed(error.response));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

export const fetchAccountServiceLinks = (
  policyNumber,
  activeAgencyCode,
  companyBranch,
  effectiveDate
) => dispatch => {
  dispatch(fetchAccountServiceLinksStarted());

  return accountholderService
    .fetchAccountServiceLinks(
      policyNumber,
      activeAgencyCode,
      companyBranch,
      effectiveDate
    )
    .then(
      response => {
        dispatch(
          fetchAccountServiceLinksSucceeded(response.data, policyNumber)
        );
      },
      error => {
        dispatch(fetchAccountServiceLinksFailed(error.response, policyNumber));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};

export const fetchAutoIdCards = search_number => dispatch => {
  dispatch(fetchAutoIdCardsStarted());
  return accountholderService.getAutoIdCards(search_number).then(
    response => {
      dispatch(fetchAutoIdCardsSucceeded(response.data));
    },
    error => {
      // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
      dispatch(fetchAutoIdCardsFailed(error.response));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

// Not entirely sure this belongs in redux actions
export const viewAutoIdCard = (id_number, search_number, policy_type) => () => {
  const documentService = new DocumentService();

  return accountholderService
    .getAutoIdCard(id_number, search_number, policy_type)
    .then(
      response => {
        documentService.initializeRenderer("/loading-document");
        documentService.saveOrOpenDocument(
          response.data,
          `${id_number}-auto-id-card.pdf`
        );
      },
      error => {
        documentService.clearRenderer();
        logger.error({
          description: error.toString(),
          fatal: false
        });
      }
    );
};

export const leftAccountOverview = () => ({
  type: types.LEFT_ACCOUNT_OVERVIEW
});

export const fetchDriversListReportStarted = () => ({
  type: types.FETCH_DRIVERS_LIST_REPORT_STARTED
});

export const fetchDriversListReportSucceeded = () => ({
  type: types.FETCH_DRIVERS_LIST_REPORT_SUCCEEDED
});

export const fetchDriversListReportFailed = error => ({
  type: types.FETCH_DRIVERS_LIST_REPORT_FAILED,
  payload: error,
  error: true
});

export const fetchDriversListReport = body => dispatch => {
  const documentService = new DocumentService();
  const { effective_date, policy_number } = body;
  dispatch(fetchDriversListReportStarted());
  return accountholderService
    .fetchDriversListReport(body)
    .then(
      response => {
        dispatch(fetchDriversListReportSucceeded());
        documentService.initializeRenderer("/loading-document");
        documentService.saveOrOpenDocument(
          response.data,
          `${policy_number}-drivers-${effective_date}.pdf`
        );
      },
      error => {
        dispatch(fetchDriversListReportFailed(error.response));
        documentService.clearRenderer();
        logger.error({
          description: error.toString(),
          fatal: false
        });
      }
    )
    .catch(() => {
      documentService.clearRenderer();
    });
};

export const fetchVehiclesListReportStarted = () => ({
  type: types.FETCH_VEHICLES_LIST_REPORT_STARTED
});

export const fetchVehiclesListReportSucceeded = () => ({
  type: types.FETCH_VEHICLES_LIST_REPORT_SUCCEEDED
});

export const fetchVehiclesListReportFailed = error => ({
  type: types.FETCH_VEHICLES_LIST_REPORT_FAILED,
  payload: error,
  error: true
});

export const fetchVehiclesListReport = body => dispatch => {
  const documentService = new DocumentService();
  const { effective_date, policy_number } = body;
  dispatch(fetchVehiclesListReportStarted());
  return accountholderService
    .fetchVehiclesListReport(body)
    .then(
      response => {
        dispatch(fetchVehiclesListReportSucceeded());
        documentService.initializeRenderer("/loading-document");
        documentService.saveOrOpenDocument(
          response.data,
          `${policy_number}-vehicles-${effective_date}.pdf`
        );
      },
      error => {
        dispatch(fetchVehiclesListReportFailed(error.response));
        documentService.clearRenderer();
        logger.error({
          description: error.toString(),
          fatal: false
        });
      }
    )
    .catch(() => {
      documentService.clearRenderer();
    });
};
