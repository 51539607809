import { connect } from "react-redux";
import PolicyLossRunForm from "../components/claims/policy-loss-runs/PolicyLossRunForm";
import { searchKeywordUpdatedReportClaims } from "../../actions/policies";

// TODO - Chris: Containers that don't need state are generally result of some
// other application architecture issue that needs fixed.
const mapStateToProps = () => ({});

const mapDispatchToProps = {
  searchKeywordUpdatedReportClaims
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyLossRunForm);
