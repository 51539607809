import React from "react";
import PropTypes from "prop-types";
import { DateFormat } from "../../Factory";
import { Translations } from "../../../components/translations";
import { Button, Table, Modal, FlexRow } from "@ufginsurance/ui-kit";

const BILL_STATEMENTS = {
  A: "Billing Statement",
  P: "Billing Statement",
  C: "Billing Statement, Notice of Cancellation",
  E: "Billing Statement, Canceled Policy"
};

const ViewStatement = props => {
  const {
    getLegacyViewStatementPDF,
    getGWViewStatementPDF,
    accountNumber,
    billing_statements,
    earned_premium_statements,
    show,
    handleClose
  } = props;

  const handleDocumentClick = row => () => {
    const billDate = DateFormat(row.bill_date, "YYYY-MM-DD");
    const identifier =
      row.bill_type === "A" ? accountNumber : row.policy_number;

    if (row.type === "gw") {
      getGWViewStatementPDF(row.bill_type, identifier, row.document_id);
    } else {
      getLegacyViewStatementPDF(
        row.bill_type,
        billDate,
        identifier,
        row.category
      );
    }
  };

  const getBillTypeLabel = (billType, category) => {
    return (
      BILL_STATEMENTS[category === "earned-premium" ? "E" : billType] ||
      "View Statement"
    );
  };

  const createColumns = () => [
    {
      key: "bill_date",
      label: Translations.account_overview.billingDetailsTab.statementDate,
      element: row => {
        return <span>{DateFormat(row.bill_date)}</span>;
      }
    },
    {
      key: "bill_type",
      label: Translations.account_overview.billingDetailsTab.documentType,
      element: row => {
        return (
          <Button isLink variant="secondary" onClick={handleDocumentClick(row)}>
            {getBillTypeLabel(row.bill_type, row.category)}
          </Button>
        );
      }
    }
  ];

  const statements = billing_statements
    .map(_ => ({
      ..._,
      category: "billing"
    }))
    .concat(
      earned_premium_statements.map(_ => ({
        ..._,
        bill_date: _.earned_premium_date,
        bill_type: _.earned_premium_type,
        category: "earned-premium"
      }))
    )
    .sort((a, b) =>
      a.bill_date > b.bill_date ? -1 : a.bill_date < b.bill_date ? 1 : 0
    )
    .slice(0, 3);

  const hasStatements = statements.length > 0;

  return (
    <div>
      <Modal
        body={
          <div>
            {!hasStatements ? (
              <div>{Translations.account_overview.billingDetailsTab.error}</div>
            ) : (
              <Table
                rowKey="document_id"
                data={statements}
                columns={createColumns()}
                itemsPerPage={20}
                initialDirection="desc"
                initialSort="process_date"
                showPagination
              />
            )}
          </div>
        }
        footer={
          <FlexRow align="right">
            <Button type="button" variant="secondary" onClick={handleClose}>
              {Translations.account_overview.billingDetailsTab.close}
            </Button>
          </FlexRow>
        }
        title={Translations.account_overview.billingDetailsTab.view_statement}
        subTitle={
          hasStatements && (
            <div className="sub-heading">
              {
                Translations.account_overview.billingDetailsTab
                  .statement_sub_heading
              }
            </div>
          )
        }
        show={show}
        onHide={handleClose}
      />
    </div>
  );
};

ViewStatement.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  billing_statements: PropTypes.array.isRequired,
  earned_premium_statements: PropTypes.array.isRequired,
  getLegacyViewStatementPDF: PropTypes.func,
  getGWViewStatementPDF: PropTypes.func,
  accountNumber: PropTypes.string
};

export default ViewStatement;
