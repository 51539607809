import React from "react";
import { SidebarNav } from "../../components/menus/SidebarNav";
import { Translations } from "../../components/translations";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as routes from "../../constants/routes";

const SuretySideNav = () => {
  const {
    suretyAgentManualsUpdateEnabled,
    suretyDocumentsAndFormsEnabled,
    suretyNewLandingPageEnabled,
    suretyContractAndCommercialPagesEnabled
  } = useFlags();

  const getSuretyLinks = () => {
    const links = [];
    if (suretyNewLandingPageEnabled) {
      links.push({
        to: routes.SURETY,
        activeClassName: "nav-menu--active",
        text: "Surety"
      });
    }
    if (suretyAgentManualsUpdateEnabled) {
      links.push({
        to: routes.SURETY_AGENT_MANUALS,
        activeClassName: "nav-menu--active",
        text: "Surety Agent Manuals"
      });
    }
    if (suretyDocumentsAndFormsEnabled) {
      links.push({
        to: routes.SURETY_DOCS_FORMS,
        activeClassName: "nav-menu--active",
        text: "Surety Documents and Forms"
      });
    }
    if (suretyContractAndCommercialPagesEnabled) {
      links.push({
        to: routes.CONTRACT_SURETY,
        activeClassName: "nav-menu--active",
        text: "Contract Surety"
      });
      links.push({
        to: routes.COMMERCIAL_SURETY,
        activeClassName: "nav-menu--active",
        text: "Commercial Surety"
      });
    }
    return links;
  };

  return (
    <SidebarNav links={getSuretyLinks()} header={Translations.Surety.surety} />
  );
};

export default SuretySideNav;
