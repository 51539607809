import { lessorsRiskClasses } from "./constants";

const businessTypesNotWithLessorRisk = [
  "convenience store",
  "distributor",
  "restaurant-casual dining",
  "restaurant-fine dining",
  "restaurant-fast food"
];

export const classCodeFilter = ({
  data,
  busType,
  searchText = "",
  lessorsRiskIndicator,
  selectedClasses = []
}) => {
  if (!data) return [];

  return data.filter(c => {
    // business type filtering
    const b = !!busType
      ? Array.isArray(busType)
        ? busType.length
          ? busType.includes(c.businessType_UFG.toLowerCase())
          : true
        : c.businessType_UFG.toLowerCase() === busType.toLowerCase()
      : true;
    // search text filtering
    const s = !!searchText
      ? c.description.toLowerCase().includes(searchText.toLowerCase()) ||
        c.code.toLowerCase().includes(searchText.toLowerCase())
      : true;

    // selected classes filtering
    const sel = !(selectedClasses || []).some(s => c.code === s.code);

    // is this class a lessors' risk class?
    const lessorsRiskFound = lessorsRiskClasses.find(
      cl => c.description === cl.description && c.code === cl.code
    );

    /**
     * business type not with lessor risk
     * https://ufginsurance.atlassian.net/browse/OOQ-13961
     * if is lessor risk, then don't show classes from specific business types
     */
    const btnwlr = lessorsRiskIndicator
      ? !businessTypesNotWithLessorRisk.includes(
          c.businessType_UFG.toLowerCase()
        )
      : true;

    // if we get a true/false for the LR indicator, then:
    // if is, then display whichever matches the switch value
    // if not, then display the class (true)
    const l =
      lessorsRiskIndicator !== undefined
        ? lessorsRiskFound
          ? lessorsRiskFound.isLessorsRisk === lessorsRiskIndicator
          : true
        : true;

    return b && s && l && sel && btnwlr;
  });
};
