import * as types from "../constants/actionTypes";
import * as billingService from "../services/billingService";
import { logger } from "../loggers";
import { DocumentService } from "../services/documentService";

const fetchBillingStarted = () => ({
  type: types.FETCH_BILLING_STARTED
});

const fetchBillingSucceeded = data => ({
  type: types.FETCH_BILLING_SUCCEEDED,
  payload: data
});

const fetchBillingFailed = error => ({
  type: types.FETCH_BILLING_FAILED,
  payload: error,
  error: true
});

export const fetchBillingDetailsStatement = (
  policy_account_number,
  agency_code,
  begin_date,
  end_date
) => dispatch => {
  dispatch(fetchBillingStarted());

  return billingService
    .getBillingDetails(policy_account_number, agency_code, begin_date, end_date)
    .then(
      response =>
        dispatch(
          fetchBillingSucceeded({
            activities: response?.data?.history || [],
            unapplied_amount: response?.data?.unapplied_amount
          })
        ),
      error => {
        dispatch(fetchBillingFailed(error.response));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};

export const postBillingDetails = data => () => {
  const documentService = new DocumentService();
  documentService.initializeRenderer("/loading-document");
  return billingService
    .getHistoryPrintReport(data)
    .then(response => {
      documentService.saveOrOpenDocument(
        response.data,
        `Billing-details-history.pdf`
      );
    })
    .catch(() => {
      documentService.clearRenderer();
    });
};
