import React, { useState, useMemo } from "react";
import { toast } from "react-toastify";
import {
  DatePicker,
  Dropdown,
  Input,
  formatDate,
  FlexRow,
  Button,
  Form,
  FormGroup,
  useForm
} from "@ufginsurance/ui-kit";
import { usePolicyChangesContext } from "../context";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";
import {
  DRIVER_LICENSE_STATE_DROPDOWN_LIST,
  validateStateDriverLicenseNumberRules
} from "../DriverUtils";
import { useFlags } from "launchdarkly-react-client-sdk";

const DriverDetailsForm = ({ data, handleOnClose }) => {
  const { driverChangesPhase2 } = useFlags();

  const { policyData, updatePolicyChangeDriver, createPolicyChangeDriver } =
    usePolicyChangesContext();
  const { policyInfo } = policyData;
  const [saveEditDriverIsLoading, setSaveEditDriverIsLoading] = useState(false);
  const [addAnotherDriverIsLoading, setAddAnotherDriverIsLoading] =
    useState(false);
  const [licenseNumberChanged, setLicenseNumberChanged] = useState(false);
  const DRIVER_EDIT_SUCCESS_MESSAGE =
    "The driver has been successfully updated.";
  const DRIVER_ADD_ANOTHER_SUCCESS_MESSAGE =
    "The driver has been successfully added.";
  const isEditMode = !!data?.driver_id;
  const GENDER_OPTIONS = [
    {
      label: "Male",
      value: "M"
    },
    {
      label: "Female",
      value: "F"
    },
    {
      label: "Unknown",
      value: "Unknown"
    }
  ];
  const DATE_OF_BIRTH_DATE_PICKER_FORMAT = "MM/DD/YYYY";
  const DATE_OF_BIRTH_SERVICES_DATE_FORMAT = "YYYY-MM-DD";
  const INITIAL_MASKED_DATE_OF_BIRTH_FORM_VALUE = "";

  const { youngestDriverAge, oldestDriverAge } = useMemo(() => {
    if (!policyInfo?.policy_effective_date) return new Date();
    const { year, month, day } = formatDate(
      policyInfo.policy_effective_date,
      "OBJECT"
    );
    const effective_date = new Date(year, month, day, 0, 0, 0, 0);
    const oldestDriverAge = new Date(effective_date.setHours(0, 0, 0, 0));
    oldestDriverAge.setFullYear(
      oldestDriverAge.getFullYear() - 71,
      oldestDriverAge.getMonth(),
      oldestDriverAge.getDate() + 1
    );
    const youngestDriverAge = new Date(effective_date.setHours(0, 0, 0, 0));
    youngestDriverAge.setFullYear(
      youngestDriverAge.getFullYear() - 21,
      youngestDriverAge.getMonth(),
      youngestDriverAge.getDate()
    );
    return {
      effective_date,
      youngestDriverAge,
      oldestDriverAge
    };
  }, [policyInfo?.policy_effective_date]);

  const displayToast = (success, addAnother = false) => {
    const options = {
      position: "top-center",
      className: success ? "success" : "error",
      autoClose: success
    };
    if (addAnother) {
      setAddAnotherDriverIsLoading(false);
    } else {
      setSaveEditDriverIsLoading(false);
    }
    const message = success ? (
      addAnother ? (
        DRIVER_ADD_ANOTHER_SUCCESS_MESSAGE
      ) : (
        DRIVER_EDIT_SUCCESS_MESSAGE
      )
    ) : (
      <HelpDeskMessage display="anErrorOccurred" />
    );
    toast(message, options);
    if (!addAnother) {
      handleOnClose();
    } else {
      setLicenseNumberChanged(false);
      updateForm({
        values: {
          first_name: "",
          last_name: "",
          date_of_birth: INITIAL_MASKED_DATE_OF_BIRTH_FORM_VALUE,
          gender: "",
          license_state: !isEditMode ? policyInfo?.base_state || "" : "",
          license_number: "",
          driver_id: ""
        },
        errors: {}
      });
    }
  };

  const onSubmit = (formData, addAnother = false) => {
    const { values } = formData;
    if (addAnother) {
      setAddAnotherDriverIsLoading(true);
    } else {
      setSaveEditDriverIsLoading(true);
    }
    const driver = {
      ...values,
      date_of_birth: formatDate(
        values.date_of_birth,
        DATE_OF_BIRTH_SERVICES_DATE_FORMAT
      )
    };
    if (isEditMode) {
      if (!licenseNumberChanged) {
        delete driver.license_number;
      }
      if (driver.date_of_birth === INITIAL_MASKED_DATE_OF_BIRTH_FORM_VALUE) {
        delete driver.date_of_birth;
      }
      updatePolicyChangeDriver(
        policyInfo?.policy_number,
        policyInfo?.job_id,
        data.driver_id,
        driver
      )
        .then(res => {
          const success = res.status === 200;
          displayToast(success);
        })
        .catch(_err => {
          displayToast(false);
        });
    } else {
      delete driver.driver_id;
      createPolicyChangeDriver(
        policyInfo?.policy_number,
        policyInfo?.job_id,
        driver
      )
        .then(res => {
          const success = res.status === 200;
          displayToast(success, addAnother);
        })
        .catch(_err => {
          displayToast(false, addAnother);
        });
    }
  };

  const form = useForm({
    values: {
      first_name: data?.first_name || "",
      last_name: data?.last_name || "",
      date_of_birth: INITIAL_MASKED_DATE_OF_BIRTH_FORM_VALUE,
      gender: data?.gender || "",
      license_state: !isEditMode
        ? policyInfo?.base_state || ""
        : data?.license_state || "",
      license_number: data?.license_number || "",
      driver_id: data?.driver_id || ""
    },
    onSubmit
  });

  const {
    errors,
    values,
    invalidFields,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm
  } = form;

  const handleOnSaveAddAnother = () => {
    onSubmit(form, true);
  };

  const saveButtonDisabled = useMemo(() => {
    if (isEditMode) {
      const fieldsAreSame =
        values.first_name === data?.orig_first_name &&
        values.last_name === data?.orig_last_name &&
        values.date_of_birth === INITIAL_MASKED_DATE_OF_BIRTH_FORM_VALUE &&
        values.gender === data?.orig_gender &&
        values.license_state === data?.orig_license_state &&
        values.license_number === data?.orig_license_number;
      return (
        fieldsAreSame ||
        Object.keys(errors).length >= 1 ||
        saveEditDriverIsLoading ||
        addAnotherDriverIsLoading
      );
    }
    const emptyFieldExists =
      values.first_name.length === 0 ||
      values.last_name.length === 0 ||
      values.date_of_birth.length === 0 ||
      values.gender.length === 0 ||
      values.license_state.length === 0 ||
      values.license_number.length === 0;
    return (
      emptyFieldExists ||
      Object.keys(errors).length >= 1 ||
      saveEditDriverIsLoading ||
      addAnotherDriverIsLoading
    );
  }, [
    errors,
    saveEditDriverIsLoading,
    addAnotherDriverIsLoading,
    isEditMode,
    values,
    data
  ]);

  const handleDriverLicenseValidate = ({ field, value, validation }) => {
    let fieldErrors = [];
    if (licenseNumberChanged) {
      fieldErrors = handleOnValidate({ field, value, validation });
      const validationResponse = validateStateDriverLicenseNumberRules(
        value,
        values.license_state
      );
      if (!validationResponse.valid) {
        fieldErrors.push(
          values.license_state +
            " License formatting must match: " +
            validationResponse.errorString
        );
      }
    }
    return fieldErrors;
  };

  const handleDateOfBirthValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });

    if (
      !driverChangesPhase2 &&
      (formatDate(values.date_of_birth) > youngestDriverAge ||
        formatDate(values.date_of_birth) < oldestDriverAge)
    ) {
      fieldErrors.push(
        "Drivers younger than 21 or older than 70 not allowed. Please submit via the Other Change request form for underwriter review."
      );
    }

    return fieldErrors;
  };

  const handleLicenseNumberChange = ({ event, field, value }) => {
    const changed = values.license_number !== value;
    setLicenseNumberChanged(changed);
    const forceUppercaseLicenseNumber = value?.toUpperCase() ?? "";
    handleOnChange({ event, field, value: forceUppercaseLicenseNumber });
  };

  const handleLicenseStateChange = ({ event, field, value }) => {
    setLicenseNumberChanged(true);
    updateForm({
      values: {
        license_state: value,
        license_number: ""
      },
      errors: {
        license_number: "License Number is required."
      }
    });
    handleOnChange({event, field, value});
  };

  return (
    <>
      <input
        hidden
        id="policy_effective_date"
        readOnly
        value={policyInfo?.policy_effective_date}
      />
      <Form context={form} id="driver-details-form">
        <FormGroup groupErrors wrap={false}>
          <Input
            id="first_name"
            name="first_name"
            label="First Name"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.first_name}
            required
            disabled={saveEditDriverIsLoading || addAnotherDriverIsLoading}
          />
          <Input
            id="last_name"
            name="last_name"
            label="Last Name"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.last_name}
            required
            disabled={saveEditDriverIsLoading || addAnotherDriverIsLoading}
          />
          <DatePicker
            id="date_of_birth"
            name="date_of_birth"
            label="Date of Birth"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleDateOfBirthValidate}
            value={values?.date_of_birth}
            format={DATE_OF_BIRTH_DATE_PICKER_FORMAT}
            minDate={driverChangesPhase2 ? null : formatDate(oldestDriverAge)}
            maxDate={driverChangesPhase2 ? null : formatDate(youngestDriverAge)}
            required={!isEditMode}
            disabled={saveEditDriverIsLoading || addAnotherDriverIsLoading}
            className="driver-date-of-birth"
            size="md"
            placeholder={
              (isEditMode && data?.date_of_birth) ||
              DATE_OF_BIRTH_DATE_PICKER_FORMAT
            }
          />

          <Dropdown
            isSearchable
            id="gender"
            name="gender"
            label="Gender"
            options={GENDER_OPTIONS}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.gender}
            required
            size="md"
            disabled={saveEditDriverIsLoading || addAnotherDriverIsLoading}
          />
        </FormGroup>
        <FormGroup groupErrors wrap={false}>
          <Dropdown
            isSearchable
            id="license_state"
            name="license_state"
            label="License State"
            options={DRIVER_LICENSE_STATE_DROPDOWN_LIST}
            onChange={handleLicenseStateChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.license_state}
            required
            size="md"
            disabled={saveEditDriverIsLoading || addAnotherDriverIsLoading}
          />
          {licenseNumberChanged ? (
            <Input
              id="license_number"
              name="license_number"
              label="License Number"
              onChange={handleLicenseNumberChange}
              onBlur={handleOnBlur}
              onValidate={handleDriverLicenseValidate}
              value={values.license_number}
              required
              size="md"
              disabled={saveEditDriverIsLoading || addAnotherDriverIsLoading}
            />
          ) : (
            <Input
              id="license_number"
              name="license_number"
              label="License Number"
              onChange={handleLicenseNumberChange}
              onBlur={handleOnBlur}
              onValidate={handleDriverLicenseValidate}
              value=""
              size="md"
              disabled={saveEditDriverIsLoading || addAnotherDriverIsLoading}
              placeholder={values.license_number}
            />
          )}
        </FormGroup>
        <FlexRow align="right">
          <Button
            disabled={saveEditDriverIsLoading || addAnotherDriverIsLoading}
            onClick={handleOnClose}
          >
            Cancel
          </Button>
          {!isEditMode && (
            <Button
              disabled={saveButtonDisabled || (invalidFields?.length ?? 0) > 0}
              id="driver-details-save-add-another"
              variant="primary"
              spinner={addAnotherDriverIsLoading}
              onClick={handleOnSaveAddAnother}
            >
              Save and Add New Driver
            </Button>
          )}

          <Button
            disabled={saveButtonDisabled || (invalidFields?.length ?? 0) > 0}
            type="submit"
            id="driver-details-save"
            variant="primary"
            spinner={saveEditDriverIsLoading}
          >
            Save
          </Button>
        </FlexRow>
      </Form>
    </>
  );
};

export default DriverDetailsForm;
