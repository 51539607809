import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import * as routes from "../../constants/routes";
import { Translations } from "../../components/translations";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import cn from "classnames";
import AgencySelectionList from "../../components/agencyselection/AgencySelectionList";
import { withLDConsumer, useFlags } from "launchdarkly-react-client-sdk";
import { NAV_EVENT_TYPES, HEADER_NAV_ITEMS } from "../../constants/dictionary";

const HeaderUserMenu = ({
  user,
  logoutAndRedirect,
  changeAgencyView,
  impersonateAgentRedirect,
  applicationId,
  firstName,
  agentCode,
  activeAgencyCode,
  agencies,
  isMyProfileEnabled,
  isAgencyAdministrator,
  isEmployeeAuthentication,
  canImpersonate,
  isImpersonatingAnotherAgent,
  isSpecialtyPortalView,
  changeToUfgAgent,
  changeToMercer,
  hasAccessToUfgAgentAndSpecialty,
  history,
  setUserNavExpanded,
  userNavExpanded,
  navEventRef
}) => {
  const {
    specialtyEnabled,
    agencyProfileMicrofrontendEnabled,
    agencyProfileMicrofrontendEnabledPhaseTwo
  } = useFlags();

  const wrapperRef = useRef();

  const closeUserNavMenu = useCallback(() => {
    navEventRef.current[NAV_EVENT_TYPES.MOUSE_ENTER] = "";
    navEventRef.current[NAV_EVENT_TYPES.TAB] = "";

    setUserNavExpanded(false);
  }, [navEventRef, setUserNavExpanded]);

  const handleToggleUserNav = useCallback(
    eventType => {
      if (eventType?.type === NAV_EVENT_TYPES.BLUR) {
        closeUserNavMenu();
        return;
      }
      const isMouseEnterSelect =
        navEventRef.current[NAV_EVENT_TYPES.MOUSE_ENTER] ===
        HEADER_NAV_ITEMS.USER_MENU;

      const isTabSelect =
        navEventRef.current[NAV_EVENT_TYPES.TAB] === HEADER_NAV_ITEMS.USER_MENU;

      navEventRef.current[eventType] = HEADER_NAV_ITEMS.USER_MENU;

      if (eventType === NAV_EVENT_TYPES.TAB) {
        if (isTabSelect || (!isMouseEnterSelect && !isTabSelect)) {
          if (!userNavExpanded) {
            setUserNavExpanded(true);
          }
        }
      } else if (eventType === NAV_EVENT_TYPES.MOUSE_ENTER) {
        if (!isTabSelect) {
          setUserNavExpanded(!userNavExpanded);
        } else if (isTabSelect) {
          if (isMouseEnterSelect) {
            closeUserNavMenu();
          } else {
            setUserNavExpanded(true);
          }
        }
      }
    },
    [navEventRef, userNavExpanded, setUserNavExpanded, closeUserNavMenu]
  );

  const handleOutsideClick = useCallback(
    e => {
      if (
        !wrapperRef?.current ||
        wrapperRef.current.contains(e.target) ||
        !userNavExpanded
      ) {
        return;
      }

      closeUserNavMenu();
    },
    [closeUserNavMenu, userNavExpanded]
  );

  const trackAnalytics = () => {
    // TODO: Log analytics
  };

  const handleMyProfileClick = event => {
    const legacyUfgAgentBaseUrl = window.env.REACT_APP_LEGACY_SITE_URL;
    const myProfileUrl = agencyProfileMicrofrontendEnabled
      ? routes.AGENCY_PROFILE_MY_PROFILE
      : `${legacyUfgAgentBaseUrl}${routes.EPORTAL_MY_PROFILE}`;
    closeUserNavMenu();
    trackAnalytics(event);
    window.location = myProfileUrl;
  };

  const handleAgencyProfileClick = event => {
    const legacyUfgAgentBaseUrl = window.env.REACT_APP_LEGACY_SITE_URL;
    const agencyProfileUrl = agencyProfileMicrofrontendEnabledPhaseTwo
      ? routes.AGENCY_PROFILE
      : `${legacyUfgAgentBaseUrl}${routes.EPORTAL_AGENCY_PROFILE}`;
    handleToggleUserNav();
    trackAnalytics(event);
    window.location = agencyProfileUrl;
  };

  const handleLogout = event => {
    closeUserNavMenu();
    trackAnalytics(event);
    logoutAndRedirect(user);
  };

  const handleChangeAgencyView = event => {
    const { agencycode } = event.target.dataset;
    closeUserNavMenu();
    changeAgencyView(agencycode);
  };

  const handleImpersonateAgentClick = event => {
    trackAnalytics(event);
    event.preventDefault();
    closeUserNavMenu();
    impersonateAgentRedirect(applicationId);
  };

  const redirectToHome = () => {
    if (history.location.pathname !== "/") {
      history.replace("/");
    }
  };

  const switchToUfgAgent = () => {
    changeToUfgAgent().then(redirectToHome);
  };

  const switchToSpecialty = () => {
    changeToMercer().then(redirectToHome);
  };

  const agencyProfileText = () => {
    return isSpecialtyPortalView
      ? Translations.user_profile.broker_profile
      : Translations.user_profile.agency_profile;
  };

  const impersonateText = () => {
    return isSpecialtyPortalView
      ? Translations.header.impersonate_broker
      : Translations.header.impersonate_agent;
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => document.addEventListener("click", handleOutsideClick);
  }, [handleOutsideClick]);

  return (
    <div className="headerUserMenu__container">
      <div className="headerUserMenu__user-welcome">
        <div
          className={cn("dropdown", {
            open: userNavExpanded,
            closed: !userNavExpanded
          })}
          ref={wrapperRef}
        >
          <p>
            {Translations.navigation.welcome}
            &nbsp;
            {firstName}
            <br />
            <small className="headerUserMenu__agentCode">{agentCode}</small>
          </p>
          <button
            aria-expanded={userNavExpanded}
            aria-controls="user-menu"
            className="headerUserMenu__toggle"
            onClick={() => handleToggleUserNav(NAV_EVENT_TYPES.MOUSE_ENTER)}
            onFocus={() => handleToggleUserNav(NAV_EVENT_TYPES.TAB)}
            style={
              !userNavExpanded
                ? { transform: "rotate(0deg)" }
                : { transform: "rotate(180deg)" }
            }
          >
            <div className="sr-only">Toggle user menu</div>
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <div
            id="user-menu"
            className={cn("user-menu", {
              "user-menu--open": userNavExpanded,
              "user-menu--closed": !userNavExpanded
            })}
          >
            <ul className="user-menu__list">
              {isMyProfileEnabled && (
                <li className="user-menu-first">
                  <Link to="#" onClick={handleMyProfileClick}>
                    {Translations.user_profile.my_profile}
                  </Link>
                </li>
              )}
              {isAgencyAdministrator && (
                <li>
                  <Link to="#" onClick={handleAgencyProfileClick}>
                    {agencyProfileText()}
                  </Link>
                </li>
              )}
              {!isEmployeeAuthentication && (
                <li>
                  <Link to="#" onClick={handleLogout}>
                    {Translations.header.logout}
                  </Link>
                </li>
              )}

              <AgencySelectionList
                handleChange={handleChangeAgencyView}
                agencies={agencies}
                activeAgencyCode={activeAgencyCode}
              />

              {hasAccessToUfgAgentAndSpecialty &&
                !isSpecialtyPortalView &&
                specialtyEnabled && (
                  <li>
                    <Link
                      to="#"
                      onClick={switchToSpecialty}
                      onBlur={
                        !canImpersonate && !isImpersonatingAnotherAgent
                          ? handleToggleUserNav
                          : undefined
                      }
                    >
                      {Translations.header.ufgSpecialty_switch}
                    </Link>
                  </li>
                )}

              {hasAccessToUfgAgentAndSpecialty &&
                isSpecialtyPortalView &&
                specialtyEnabled && (
                  <li>
                    <Link
                      to="#"
                      onClick={switchToUfgAgent}
                      onBlur={
                        !canImpersonate && !isImpersonatingAnotherAgent
                          ? handleToggleUserNav
                          : undefined
                      }
                    >
                      {Translations.header.ufgAgent_switch}
                    </Link>
                  </li>
                )}

              {canImpersonate && (
                <li onBlur={handleToggleUserNav}>
                  <Link to="#" onClick={handleImpersonateAgentClick}>
                    {impersonateText()}
                  </Link>
                </li>
              )}
              {isImpersonatingAnotherAgent && (
                <li onBlur={handleToggleUserNav}>
                  <Link to="#" onClick={handleLogout}>
                    {Translations.header.end_impersonation}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <form className="raterAgentCode">
        <input type="hidden" id="RaterAgentCode" value={activeAgencyCode} />
      </form>
    </div>
  );
};

export default withLDConsumer()(HeaderUserMenu);

HeaderUserMenu.propTypes = {
  user: PropTypes.object.isRequired,
  applicationId: PropTypes.string,
  firstName: PropTypes.string,
  agentCode: PropTypes.string,
  activeAgencyCode: PropTypes.string,
  agencies: PropTypes.array,
  isMyProfileEnabled: PropTypes.bool.isRequired,
  isAgencyAdministrator: PropTypes.bool,
  isEmployeeAuthentication: PropTypes.bool,
  canImpersonate: PropTypes.bool,
  isImpersonatingAnotherAgent: PropTypes.bool,
  logoutAndRedirect: PropTypes.func.isRequired,
  changeAgencyView: PropTypes.func.isRequired,
  impersonateAgentRedirect: PropTypes.func.isRequired,
  isSpecialtyPortalView: PropTypes.bool,
  changeToUfgAgent: PropTypes.func,
  changeToMercer: PropTypes.func,
  hasAccessToUfgAgentAndSpecialty: PropTypes.bool,
  history: PropTypes.object.isRequired,
  setUserNavExpanded: PropTypes.func.isRequired,
  userNavExpanded: PropTypes.bool.isRequired,
  navEventRef: PropTypes.any.isRequired
};
