import React, { useState, useContext } from "react";
import { Button, Panel, LoadingIndicator } from "@ufginsurance/ui-kit";
import _remove from "lodash/remove";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import * as routes from "../../../constants/routes";

import { locationDisplayName, sortByProperty } from "../../shared/util";
import { buildingCoverageControls } from "../../shared/dynamicCoverageControls/building";
import buildingCoverages, {
  getBuildingCoveragesExclusionsFromQuote
} from "./buildingCoverages";
import { mergeExclusions } from "../../shared/helpers/mergeExclusions";

import OnlineQuotingContext from "../../OnlineQuotingContext";
import BuildingCardContents from "./BuildingCardContents";
import BuildingCardTitle from "./BuildingCardTitle";
import BuildingModalWizard from "./BuildingModalWizard";
import MissingItemPanel from "../../shared/MissingItemPanel";
import CoveragesInCards, {
  displayedCoverages,
  isMissingRequiredTerms
} from "../CoveragesInCards";
import ConfirmationModal from "../../shared/ConfirmationModal";

const BuildingCards = ({ locations, isQuoteSummary, coverageExclusions }) => {
  const {
    quoteData,
    deleteCoverablePromise,
    supportingData,
    toastErrr,
    updateSupportingDataPromise
  } = useContext(OnlineQuotingContext);

  const history = useHistory();
  const [showItemForm, setShowItemForm] = useState(false);
  const [activeBuilding, setActiveBuilding] = useState();
  const [itemToDelete, setItemToDelete] = useState();
  const [defaultLocation, setDefaultLocation] = useState(null);

  const buildings = locations
    // get buildings from locations
    .reduce((acc, curr) => {
      if ((curr?.buildings || []).length) acc.push(...curr.buildings);
      return acc;
    }, [])
    // update building to include additional datas
    .map(b => {
      const hasErrors =
        quoteData?.errorsAndWarnings?.validationIssues?.issues?.some(
          i =>
            i?.relatedEntity?.fixedId === b.fixedId ||
            b?.classifications
              .map(c => c.fixedId)
              .includes(i?.relatedEntity?.fixedId)
        );
      const coverages = buildingCoverages(quoteData, b.fixedId);
      const hasEmptySI = coverages.some(
        f =>
          f.hasOwnProperty("clauseScheduleItems") &&
          f.clauseScheduleItems.scheduleItems.length === 0
      );

      return {
        ...b,
        id: b.id || b.fixedId || b.fixedID,
        locationId: b.locationId
          ? b.locationId
          : b.location
          ? b.location.bp7LocationFixedID
          : null,
        location: locations.find(l => b.locationId === l.bp7LocationFixedID),
        className:
          hasErrors || hasEmptySI ? "oq__coverable__tableRowWithError" : "",
        isPrefill: b?.sourceOfService === "prefill",
        isMissingInfo:
          !b?.description ||
          !b?.yearBuilt ||
          !b?.numberOfStories ||
          !b?.constructionType ||
          !b?.roofType
      };
    });

  //only bp7 locations are required to have buildings
  const locationsWithoutBuildings = locations.filter(
    l => !!l?.bp7LocationFixedID && (l.buildings || []).length === 0
  );

  const onDeleteBuilding = () => {
    const building = itemToDelete;
    const selectedLocation = locations.find(
      l => l.bp7LocationFixedID === building.locationId
    );
    _remove(selectedLocation.buildings, b => b.fixedId === building.fixedId);

    deleteCoverablePromise({
      coverableType: "building",
      buildingIds: String(building.fixedId),
      locationId: building.locationId
    })
      .catch(({ error }) => {
        toastErrr({
          action: "onDeleteBuilding",
          misc: { building },
          error,
          description: "Failed to remove building",
          displayMessage: "Failed to remove building."
        });
      })
      .finally(() => setItemToDelete(null));
  };

  const deleteActionCancelled = () => {
    setItemToDelete(null);
  };

  const openItemEdit = building => {
    setActiveBuilding(building);
    setShowItemForm(true);
  };

  // open new building modal - if location is received, then set the default location in the new building form
  const openItemNew = ({ locationId }) => {
    setDefaultLocation(locationId);
    setActiveBuilding(null);
    setShowItemForm(true);
  };

  const onHideModal = () => {
    setShowItemForm(false);
    setActiveBuilding(null);
  };

  const coverageFields = building =>
    (buildingCoverages(quoteData, building.fixedId) || []).filter(
      c => c.selected === true
    );

  const coverageExclusion = building => {
    const buildCovers = (
      getBuildingCoveragesExclusionsFromQuote(quoteData, building.fixedId) || []
    ).filter(c => !!c.selected);

    const hasWindHailExcl25 = buildCovers.find(
      c => c.publicID === "BP7WindstormOrHailExcl"
    );
    const _showToastBuildings = supportingData?.showOnceToast?.buildings || [];
    const toastDedLoc = _showToastBuildings.find(
      l => l.fixedID === building.fixedId && !!l.windHailExcl25
    );

    if (
      !toast.isActive("hasWindHailExclusion25Toast") &&
      !!hasWindHailExcl25 &&
      !toastDedLoc
    ) {
      //Show the toast message only once to the user per building.

      _showToastBuildings.push({
        fixedID: building.fixedId,
        windHailExcl25: true
      });

      const payload = {
        dataToMergeAndSave: {
          showOnceToast: {
            buildings: _showToastBuildings
          }
        }
      };
      updateSupportingDataPromise(payload);

      toast(
        `Windstorm or Hail Exclusion has been updated on this building(s) ${building?.description}.`,
        {
          toastId: "hasWindHailExclusion25Toast",
          position: "top-center",
          className: "warning",
          autoClose: true
        }
      );
    }

    return buildCovers;
  };

  return (
    <div>
      {itemToDelete && (
        <ConfirmationModal
          title="Delete confirmation"
          warningMessage={
            <div>
              <p>
                Are you sure you want to remove building{" "}
                {itemToDelete.description}?
              </p>
              <p>
                Reminder:
                <br />
                At least one building is required for each location.
              </p>
            </div>
          }
          onClickYes={onDeleteBuilding}
          onClickNo={deleteActionCancelled}
          show
        />
      )}
      {showItemForm && (
        <BuildingModalWizard
          key={`edit${activeBuilding?.id}`}
          locations={locations}
          show={showItemForm}
          name="Building"
          itemData={activeBuilding}
          onHide={onHideModal}
          coverageExclusions={coverageExclusions}
          mode="edit"
          defaultLocation={defaultLocation}
        />
      )}
      <Panel
        id="oq__coverable__container__building"
        rounded={!isQuoteSummary}
        noBorder={isQuoteSummary}
        titlebar
        bgcolor={isQuoteSummary ? undefined : "grey"}
        className="oq__coverable__container oq__coverable__container__building"
        title={
          <div className="flexRow flexRow__full-width flexRow__centered-vertically">
            <span className="oq__data-section__title">
              Buildings ({buildings.length})
            </span>
            {isQuoteSummary && (
              <Button
                variant="outline"
                wrapperClassName="align-right"
                className="link-not-bolded"
                isLink
                inline
                onClick={() => {
                  history.push({
                    pathname: routes.ONLINE_QUOTING_STEP5,
                    hash: "Buildings"
                  });
                }}
              >
                Back to Buildings
              </Button>
            )}
          </div>
        }
      >
        <>
          {buildings.sort(sortByProperty("id")).map(building => {
            const mergedExclusions = mergeExclusions([
              coverageExclusions,
              buildingCoverageControls({ building, supportingData, quoteData })
            ]);

            const coverages = displayedCoverages({
              coverages: coverageFields(building),
              coverageExclusions: mergedExclusions
            });

            return (
              <Panel
                id={`container__building__${building?.fixedId}`}
                key={`container__building__${building?.fixedId}`}
                rounded
                isOpen
                collapsible={
                  !isQuoteSummary &&
                  !(building.isMissingInfo && building.isPrefill) &&
                  !isMissingRequiredTerms({ coverages })
                }
                className="oq__coverable__card oq__coverable__card__building"
                title={
                  <BuildingCardTitle
                    building={building}
                    onClickHandler={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      openItemEdit(building);
                    }}
                  />
                }
              >
                <div className="oq__coverable__building-wrapper">
                  <BuildingCardContents
                    building={building}
                    setActiveBuilding={setActiveBuilding}
                    coverageExclusions={mergedExclusions}
                  />
                  {!coverageExclusions ? (
                    <LoadingIndicator
                      message="Loading Coverages"
                      type="spinner"
                      className="oq__view__coverage__loading-metadata"
                    />
                  ) : (
                    coverages.length && (
                      <div className="oq__coverable__card__content__coverages">
                        <CoveragesInCards
                          quoteData={quoteData}
                          coverageFields={coverages}
                          coverageExclusions={mergedExclusions}
                          id={`coverages__building__${building.id}`}
                          editScheduledItems={!isQuoteSummary}
                          alwaysDisplay={isMissingRequiredTerms({
                            coverages
                          })}
                          handleEditItem={() => openItemEdit(building)}
                        />
                      </div>
                    )
                  )}
                  {!!coverageExclusion(building)?.length && (
                    <div className="oq__coverable__card__content__coverages">
                      <p className="">
                        The following exclusion(s) have been added to this
                        building:
                      </p>

                      {coverageExclusion(building).map(c => (
                        <Panel
                          key={c.rowKey || c.publicID}
                          id={c.publicID}
                          bgcolor="white"
                          className="oq__view__coverage-panel"
                          highlight="blue"
                        >
                          <div className="oq__view__coverage__title">
                            <span className="oq__view__coverage__title-text">
                              {c.name}
                            </span>
                          </div>
                        </Panel>
                      ))}
                    </div>
                  )}
                  {!isQuoteSummary && (
                    <div className="oq__coverable__card__actions">
                      <Button
                        wrapperClassName="oq__coverable__card__edit"
                        className="oq__coverable__card__edit-button"
                        inline
                        isLink
                        onClick={() => {
                          openItemEdit(building);
                        }}
                      >
                        Edit Building and Coverages
                      </Button>
                      <Button
                        wrapperClassName="oq__coverable__card__delete"
                        isLink
                        inline
                        variant="tertiary"
                        onClick={() => {
                          setItemToDelete(building);
                        }}
                      >
                        Remove Building
                      </Button>
                    </div>
                  )}
                </div>
              </Panel>
            );
          })}
          {locationsWithoutBuildings.map(location => (
            <MissingItemPanel
              key={location.id}
              onClickHandler={() => {
                openItemNew({ locationId: location.bp7LocationFixedID });
              }}
              content={
                <span className="oq__coverable__card__initial-content">
                  <span className="oq__coverable__card__initial-content__secondary">
                    A building is required for the location:
                  </span>
                  <span className="oq__coverable__card__initial-content__extended">
                    {locationDisplayName(location)}
                  </span>
                </span>
              }
              buttonText="Add Building"
            />
          ))}
          {!isQuoteSummary && (
            <Button
              block
              variant="outline"
              wrapperClassName="oq__coverable__add-button-wrapper"
              className="oq__coverable__card__add__button"
              icon="farPlus"
              onClick={() => {
                openItemNew({ locationId: null });
              }}
            >
              Add Building
            </Button>
          )}
        </>
      </Panel>
    </div>
  );
};

export default BuildingCards;
