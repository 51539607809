import React, { useState, useRef, useEffect, useCallback } from "react";
import { Dropdown } from "@ufginsurance/ui-kit";
import propTypes from "prop-types";
import { formatBondDropdownOption } from "../../shared/utils";
import { debounceApi } from "../../../util/debounce";
import { ACCOUNT_NAME_BOND_NUMBER_MAX_LENGTH } from "../../shared/constants";
import { logger } from "../../../loggers";
import { getUWReviewBondInfo } from "../../../underwriter-review/uwReviewServices";
import { getConsentOfSurety } from "../../services/agencyActivityService";
import { useSuretyContext } from "../../surety-context/SuretyContext";
import { parseLocation } from "parse-address";

const BondSearchInput = ({
  label,
  form: { values, errors, handleOnValidate, updateForm },
  hasBondUrlParam = false,
  setAlertMessage
}) => {
  const [dropdownOptions, setDropdownOptions] = useState();
  const [bond, setBond] = useState();
  const [isRecentConsent, setIsRecentConsent] = useState();
  const [isBondLoading, setIsBondLoading] = useState(false);
  const [isUwBondLoading, setIsUwBondLoading] = useState(false);
  const [isRecentConsentLoading, setIsRecentConsentLoading] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [accountBondSearchInput, setAccountBondSearchInput] = useState(
    values.accountBondSearchInput
  );
  const cache = useRef({});
  const { agency } = useSuretyContext();

  const agencyCode = agency?.agencyCode;
  const handleBondSearch = useCallback(
    bondNumber => {
      setIsBondLoading(true);
      setIsUwBondLoading(true);
      setIsRecentConsentLoading(true);
      setDropdownOptions([]);
      setIsDropdownVisible(true);
      setAlertMessage({ alertMessage: null, alertType: null });
      getUWReviewBondInfo(bondNumber)
        .then(res => {
          if (res.status === 200 && !res.data?.account_number) {
            throw new Error("Invalid bond number");
          }
          if (res.status === 200) {
            cache.current[bondNumber] = res.data;
            setBond({
              ...res?.data,
              bond_number: bondNumber
            });
            setAccountBondSearchInput(null);
            setDropdownOptions(
              formatBondDropdownOption([res.data], bondNumber)
            );
          }
        })
        .catch(err => {
          console.error("Bond not found: " + err);
          logger.error({
            description: err.toString(),
            fatal: false
          });
          setIsBondLoading(false);
          setIsDropdownVisible(false);
          setAlertMessage({
            alertMessage: "Unable to load the specified bond",
            alertType: "error"
          });
          updateForm({
            values: {
              ...values,
              bond_number: bond?.bond_number,
              isValidForConsentOfSurety: false
            }
          });
        });
      getConsentOfSurety({ agencyCode, bondNumber })
        .then(res => {
          if (res.status === 200) {
            setIsRecentConsent(true);
          }
        })
        .catch(err => {
          // Assumption is that there is no recent CoS
          setIsRecentConsent(false);
          console.log("This 404 is expected and isn't really an error" + err);
        });
    },
    // Run effect only when the function is specifically called.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleAccountBondSearchInputChange = value => {
    if (value.length === 8) {
      setAccountBondSearchInput(null);
      values.accountBondSearch = null;
      debounceApi(handleBondSearch(value), 100);
    } else if (bond != null) {
      setIsRecentConsent(null);
      setBond(null);
    }
    updateForm({ values: { ...values, accountBondSearchInput: value } });
  };

  const handleBondSearchInputOnBlur = () => {
    setIsDropdownVisible(false);
    if (
      !values.accountBondSearch &&
      dropdownOptions !== undefined &&
      dropdownOptions.length !== 0
    ) {
      setDropdownOptions([]);
    }
  };

  const handleFetchedBondUpdateForm = values => {
    setIsDropdownVisible(false);

    const parsedObligeeCSZ = parseLocation(
      Object.values({
        street: bond?.obligee_street_address || "",
        csz: bond?.obligee_city_state_zip || ""
      }).join(" ")
    );

    let bondZipCode = bond?.account_zip.replace("-", "") || "";
    if (bondZipCode.length > 5)
      bondZipCode = bondZipCode.substr(0, 5) + "-" + bondZipCode.substr(5);
    updateForm({
      values: {
        ...values,
        accountBondSearch: values?.accountBondSearch,
        accountBondSearchInput: "",
        account_name: bond?.account_name,
        account_number: bond?.account_number,
        address_line: bond?.account_street_address || "",
        consent_type: values?.consent_type || "",
        city: bond?.account_city || "",
        state: bond?.account_state || "",
        zip: bondZipCode,
        project_description: bond?.bond_description || "",
        obligee_name: bond?.obligee_name || "",
        obligee_address_line: bond?.obligee_street_address || "",
        obligee_zip: parsedObligeeCSZ?.zip || "",
        obligee_city: parsedObligeeCSZ?.city || "",
        obligee_state: parsedObligeeCSZ?.state || "",
        bond_status: bond?.bond_status || "",
        bond_type: bond?.bond_type || "",
        saved_bond_options: dropdownOptions,
        agency,
        bond
      },
      errors: !!values?.accountBondSearch?.length
        ? {
            bondTypeSelect: [],
            uwMessage: []
          }
        : errors
    });
  };

  const handleAccountBondSearchOnChange = ({ value }) => {
    if (hasBondUrlParam) return;
    handleFetchedBondUpdateForm({
      ...values,
      accountBondSearch: value,
      accountBondSearchInput: ""
    });
  };

  const handleAccountBondSearchOnValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    const regex = /[~=`{}_[\\^<>\]]/g;
    if (value && regex.test(value)) {
      fieldErrors.push(`Bond Numbers can be alpha or numeric characters only.`);
    }
    return fieldErrors;
  };

  useEffect(() => {
    if (bond) {
      setDropdownOptions(formatBondDropdownOption([bond], bond?.bond_number));

      if (hasBondUrlParam) {
        handleFetchedBondUpdateForm({ ...values, accountBondSearchInput: "" });
      }
    }
    setIsUwBondLoading(false);
    // Run effect only when the data changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bond]);

  useEffect(() => {
    setIsRecentConsentLoading(false);
    if (isRecentConsent) {
      setAlertMessage({
        alertMessage:
          "You have a Consent of Surety pending. Please contact your underwriter.",
        alertType: "error"
      });
    }
    updateForm({
      values: {
        ...values,
        recentConsentOfSurety: isRecentConsent
      }
    });
    // Run effect only when the data changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecentConsent]);

  useEffect(() => {
    setIsBondLoading(isRecentConsentLoading || isUwBondLoading);
  }, [isRecentConsentLoading, isUwBondLoading]);

  useEffect(() => {
    if (accountBondSearchInput) {
      debounceApi(handleBondSearch(accountBondSearchInput), 100);
    }
  }, [accountBondSearchInput, handleBondSearch]);

  useEffect(() => {
    if (dropdownOptions !== undefined && dropdownOptions.length !== 0) {
      values.saved_bond_options = dropdownOptions; // TODO: this is bad/wrong
    }
  }, [values, dropdownOptions]);

  return (
    <Dropdown
      id="accountBondSearch"
      name="accountBondSearch"
      placeholder="Start typing the bond number..."
      noLabel={!label}
      label={label}
      onChange={handleAccountBondSearchOnChange}
      onInputChange={handleAccountBondSearchInputChange}
      onBlur={handleBondSearchInputOnBlur}
      onValidate={handleAccountBondSearchOnValidate}
      value={values.accountBondSearch || accountBondSearchInput}
      inputValue={
        values.accountBondSearchInput?.length <=
        ACCOUNT_NAME_BOND_NUMBER_MAX_LENGTH
          ? values.accountBondSearchInput
          : values.accountBondSearchInput?.substr(
              0,
              ACCOUNT_NAME_BOND_NUMBER_MAX_LENGTH
            )
      }
      disabled={hasBondUrlParam}
      options={dropdownOptions || values.saved_bond_options || []}
      size="fill"
      isSearchable
      isLoading={isBondLoading}
      loadingMessage={() => "Searching..."}
      hideIndicatorUntilSearch
      isClearable={false}
      menuIsOpen={isDropdownVisible}
      required
    />
  );
};

BondSearchInput.propTypes = {
  form: propTypes.object.isRequired,
  label: propTypes.string,
  hasBondUrlParam: propTypes.bool,
  setAlertMessage: propTypes.func
};

export default BondSearchInput;
