import React from "react";
import {
  ssoClient,
  SSOValidationContainer,
  configuration
} from "@ufginsurance/sso-oidc-client-react";
import App from "./App";
import { withLDConsumer, useFlags } from "launchdarkly-react-client-sdk";

const LaunchDarklyContainer = ({ store }) => {
  const { specialtyEnabled } = useFlags();
  return (
    <SSOValidationContainer
      specialtyEnabled={specialtyEnabled}
      store={store}
      userManager={ssoClient.userManager}
      clientType={configuration.CLIENT_TYPE_AGENT}
    >
      <App />
    </SSOValidationContainer>
  );
};
export default withLDConsumer()(LaunchDarklyContainer);
