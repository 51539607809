import * as types from "../../constants/actionTypes";
import initialState from "../../reducers/initialState";

const accountChangeRequestsReducer = (
  state = initialState.policyChangeRequest,
  action
) => {
  switch (action.type) {
    case types.PUT_POLICY_CHANGE_STARTED:
      return {
        ...state,
        policyDraft: {
          ...state.policyDraft,
          isLoading: true,
          error: false
        }
      };
    case types.PUT_POLICY_CHANGE_SUCCEEDED:
      return {
        ...state,
        policyDraft: {
          data: action.payload,
          isLoading: false,
          error: false
        }
      };
    case types.FETCH_POLICY_DRAFT_STARTED:
      return {
        ...state,
        policyDraft: {
          ...state.policyDraft,
          isLoading: true,
          error: false
        }
      };
    case types.FETCH_POLICY_DRAFT_SUCCEEDED:
      return {
        ...state,
        policyDraft: {
          data: action.payload,
          isLoading: false,
          error: false
        }
      };
    case types.FETCH_POLICY_DRAFT_FAILED:
      return {
        ...state,
        policyDraft: {
          isLoading: false,
          data: null,
          error: action.payload
        }
      };
    case types.CREATE_POLICY_DRAFT_STARTED:
      return {
        ...state,
        policyDraft: {
          ...state.policyDraft,
          isLoading: true,
          error: false
        }
      };
    case types.CREATE_POLICY_DRAFT_SUCCEEDED:
      return {
        ...state,
        policyDraft: {
          data: action.payload,
          isLoading: false,
          error: false
        }
      };
    case types.CREATE_POLICY_DRAFT_FAILED:
      return {
        ...state,
        policyDraft: {
          isLoading: false,
          data: null,
          error: action.payload
        }
      };
    case types.FETCH_CHANGE_REQUEST_ELEGIBILTY_INFO_STARTED:
      return {
        ...state,
        policyChangeInformation: {
          ...state.policyChangeInformation,
          isLoading: true,
          error: false
        }
      };
    case types.FETCH_CHANGE_REQUEST_ELEGIBILTY_INFO_SUCCEEDED:
      return {
        ...state,
        policyChangeInformation: {
          data: action.payload,
          isLoading: false,
          error: false
        }
      };
    case types.FETCH_CHANGE_REQUEST_ELEGIBILTY_INFO_FAILED:
      return {
        ...state,
        policyChangeInformation: {
          isLoading: false,
          data: null,
          error: action.payload
        }
      };
    case types.FETCH_POST_REQUEST_ELEGIBILTY_INFO_STARTED:
      return {
        ...state,
        policyChangeInformation: {
          ...state.policyChangeInformation,
          isLoading: true
        }
      };
    case types.FETCH_POST_REQUEST_ELEGIBILTY_INFO_SUCCEEDED:
      return {
        ...state,
        policyChangeInformation: {
          ...state.policyChangeInformation,
          nextResponse: action.payload,
          isLoading: false,
          error: false
        }
      };
    case types.FETCH_POST_REQUEST_ELEGIBILTY_INFO_FAILED:
      return {
        ...state,
        policyChangeInformation: {
          ...state.policyChangeInformation,
          isLoading: false,
          error: action.payload
        }
      };
    default:
      return state;
  }
};

export default accountChangeRequestsReducer;
